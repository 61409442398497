import React from "react";

import { racehorse360 } from "@tsg/1st-grpc-web";

import { SortOrder } from "interfaces/SortOrder";

export enum EExamTableStatus {
  PENDING = "Pending",
  QUICK_PASSED = "Quick Passed",
  EXAM_PASSED = "Exam Passed",
  EXAM_FAILED = "Exam Failed"
}

export interface IHeaderCell<T = string> {
  title: string;
  isDisplayed: boolean;
  dataTestId?: string;
  centered?: boolean;
  lastTwoColumnsCell?: boolean;
  lastOneColumnCell?: boolean;
  sortableCell?: {
    name: T;
    sortOrder: SortOrder;
    sortHandler: (columnName: T, sortOrder: SortOrder) => void;
    isInverted?: boolean;
  };
}

export enum EEventType {
  WORKOUT_REQUEST = "Workout Request",
  PRE_RACE = "Race"
}

export type TExamNote =
  | racehorse360.IWorkoutRequestExamReasonNote
  | racehorse360.IUpcomingRaceEntryExamNote;

export type TFlagPriority =
  | racehorse360.WorkoutRequestFlagPriority
  | racehorse360.UpcomingRaceEntryFlagPriority;

export interface IExamsTableRowData {
  eventId: string;
  eventType: EEventType;
  eventDate: string;
  eventNumber?: number;
  eventPosition?: number;
  eventCreationDate: string;
  eventStatus:
    | racehorse360.WorkoutRequestStatus
    | racehorse360.UpcomingRaceEntryStatus;
  eventFacility: racehorse360.IFacility;
  eventFlag?: racehorse360.IWorkoutRequestFlag;
  eventFlagPriority?: TFlagPriority;
  hasEventComment?: boolean;
  pastEventsCount?: number;
  eventRejectionNote?: racehorse360.IWorkoutRequestRejectionComment;
  examId: string;
  examStatus: racehorse360.HorseExamStatus;
  examType: racehorse360.HorseExamType;
  examCreatedById?: string;
  examResult?: racehorse360.HorseExamResult;
  examAssignedUser?: racehorse360.IUser;
  examNote?: TExamNote;
  horse?: racehorse360.IHorse;
  highSpeedFurlongs?: number;
}

export interface ITableHeaderColumnsContent<T = string> {
  [key: string]: IHeaderCell<T> | IHeaderCell<T>[];
}

export interface ITableColumnsContent {
  [key: string]: React.ReactNode;
}
