import { makeStyles } from "@material-ui/core/styles";

export default makeStyles({
  rowOdd: {
    backgroundColor: "#F6F6F6"
  },
  rowExamChecked: {
    backgroundColor: "rgba(252, 249, 242, 1.0)",
    "& label.MuiFormLabel-root.MuiFormLabel-root": {
      backgroundColor: "rgba(252, 249, 242, 1.0)"
    }
  },
  rowPassChecked: {
    backgroundColor: "rgba(205, 253, 240, 1.0)",
    "& label.MuiFormLabel-root.MuiFormLabel-root": {
      backgroundColor: "rgba(205, 253, 240, 1.0)"
    }
  },
  rowAssignChecked: {
    backgroundColor: "rgba(205, 253, 240, 1.0)",
    "& label.MuiFormLabel-root.MuiFormLabel-root": {
      backgroundColor: "rgba(205, 253, 240, 1.0)"
    }
  }
});
