import React from "react";
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";

import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import { racehorse360 } from "@tsg/1st-grpc-web";

import { EMPTY_STRING } from "common/constants";
import {
  getHorseAgeSexColorValue,
  getHorseOwnerOrBreederName
} from "utils/horse";
import HealthRating from "./HealthRating";
import FlagOnTrack from "./FlagOnTrack";
import { useLoggedInUser } from "components/LoggedInUserProvider";
import BarnNumberPicker from "components/BarnNumberPicker";
import { useFacilityBarns } from "components/BarnNumberPicker/useFacilityBarns";
import { getPersonFullName } from "utils/person";

import useStyles from "./styles";

interface Props {
  horse: racehorse360.IHorse;
  isLoading: boolean;
  refetchComments: () => void;
  onBarnChange: (barn: racehorse360.IBarn) => void;
}

const Information = (props: Props) => {
  const { horse, isLoading, refetchComments, onBarnChange } = props;

  const classes = useStyles();
  const { currentUser } = useLoggedInUser();
  const { facilityBarns, isLoading: isListBarnsLoading } = useFacilityBarns(
    horse.currentFacility?.id
  );

  const { isRegulatoryVet } = currentUser;

  return (
    <>
      <Paper component={List} className={classes.list}>
        <ListItem className={classes.listItem}>
          <Typography>Horse Information</Typography>
        </ListItem>
        <Divider />
        <ListItem className={classes.listItem}>
          <Typography className={classes.listItemLabel}>
            Registration Number
          </Typography>
          <Typography className={classes.listItemValue}>
            {horse.registrationNumber}
          </Typography>
        </ListItem>
        <Divider light />
        <ListItem className={classes.listItem}>
          <Typography className={classes.listItemLabel}>
            Chip ID Number
          </Typography>
          <Typography className={classes.listItemValue}>
            {`${horse.chipNumber || "-"}`}
          </Typography>
        </ListItem>
        <Divider light />
        <ListItem className={classes.listItem}>
          <Typography className={classes.listItemLabel}>
            Date of Birth
          </Typography>
          <Typography className={classes.listItemValue}>
            {horse?.birthday &&
              format(parseISO(horse?.birthday), "MMMM d, yyyy")}
          </Typography>
        </ListItem>
        <Divider light />
        <ListItem className={classes.listItem}>
          <Typography className={classes.listItemLabel}>
            Age - Sex - Color
          </Typography>
          <Typography className={classes.listItemValue}>
            {getHorseAgeSexColorValue(horse, "%a - %S - %C")}
          </Typography>
        </ListItem>
        <Divider light />
        <ListItem className={classes.listItem}>
          <Typography className={classes.listItemLabel}>Dam</Typography>
          <Typography className={classes.listItemValue}>
            {horse.damName}
          </Typography>
        </ListItem>
        <Divider light />
        <ListItem className={classes.listItem}>
          <Typography className={classes.listItemLabel}>Sire</Typography>
          <Typography className={classes.listItemValue}>
            {horse.sireName}
          </Typography>
        </ListItem>
        <Divider light />
        <ListItem className={classes.listItem}>
          <Typography className={classes.listItemLabel}>
            Risk Level/ABC
          </Typography>
          <HealthRating horse={horse} isLoading={isLoading} />
        </ListItem>
        <Divider light />
        {!isRegulatoryVet && (
          <>
            <ListItem className={classes.listItem}>
              <Typography className={classes.listItemLabel}>
                Flag on track
              </Typography>
              <FlagOnTrack horse={horse} refetchComments={refetchComments} />
            </ListItem>
            <Divider light />
          </>
        )}
        <ListItem className={classes.listItem}>
          <Typography className={classes.listItemLabel}>Trainer</Typography>
          <Typography className={classes.listItemValue}>
            {getPersonFullName(horse.trainer, "%F %L")}
          </Typography>
        </ListItem>
        <Divider light />
        <ListItem className={classes.listItem}>
          <Typography className={classes.listItemLabel}>Owner</Typography>
          <Typography className={classes.listItemValue}>
            {getHorseOwnerOrBreederName(horse, EMPTY_STRING)}
          </Typography>
        </ListItem>
        <Divider light />
        <ListItem className={classes.listItem}>
          <Typography className={classes.listItemLabel}>Barn Number</Typography>
          <div className={classes.listItemContainer}>
            {!isListBarnsLoading && (
              <BarnNumberPicker
                className={classes.barnNumberPicker}
                horse={horse}
                selectedFacilityId={horse.currentFacility?.id}
                startActions={true}
                options={facilityBarns}
                onBarnChange={onBarnChange}
              />
            )}
          </div>
        </ListItem>
        <Divider light />
        <ListItem className={classes.listItem}>
          <Typography className={classes.listItemLabel}>
            Last Workout
          </Typography>
          <Typography className={classes.listItemValue}>
            {horse.lastWorkoutDate
              ? format(parseISO(horse.lastWorkoutDate), "MMMM dd, yyyy")
              : "None"}
          </Typography>
        </ListItem>
        <Divider light />
        <ListItem className={classes.listItem}>
          <Typography className={classes.listItemLabel}>
            Last Workout Track
          </Typography>
          <Typography className={classes.listItemValue}>
            {horse.lastWorkoutFacility
              ? horse.lastWorkoutFacility.code
              : "None"}
          </Typography>
        </ListItem>
        <Divider light />
        <ListItem className={classes.listItem}>
          <Typography className={classes.listItemLabel}>Last Race</Typography>
          <Typography className={classes.listItemValue}>
            {horse.lastRaceDate
              ? format(parseISO(horse.lastRaceDate), "MMMM dd, yyyy")
              : "None"}
          </Typography>
        </ListItem>
        <Divider light />
        <ListItem className={classes.listItem}>
          <Typography className={classes.listItemLabel}>
            Last Race Track
          </Typography>
          <Typography className={classes.listItemValue}>
            {horse.lastRaceFacility ? horse.lastRaceFacility.code : "None"}
          </Typography>
        </ListItem>
      </Paper>
    </>
  );
};

export default Information;
