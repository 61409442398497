import { makeStyles } from "@material-ui/core";
import { MuiTheme } from "theme";

export default makeStyles((theme: MuiTheme) => {
  const { primary } = theme.palette;

  return {
    root: {
      width: "100%",
      margin: 0,
      "& .MuiFormControlLabel-label": {
        fontSize: 14,
        color: primary.dark,
        marginRight: 4
      }
    }
  };
});
