import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import clsx from "clsx";

import { Theme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import List from "@material-ui/core/List";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";

import { racehorse360 } from "@tsg/1st-grpc-web";

import { useLoggedInUser } from "components/LoggedInUserProvider";
import { useWindowSize } from "hooks/screen";
import IQueriesConfigState from "interfaces/queriesConfigState";
import { FacilitiesState } from "store/reducers/facilities";
import {
  withClickBlocker,
  WithClickBlockerProps
} from "components/BlockableClickContext";
import FacilitySelectOption from "./FacilitySelectOption";
import {
  allFacilitiesOption,
  getFacilityOptions,
  getShortenedFacilityName
} from "./helpers";

import useStyles from "./styles";

export interface IProps {
  className?: string;
  selectedFacility?: racehorse360.IFacility;
  onChange: (facility: racehorse360.IFacility) => void;
  isActive?: boolean;
  instructions?: string;
  shouldDisplayAllTracksOption?: boolean;
  customOptions?: racehorse360.IFacility[];
  shouldDisplayTrainingFacilities?: boolean;
}

interface Coordinates {
  top: number;
  left: number | string;
  right: number | string;
}

const MAX_DIALOG_WIDTH = 300;

const FacilitySelect: React.FC<IProps & WithClickBlockerProps> = React.memo(
  props => {
    const {
      className,
      onChange,
      instructions,
      handleBlockableClick,
      shouldDisplayAllTracksOption,
      customOptions,
      shouldDisplayTrainingFacilities
    } = props;

    const { currentUser } = useLoggedInUser();
    const { width, height } = useWindowSize();
    const [open, setOpen] = useState(false);
    const [coordinates, setCoordinates] = useState<Coordinates>({
      top: 0,
      left: 0,
      right: 0
    });
    const [selectedFacility, setSelectedFacility] =
      useState<racehorse360.IFacility>(props.selectedFacility);
    const classes = useStyles({
      foregroundColor: selectedFacility?.foregroundColor
    });
    const matchesUpLg = useMediaQuery((theme: Theme) =>
      theme.breakpoints.up("lg")
    );
    const facilityNameRef = useRef(null);
    const dialogRef = useRef(null);
    const { facilities } = useSelector(
      (state: { facilities: FacilitiesState }) =>
        state?.facilities || { facilities: [] }
    );
    const trainerSelectedFacilityId = useSelector(
      (state: { queriesConfig: IQueriesConfigState }) => {
        return state?.queriesConfig?.currentFacilityId;
      }
    );
    const [facilityOptions, setFacilityOptions] = useState(
      getFacilityOptions(
        facilities,
        shouldDisplayAllTracksOption,
        shouldDisplayTrainingFacilities,
        customOptions
      )
    );

    const getDefaultFacilityId = (): string => {
      let item: racehorse360.IFacility;

      if (shouldDisplayAllTracksOption) {
        item = allFacilitiesOption;
      } else if (currentUser.isTrainer) {
        item = facilities.find(f => f.id === trainerSelectedFacilityId);
      } else {
        const hasTrackFacilities = facilities.some(f => f.isTrackFacility);

        item = facilities.find(f => !hasTrackFacilities || f.isTrackFacility);
      }

      if (!item) {
        item = facilities.find(f => f.code === "SA") || facilities[0];
      }

      return item.id;
    };
    const handleFacilityChanged = (facility: racehorse360.IFacility) => {
      if (facility?.id !== selectedFacility?.id) {
        setSelectedFacility(facility);
        onChange(facility);
      }
    };

    const handleOpen = () => {
      handleBlockableClick(() => setOpen(true));
    };

    const handleClose = () => {
      setOpen(false);
    };

    const handleFacilitySelect = (facility: racehorse360.IFacility) => {
      if (facility.id !== selectedFacility?.id) {
        setSelectedFacility(facility);
        onChange(facility);
      }
      setOpen(false);
    };

    const handleDialogEntering = () => {
      countPosition();
    };

    const countPosition = () => {
      const {
        top,
        left,
        right,
        height: nameHeight
      } = facilityNameRef.current.getBoundingClientRect();
      let offsetTop = top + nameHeight;
      const offsetLeft =
        left + MAX_DIALOG_WIDTH < window.innerWidth ? left : "auto";
      const offsetRight =
        left + MAX_DIALOG_WIDTH > window.innerWidth
          ? window.innerWidth - right
          : "auto";

      if (dialogRef.current) {
        const dialogRect = dialogRef.current.getBoundingClientRect();
        if (offsetTop + dialogRect.height > window.innerHeight) {
          offsetTop = top - dialogRect.height;
        }
      }

      setCoordinates({ top: offsetTop, left: offsetLeft, right: offsetRight });
    };

    useEffect(() => {
      countPosition();
    }, [matchesUpLg, width, height]);

    useEffect(() => {
      if (facilityOptions?.length) {
        const selectedFacilityId =
          props.selectedFacility?.id || getDefaultFacilityId();

        const newSelectedFacilityOption =
          facilityOptions.find(fo => fo.id === selectedFacilityId) ||
          facilityOptions[0];

        if (newSelectedFacilityOption?.id !== selectedFacility?.id) {
          setSelectedFacility(newSelectedFacilityOption);
          onChange(newSelectedFacilityOption);
        }
      }
    }, [props.selectedFacility, facilityOptions]);

    useEffect(() => {
      if (facilities?.length) {
        setFacilityOptions(
          getFacilityOptions(
            facilities,
            shouldDisplayAllTracksOption,
            shouldDisplayTrainingFacilities,
            customOptions
          )
        );
      }
    }, [facilities]);

    return (
      <>
        <Button
          ref={facilityNameRef}
          className={clsx(classes.facilityButton, className)}
          onClick={handleOpen}
          data-test-id={"facilityButton"}
        >
          {Boolean(selectedFacility) && (
            <>
              <span className={classes.facilityNameText}>
                {getShortenedFacilityName(selectedFacility)}
              </span>
              <ArrowDropDownIcon
                className={clsx(classes.facilityNameIcon, {
                  [classes.facilityNameIconOpen]: open
                })}
              />
            </>
          )}
        </Button>

        <Dialog
          fullWidth
          className={clsx(classes.root, {
            [classes.rootPopoverBack]: matchesUpLg
          })}
          open={open}
          TransitionProps={{
            onEntering: handleDialogEntering
          }}
          onClose={handleClose}
          classes={{
            paper: clsx({ [classes.rootPopover]: matchesUpLg })
          }}
          PaperProps={{
            style: {
              maxWidth: MAX_DIALOG_WIDTH,
              margin: "0",
              left: matchesUpLg ? coordinates.left : 0,
              top: matchesUpLg ? coordinates.top : 0,
              right: matchesUpLg ? coordinates.right : 0
            }
          }}
          hideBackdrop={matchesUpLg}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div ref={dialogRef}>
            <DialogTitle disableTypography className={classes.header}>
              <Typography
                variant="h6"
                className={clsx(
                  classes.title,
                  { [classes.titleSmall]: matchesUpLg },
                  { [classes.titleInstructions]: instructions }
                )}
              >
                Track Selection
              </Typography>
              {instructions && (
                <Typography className={classes.subTitle} color="textSecondary">
                  {instructions}
                </Typography>
              )}
              <IconButton
                aria-label="close"
                onClick={handleClose}
                className={clsx(classes.closeButton, {
                  [classes.closeButtonHide]: matchesUpLg
                })}
              >
                <CloseIcon className={classes.closeButtonIcon} />
              </IconButton>
            </DialogTitle>

            <DialogContent className={classes.content}>
              <List disablePadding>
                {facilityOptions.map(facility => (
                  <FacilitySelectOption
                    key={facility.id}
                    facility={facility}
                    isSelected={facility.id === selectedFacility?.id}
                    onFacilitySelect={handleFacilitySelect}
                  />
                ))}
              </List>
            </DialogContent>
          </div>
        </Dialog>
      </>
    );
  }
);

export default withClickBlocker<IProps>(FacilitySelect);
