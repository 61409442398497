import React, { HTMLAttributes, MouseEventHandler } from "react";
import clsx from "clsx";

import IconButton from "@material-ui/core/IconButton";
import ConfirmIcon from "@material-ui/icons/CheckCircleOutlined";
import CancelIcon from "@material-ui/icons/CancelOutlined";

import useStyles from "./styles";

interface IProps extends HTMLAttributes<HTMLDivElement> {
  onConfirm: MouseEventHandler<HTMLButtonElement>;
  onCancel: () => void;
}

const ConfirmAndCancelButtons = (props: IProps) => {
  const { onConfirm, onCancel, className } = props;

  const classes = useStyles();

  return (
    <div className={clsx(className, classes.actionsContainer)}>
      <IconButton
        className={clsx(classes.actionButton, classes.cancel)}
        onClick={onCancel}
      >
        <CancelIcon className={classes.actionIcon} />
      </IconButton>
      <IconButton
        className={clsx(classes.actionButton, classes.confirm)}
        onClick={onConfirm}
      >
        <ConfirmIcon className={classes.actionIcon} />
      </IconButton>
    </div>
  );
};

export default React.memo(ConfirmAndCancelButtons);
