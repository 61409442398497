import React, { useState } from "react";

import { racehorse360 } from "@tsg/1st-grpc-web";

import ExamLockerContext from "components/ExamLockerContext";
import ExamLockerCountdownModal from "components/ExamLockerCountdownModal";
import { useRacehorse360Api } from "hooks/api";
import VetExamProvider from "./VetExamProvider";
import VetExams from "./VetExams";
import { getVetExamRequestState } from "./helper";
import { IVetExamLocalState } from "./options";

interface IProps {
  examId: string;
  refetchData: () => void;
  onClose: () => void;
}

const VetExamOverlay = (props: IProps) => {
  const { examId, refetchData, onClose } = props;

  const [lockExpirationDate, setLockExpirationDate] = useState<Date>();

  const { useCancelHorseExam, useEditHorseExam } = useRacehorse360Api();

  const { mutateAsync: cancelExam } = useCancelHorseExam();
  const { mutateAsync: saveExamState } = useEditHorseExam();

  const handleCancelExam = () =>
    cancelExam({
      id: examId
    }).then(() => refetchData());

  const handleExamLockExpiration = () =>
    handleCancelExam().then(() => {
      onClose();
    });

  const handleCountdownClose = () => {
    setLockExpirationDate(null);
    refetchData();
    onClose();
  };

  const handleLock = expirationDate => {
    setLockExpirationDate(expirationDate);
  };

  const handleClose = () => {
    setLockExpirationDate(null);
    onClose();
  };

  const saveExam = (
    state: IVetExamLocalState,
    currentExam: racehorse360.IHorseExam
  ) => {
    saveExamState({
      horseExam: getVetExamRequestState(currentExam, state)
    });
  };

  return (
    <ExamLockerContext
      recordId={examId}
      countdown={true}
      onExpire={handleExamLockExpiration} //TODO: set it using setState inside vet exam modal component
      onLock={handleLock}
    >
      <VetExamProvider examId={examId}>
        <>
          <VetExams refetchData={refetchData} onClose={handleClose} />
          <ExamLockerCountdownModal
            expirationDate={lockExpirationDate}
            onClose={handleCountdownClose}
            onSaveExam={saveExam}
          />
        </>
      </VetExamProvider>
    </ExamLockerContext>
  );
};

export default React.memo(VetExamOverlay);
