import {
  EPreRaceTableColumnSorting,
  IPreRaceTableTab
} from "pages/PreRaceScreeningPage/types";
import { IExamsTableRowData } from "components/VetExamsTable/types";
import { SortOrder } from "interfaces/SortOrder";
import { racehorse360 } from "@tsg/1st-grpc-web";

export type TPreRaceScreeningAction =
  | TSetSelectedTabPreRaceTable
  | TSetSelectedPreRaceFacility
  | TSetShowPassedEntriesCheckboxState
  | TSetTableRows
  | TFilterTableRow
  | TSetSortOrder
  | TSetOrderBy
  | TSetSearchValue
  | TSetFacilityBarns
  | TAddAssignCheckedId
  | TRemoveAssignCheckedId
  | TClearAssignCheckedIds
  | TSetShowAssignedToMeOnly;

type TSetSelectedTabPreRaceTable = ReturnType<
  typeof setSelectedTabPreRaceTable
>;
type TSetSelectedPreRaceFacility = ReturnType<
  typeof setSelectedPreRaceFacility
>;
type TSetShowPassedEntriesCheckboxState = ReturnType<
  typeof setShowPassedEntriesCheckboxState
>;
type TSetTableRows = ReturnType<typeof setTableRows>;
type TFilterTableRow = ReturnType<typeof filterTableRow>;
type TSetSortOrder = ReturnType<typeof setSortOrder>;
type TSetOrderBy = ReturnType<typeof setOrderBy>;
type TSetSearchValue = ReturnType<typeof setSearchValue>;
type TSetFacilityBarns = ReturnType<typeof setFacilityBarns>;
type TAddAssignCheckedId = ReturnType<typeof addAssignCheckedId>;
type TRemoveAssignCheckedId = ReturnType<typeof removeAssignCheckedId>;
type TClearAssignCheckedIds = ReturnType<typeof clearAssignCheckedIds>;
type TSetShowAssignedToMeOnly = ReturnType<typeof setShowAssignedToMeOnly>;

const base = "PRE_RACE_SCREENING_PAGE";

export const SET_SELECTED_PRE_RACE_TABLE_TAB = `${base}_SET_SELECTED_PRE_RACE_TABLE_TAB`;
export const SET_SELECTED_PRE_RACE_FACILITY = `${base}_SET_SELECTED_PRE_RACE_FACILITY`;
export const SET_SHOW_PASSED_ENTRIES_CHECKBOX_STATE = `${base}_SET_SHOW_PASSED_ENTRIES_CHECKBOX_STATE`;
export const SET_TABLE_ROWS = `${base}_SET_TABLE_ROWS`;
export const FILTER_TABLE_ROW = `${base}_FILTER_TABLE_ROW`;
export const SET_SORT_ORDER = `${base}_SET_SORT_ORDER`;
export const SET_ORDER_BY = `${base}_SET_ORDER_BY`;
export const SET_SEARCH_VALUE = `${base}_SET_SEARCH_VALUE`;
export const SET_FACILITY_BARNS = `${base}_SET_FACILITY_BARNS`;
export const ADD_ASSIGN_CHECKED_ID = `${base}_ADD_ASSIGN_CHECKED_ID`;
export const REMOVE_ASSIGN_CHECKED_ID = `${base}_REMOVE_ASSIGN_CHECKED_ID`;
export const CLEAR_ASSIGN_CHECKED_IDS = `${base}_CLEAR_ASSIGN_CHECKED_IDS`;
export const SET_SHOW_ASSIGNED_TO_ME_ONLY = `${base}_SET_SHOW_ASSIGNED_TO_ME_ONLY`;

export const setSelectedTabPreRaceTable = (selectedTab: IPreRaceTableTab) =>
  ({
    type: SET_SELECTED_PRE_RACE_TABLE_TAB,
    selectedTab
  } as const);

export const setSelectedPreRaceFacility = (
  selectedFacility: racehorse360.IFacility
) =>
  ({
    type: SET_SELECTED_PRE_RACE_FACILITY,
    selectedFacility
  } as const);

export const setShowPassedEntriesCheckboxState = (isChecked: boolean) =>
  ({
    type: SET_SHOW_PASSED_ENTRIES_CHECKBOX_STATE,
    isChecked
  } as const);

export const setTableRows = (rows: IExamsTableRowData[]) =>
  ({
    type: SET_TABLE_ROWS,
    rows
  } as const);

export const filterTableRow = (rowId: string) =>
  ({
    type: FILTER_TABLE_ROW,
    rowId
  } as const);

export const setSortOrder = (order: SortOrder) =>
  ({
    type: SET_SORT_ORDER,
    order
  } as const);

export const setOrderBy = (orderBy: EPreRaceTableColumnSorting) =>
  ({
    type: SET_ORDER_BY,
    orderBy
  } as const);

export const setSearchValue = (searchValue: string) =>
  ({
    type: SET_SEARCH_VALUE,
    searchValue
  } as const);

export const setFacilityBarns = (
  barns: Pick<racehorse360.IBarn, "id" | "name">[]
) =>
  ({
    type: SET_FACILITY_BARNS,
    barns
  } as const);

export const addAssignCheckedId = (checkedId: string) =>
  ({
    type: ADD_ASSIGN_CHECKED_ID,
    checkedId
  } as const);

export const removeAssignCheckedId = (unCheckedId: string) =>
  ({
    type: REMOVE_ASSIGN_CHECKED_ID,
    unCheckedId
  } as const);

export const clearAssignCheckedIds = () =>
  ({
    type: CLEAR_ASSIGN_CHECKED_IDS
  } as const);

export const setShowAssignedToMeOnly = (showAssignedToMeOnly: boolean) =>
  ({
    type: SET_SHOW_ASSIGNED_TO_ME_ONLY,
    showAssignedToMeOnly
  } as const);
