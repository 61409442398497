import { makeStyles } from "@material-ui/core/styles";
import { MuiTheme } from "theme";

export default makeStyles((theme: MuiTheme) => ({
  list: {
    padding: 0
  },
  listItem: {
    display: "flex",
    justifyContent: "space-between",
    minHeight: 52,
    padding: "0 16px",
    "&:nth-child(4n - 1)": {
      background: "#fbfbfb"
    }
  },
  listItemContainer: {
    display: "flex",
    alignItems: "center"
  },
  listItemLabel: {
    marginRight: 12,
    lineHeight: 1
  },
  listItemValue: {
    lineHeight: 1,
    textAlign: "right"
  },
  listItemText: {
    color: theme.palette.text.primary,
    fontSize: 14
  },
  none: {
    color: theme.palette.text.hint
  },
  mapIcon: {
    marginLeft: 14
  },
  barnNumberPicker: {
    padding: "0 0 0 12px",
    fontSize: 16,
    "& span": {
      paddingTop: 2
    }
  }
}));
