import React from "react";
import clsx from "clsx";

import { useAppSelector } from "hooks/redux/useAppSelector";
import TabFilters from "./TabFilters";
import PreRaceTableTabs from "./PreRaceTableTabs";
import { checkCurrentRaceEntriesTableTab } from "../helper";

import useStyles from "./styles";

const PreRaceScreeningSubHeader = () => {
  const classes = useStyles();
  const selectedTab = useAppSelector(
    state => state.preRaceScreeningPage.selectedTab
  );

  const { isEntryExamsTab } = checkCurrentRaceEntriesTableTab(
    selectedTab.value
  );

  return (
    <div
      className={clsx(classes.subHeader, { entryExamsTab: isEntryExamsTab })}
    >
      <PreRaceTableTabs />
      <TabFilters />
    </div>
  );
};

export default React.memo(PreRaceScreeningSubHeader);
