import React, { HTMLAttributes, useState } from "react";

import IconButton from "@material-ui/core/IconButton";
import RemoveCircleOutlineRoundedIcon from "@material-ui/icons/RemoveCircleOutlineRounded";

import { racehorse360 } from "@tsg/1st-grpc-web";

import { IExamsTableRowData } from "components/VetExamsTable/types";
import TableCellWrapper from "components/VetExamsTable/TableCellWrapper";
import ErrorBoundary from "components/ErrorBoundary";
import Loader from "components/Loader";
import { useLoggedInUser } from "components/LoggedInUserProvider";
import ConfirmPopUp from "./UnAssignConfirmPopUp";

import useStyles from "./styles";

interface IProps extends HTMLAttributes<HTMLDivElement> {
  onConfirm: () => void;
  rowData: IExamsTableRowData;
  isUnAssignSaving?: boolean;
}

const UnAssignExamCell = (props: IProps) => {
  const { rowData, onConfirm, isUnAssignSaving = false, ...restProps } = props;
  const { examAssignedUser, examStatus } = rowData;

  const classes = useStyles();

  const {
    currentUser: { isTrackVet, isChiefVet, isSeniorVet, rh360Id }
  } = useLoggedInUser();

  const isCurrentChiefOrSeniorVet = [isChiefVet, isSeniorVet].some(Boolean);
  const isAssignedToCurrentTrackVet = [
    isTrackVet,
    examAssignedUser?.id === rh360Id
  ].every(Boolean);
  const isUnAssignAllowed =
    (isCurrentChiefOrSeniorVet || isAssignedToCurrentTrackVet) &&
    examStatus === racehorse360.HorseExamStatus.HORSE_EXAM_STATUS_REQUESTED;

  const [isConfirmPopupOpen, setIsConfirmPopupOpen] = useState(false);

  const handleOpenConfirmPopup = () => {
    setIsConfirmPopupOpen(true);
  };

  const handleCloseConfirmPopup = () => {
    setIsConfirmPopupOpen(false);
  };

  const handleConfirm = () => {
    setIsConfirmPopupOpen(false);
    onConfirm();
  };

  const renderCellContent = () => {
    if (!examAssignedUser?.id) return;

    if (isUnAssignSaving) {
      return <Loader size={14} disableGutters={true} />;
    }

    return (
      <IconButton
        className={classes.unassign}
        size={"small"}
        disabled={!isUnAssignAllowed}
        onClick={handleOpenConfirmPopup}
      >
        <RemoveCircleOutlineRoundedIcon fontSize={"small"} />
      </IconButton>
    );
  };

  return (
    <TableCellWrapper {...restProps} centered>
      {renderCellContent()}

      {isConfirmPopupOpen && (
        <ErrorBoundary>
          <ConfirmPopUp
            title="CONFIRM UN-ASSIGN?"
            content="The horse exam will be placed back to un-assigned after confirming."
            onClose={handleCloseConfirmPopup}
            onConfirm={handleConfirm}
            rowData={rowData}
          />
        </ErrorBoundary>
      )}
    </TableCellWrapper>
  );
};

export default React.memo(UnAssignExamCell);
