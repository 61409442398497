import { makeStyles } from "@material-ui/core/styles";
import { MuiTheme } from "theme";

export default makeStyles((theme: MuiTheme) => ({
  selectWrapper: {
    maxWidth: 55,
    minWidth: 55
  },
  root: {
    padding: "1px 0 2px",
    fontSize: 14,
    paddingLeft: 6,
    "&.MuiSelect-outlined": {
      paddingRight: 16,
      position: "relative",
      top: 1
    }
  },
  select: {
    "&:focus": {
      backgroundColor: "transparent"
    }
  },
  iconOutlined: {
    right: 0,
    color: theme.palette.primary.main
  },
  paper: {
    maxHeight: "calc(100dvh/2)"
  },
  selectList: {
    padding: "4px 0px",
    "& .MuiListItem-root": {
      padding: "2px 8px",
      fontSize: 14,
      minHeight: "auto",
      height: 25
    }
  },
  container: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    width: "100%",
    "&.editMode": {
      justifyContent: "space-between",
      padding: 0
    },
    "&.fadeOut": {
      color: "#babac0"
    }
  },
  loader: {
    padding: 0
  }
}));
