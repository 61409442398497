import { makeStyles } from "@material-ui/core/styles";
import { MuiTheme } from "theme";

export default makeStyles((theme: MuiTheme) => ({
  mapRoot: {
    boxShadow: "0px 1px 7px 0 rgba(0,0,0,0.1)",
    borderRadius: "4px",
    overflow: "hidden",
    position: "relative"
  },
  default: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      height: 75,
      "& $header": {
        height: 75,
        padding: "12px 16px"
      },
      "& $facilityName": {
        lineHeight: "normal",
        whiteSpace: "nowrap"
      },
      "& $barn, & $stall": {
        width: "50%",
        color: "#babac3",
        fontSize: 14,
        lineHeight: "normal",
        marginRight: 4
      },
      "& $number": {
        fontSize: 14,
        color: theme.palette.text.primary,
        lineHeight: "normal"
      }
    }
  },
  header: {
    backgroundColor: theme.palette.common.white,
    display: "flex",
    height: 52,
    padding: "18px 16px",
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    "& $unavailableLocation": {
      width: "100%"
    }
  },
  facilityName: {
    fontFamily: "SuisseIntlMedium, SuisseIntlRegular",
    flex: 1,
    fontSize: 16
  },
  barnStallWrapper: {
    display: "flex"
  },
  barn: {
    display: "flex",
    color: "#6f6f6f",
    marginRight: 8
  },
  barnText: {
    marginRight: 4
  },
  stall: {
    display: "flex",
    color: "#6f6f6f"
  },
  stallText: {
    marginRight: 4
  },
  number: {
    fontWeight: "bold",
    color: theme.palette.text.primary
  },
  wrapper: {
    width: "100%",
    height: 318,
    borderRadius: "0 0 4px 4px",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundColor: theme.palette.common.white,
    [theme.breakpoints.down("sm")]: {
      height: 75
    },
    overflow: "hidden"
  },
  innerWrapper: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
  },
  relative: {
    position: "relative"
  },
  comingSoonBanner: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "28px",
    fontWeight: 600,
    color: "black",
    backgroundColor: "grey",
    opacity: 0.8,
    borderRadius: 0
  },
  tools: {},
  tool: {
    zIndex: 1,
    position: "absolute",
    padding: 9,
    borderRadius: 3,
    backgroundColor: "#103827",
    color: theme.palette.secondary.main,
    bottom: 24,
    "&:hover": {
      backgroundColor: "#103827"
    },
    "& .MuiSvgIcon-root": {
      fontSize: 30
    }
  },
  refreshTool: {
    left: 24,
    [theme.breakpoints.down("sm")]: {
      left: 10,
      bottom: 85
    }
  },
  zoomInTool: {
    right: 24,
    bottom: 82,
    [theme.breakpoints.down("sm")]: {
      bottom: 139,
      right: 10
    }
  },
  zoomOutTool: {
    right: 24,
    [theme.breakpoints.down("sm")]: {
      right: 10,
      bottom: 85
    }
  },
  content: {
    flex: "auto",
    maxHeight: "calc(100vh - 124px)",
    display: "flex",
    borderRadius: "3px 3px 0 0"
  },
  fullscreen: {
    flex: "auto",
    display: "flex",
    flexDirection: "column",
    padding: 0,
    background: "#fff",
    zIndex: theme.zIndex.app.logo + 1,
    borderRadius: 0,
    boxShadow: "none",
    "& $wrapper": {
      flex: "auto",
      height: "auto"
    }
  },
  fullscreenHorseList: {
    display: "flex",
    flexDirection: "column",
    minWidth: 380,
    height: "100%",
    boxShadow: "0px 1px 7px 0 rgba(0,0,0,0.1)",
    backgroundColor: theme.palette.common.white,
    zIndex: 1,
    [theme.breakpoints.down("sm")]: {
      height: "auto",
      position: "absolute",
      top: 0,
      left: 0,
      right: 0
    },
    [theme.breakpoints.up("xl")]: {
      minWidth: 420
    },
    [theme.breakpoints.up("lg")]: {
      minWidth: 380
    }
  },
  fullscreenTool: {
    padding: 2,
    backgroundColor: theme.palette.common.white,
    right: 24,
    color: theme.palette.text.primary,
    boxShadow: "0px 1px 7px 0 rgba(0,0,0,0.1)",
    "&:hover": {
      backgroundColor: theme.palette.common.white
    }
  },
  bold: {
    fontFamily: "SuisseIntlMedium, SuisseIntlRegular"
  },
  barnDialogRoot: {
    position: "absolute"
  },
  barnDialogPaper: {
    width: 296
  },
  barnDialogBackdrop: {
    position: "absolute"
  },
  barnDialogTitle: {
    display: "flex",
    alignItems: "center",
    padding: "0 4px 0 16px",
    fontSize: 20,
    height: 55,
    "& .MuiTypography-root": {
      flex: "auto",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center"
    }
  },
  barnDialogContent: {
    padding: 0,
    borderTop: `1px solid ${theme.palette.text.hint}`
  },
  barnDialogRow: {
    height: 49
  },
  mapPlaceholder: {
    flex: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 16
  },
  unavailableLocation: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flex: 1,
    fontFamily: "SuisseIntlMedium, SuisseIntlRegular",
    fontSize: 14,
    lineHeight: "normal",
    whiteSpace: "nowrap",
    color: "#dadada"
  },
  locationText: {
    color: "inherit"
  },
  mobileWithoutMap: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center"
  },
  mobileWithMap: {
    width: 156,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start"
  },
  facilityWithMap: {
    flex: "0 1 auto",
    fontSize: 14
  },
  fullScreenLoader: {
    position: "absolute",
    zIndex: 2,
    backgroundColor: "#fff",
    opacity: 0.5
  }
}));
