import React, { useEffect, useState } from "react";
import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import clsx from "clsx";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useTheme from "@material-ui/core/styles/useTheme";

import { racehorse360 } from "@tsg/1st-grpc-web";

import { MuiTheme } from "theme";
import Loader from "components/Loader";
import { useFacilityBarns } from "components/BarnNumberPicker/useFacilityBarns";
import HorseTableRow from "./HorseTableRow";

import useStyles from "./styles";

interface Props {
  maxHeight?: number;
  onHorseClick?: (horse: racehorse360.IHorse) => void;
  selectedHorse?: racehorse360.IHorse;
  selectedFacilityId: string;
  horses: racehorse360.IHorse[];
  isLoading: boolean;
  mapBarns: Record<string, unknown>;
  onBarnChange: (barn: racehorse360.IBarn, horseId: string) => void;
}

const HorseTable = (props: Props) => {
  const {
    horses,
    isLoading,
    onHorseClick,
    maxHeight,
    selectedHorse,
    selectedFacilityId,
    mapBarns,
    onBarnChange
  } = props;

  const classes = useStyles();
  const theme = useTheme<MuiTheme>();
  const matchesDownSm = useMediaQuery(theme.breakpoints.down("sm"), {
    noSsr: true
  });
  const matchesDownXs = useMediaQuery(theme.breakpoints.down("xs"), {
    noSsr: true
  });

  const [isListOpen, setIsListOpen] = useState<boolean>(false);
  const [defaultHorse, setDefaultHorse] =
    useState<racehorse360.IHorse>(selectedHorse);

  const { facilityBarns } = useFacilityBarns(selectedFacilityId);

  const getMaxHeight = () => {
    if (matchesDownXs) return "calc(100vh - 112px)"; //-pageHeader and mapHeader
    if (matchesDownSm) return "calc(100vh - 120px)"; //-pageHeader and mapHeader
    return maxHeight;
  };

  const getHeight = () => {
    if (matchesDownSm) {
      if (isListOpen) {
        return getMaxHeight();
      } else {
        return 47 * 2 + "px";
      }
    }
    return maxHeight;
  };

  const handleHorseClick = (horse: racehorse360.IHorse) => {
    onHorseClick && onHorseClick(horse);
    setIsListOpen(false);
  };

  const handleCollapseClick = () => {
    setIsListOpen(!isListOpen);
  };

  const filterHorses = (horses: racehorse360.IHorse[]) => {
    if (matchesDownSm && !isListOpen) {
      return [defaultHorse || selectedHorse];
    }
    return horses;
  };

  const filteredHorses = filterHorses(horses);

  const renderRow = ({ index, style }) => (
    <HorseTableRow
      index={index}
      style={style}
      horse={filteredHorses[index]}
      mapBarns={mapBarns}
      selectedHorseId={selectedHorse?.id}
      onHorseClick={handleHorseClick}
      facilityBarns={facilityBarns}
      selectedFacilityId={selectedFacilityId}
      onBarnChange={onBarnChange}
    />
  );

  useEffect(() => {
    if (selectedHorse) {
      setDefaultHorse(selectedHorse);
    }
  }, [selectedHorse]);

  return (
    <Box className={classes.listRoot}>
      {isLoading ? (
        <Loader />
      ) : (
        <Box
          className={classes.listContainer}
          style={{
            maxHeight: getMaxHeight(),
            height: getHeight()
          }}
        >
          <Box className={clsx(classes.listRow, classes.listHeader)}>
            <Box className={clsx(classes.headerCell, classes.horseNameCell)}>
              Horse
            </Box>
            <Box className={clsx(classes.headerCell, classes.barnCell)}>
              Barn
            </Box>
            <Box className={clsx(classes.headerCell, classes.valueCell)}>
              Stall
            </Box>
            <Box className={clsx(classes.headerCell, classes.scopeCell)}>
              <Button
                className={classes.collapseButton}
                onClick={handleCollapseClick}
                size="small"
              >
                {isListOpen ? (
                  <>
                    Less
                    <KeyboardArrowUp fontSize={"small"} />
                  </>
                ) : (
                  <>
                    More
                    <KeyboardArrowDown fontSize={"small"} />
                  </>
                )}
              </Button>
            </Box>
          </Box>
          {!isLoading && !horses.length && (
            <Box className={classes.listRow}>
              <Box className={clsx(classes.bodyCell, classes.horseNameCell)}>
                No Results
              </Box>
            </Box>
          )}
          <AutoSizer>
            {({ height, width }) => (
              <List
                height={height}
                itemCount={filteredHorses.length}
                itemSize={47}
                width={width}
              >
                {renderRow}
              </List>
            )}
          </AutoSizer>
        </Box>
      )}
    </Box>
  );
};

export default HorseTable;
