import { makeStyles } from "@material-ui/core/styles";
import { MuiTheme } from "theme";
import Breakpoints from "common/breakpoints";

export default makeStyles((theme: MuiTheme) => {
  const { primary, secondary } = theme.palette;

  return {
    primaryFilter: {
      display: "flex",
      alignItems: "center",
      padding: "8px 8px 0 8px"
    },
    secondaryFilter: {
      display: "flex",
      alignItems: "center",
      padding: "0 8px",
      [theme.breakpoints.down(Breakpoints.XS_420)]: {
        flexDirection: "column",
        alignItems: "flex-start"
      }
    },
    pickerFilter: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      height: 44,
      [theme.breakpoints.down(Breakpoints.XS_420)]: {
        justifyContent: "space-between",
        width: "100%"
      }
    },
    facility: {
      display: "flex",
      height: 44,
      width: "100%"
    },
    assignedToMe: {
      display: "flex",
      height: 44,
      minWidth: 152
    },
    searchFilter: {
      padding: 8,
      width: "100%"
    },
    searchFilterInput: {
      [theme.breakpoints.down(Breakpoints.XS_420)]: {
        maxWidth: "100%"
      }
    },
    saveSelectionsContainer: {
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "flex-end",
      height: 44,
      [theme.breakpoints.down(Breakpoints.XS_420)]: {
        marginRight: 8
      },
      [theme.breakpoints.up(Breakpoints.SM_600)]: {
        padding: 8
      }
    },
    saveSelections: {
      fontSize: "12px",
      color: primary.main,
      backgroundColor: "rgba(3, 243, 178, 1.0)",
      borderRadius: "2px",
      minWidth: 140,
      padding: "6px 16px",
      "&:hover": {
        backgroundColor: "rgba(3, 243, 178, 1.0)"
      },
      "&.Mui-disabled": {
        backgroundColor: "#d8d8d8",
        color: primary.dark
      }
    },
    rightSideGroup: {
      display: "flex"
    },
    downloadButton: {
      display: "inline-flex"
    },
    downloadIcon: {
      color: secondary.dark,
      fontSize: "26px"
    }
  };
});
