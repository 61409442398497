import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { matchPath, useHistory, useRouteMatch } from "react-router-dom";
import clsx from "clsx";

import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";
import { Theme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { racehorse360 } from "@tsg/1st-grpc-web";

import routes from "common/routes";
import Activity from "components/Activity";
import DaysOff from "components/DaysOff";
import ErrorBoundary from "components/ErrorBoundary";
import InfoPanelsGrid from "components/InfoPanelsGrid";
import Loader from "components/Loader";
import Map from "components/Map";
import AppPage from "components/AppPage";
import AppPageContent from "components/AppPageContent";
import AppPageHeader from "components/AppPageHeader";
import TabsComponent from "components/Tabs";
import TitledContainer from "components/TitledContainer";
import FacilitySelect from "components/FacilitySelect";
import { useLoggedInUser } from "components/LoggedInUserProvider";
import { useRacehorse360Api } from "hooks/api";
import ITab from "interfaces/Tab";
import Header from "./Header";
import Information from "./Information";
import PreferredCondition from "./PreferredCondition";
import UpcomingEvents from "./UpcomingEvents";
import Comments from "./Comments";
import { setMapPopupState } from "store/actions/trainerHorseDetailsPage";
import IQueriesConfigState from "interfaces/queriesConfigState";
import ITrainerHorseDetailsPageState from "interfaces/TrainerHorseDetailsPageState";
import MatchedRacesPopover from "./MatchedRacesPopover";
import useStyles from "./styles";

const tabsList: ITab[] = [
  { name: "Details", value: "details", path: routes.horseDetails.path },
  {
    name: "Activity",
    value: "activity",
    path: routes.horseDetailsActivity.path
  },
  {
    name: "Comments",
    value: "comments",
    path: routes.horseDetailsComments.path
  }
];

const fieldsForGetHorse = [
  "id",
  "stall.name",
  "barn.name",
  "currentFacility.name",
  "currentFacility.code",
  "currentFacility.workout_required_days_off",
  "currentFacility.region.id",
  "currentFacility.backgroundColor",
  "currentFacility.foregroundColor",
  "currentFacility.strokeColor",
  "chip_number",
  "barn.facility.name",
  "barn.facility.code",
  "barn.facility.foregroundColor",
  "birthday",
  "dam_name",
  "last_race_date",
  "last_race_facility.code",
  "last_race_facility.name",
  "last_workout_date",
  "last_workout_facility.code",
  "last_workout_facility.name",
  "last_exam_date",
  "name",
  "breeder.name",
  "owner.first_name",
  "owner.last_name",
  "registration_number",
  "sire_name",
  "registration_number",
  "registration_status",
  "breed_type",
  "gender",
  "certificate_issued_date",
  "breeding_year",
  "color_description",
  "created_on",
  "updated_on",
  "common_id",
  "trainer.first_name",
  "trainer.last_name",
  "stateEligibleCode",
  "dateOfDeath",
  "lifetime"
];

const getTabFromPath = (pathname: string) => {
  return tabsList.find(t =>
    matchPath(pathname, {
      path: t.path,
      exact: true,
      strict: true
    })
  );
};

const TrainerHorseDetailsPage = () => {
  const match = useRouteMatch<{ horseId: string }>(routes.horseDetails.path);

  const classes = useStyles();
  const matchesDownSm = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const matchesDownXs = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xs")
  );

  const rootRef = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const { currentUser } = useLoggedInUser();

  const [tabs, setTabs] = useState<ITab[]>(tabsList);
  const [activeTab, setActiveTab] = useState<ITab>(
    getTabFromPath(history.location.pathname)
  );
  const [selectedFacility, setSelectedFacility] =
    useState<racehorse360.IFacility>(null);
  const [isOpenMatchedRacesPopover, setIsOpenMatchedRacesPopover] =
    useState<boolean>(false);
  const [currentHorse, setCurrentHorse] = useState<racehorse360.IHorse>();

  const { isOpenPreferredConditions, isMapFullscreen, currentFacilityId } =
    useSelector(
      (state: {
        trainerHorseDetailsPage: ITrainerHorseDetailsPageState;
        queriesConfig: IQueriesConfigState;
      }) => ({
        isOpenPreferredConditions:
          state.trainerHorseDetailsPage.preferredConditionsState,
        isMapFullscreen: state.trainerHorseDetailsPage.mapPopupState,
        currentFacilityId: state.queriesConfig?.currentFacilityId
      })
    );

  const {
    useGetHorse,
    usePullHorseAggregatedComments,
    usePullRaceConditionsForHorse
  } = useRacehorse360Api();

  const { isLoading: isHorseLoading, data: horse } = useGetHorse(
    {
      id: match.params.horseId,
      getOptions: {
        select: fieldsForGetHorse
      }
    },
    {
      onSuccess: (horse: racehorse360.IHorse) => {
        setSelectedFacility(horse.barn?.facility || horse.currentFacility);
      }
    }
  );

  const horseId = currentHorse?.id;
  const horseFacilityId =
    currentHorse?.barn?.facility?.id || currentHorse?.currentFacility?.id;

  const {
    data: horseMatchesData,
    isLoading: isMatchesLoading,
    refetch: refetchHorseMatchesData
  } = usePullRaceConditionsForHorse(
    `${horseId}-matches`,
    {
      horseId: horseId
    },
    {
      enabled: Boolean(horseId)
    }
  );
  const matchedRaces = horseMatchesData?.raceConditions;

  const {
    data: horseAggregatedCommentsData,
    isLoading: isCommentsLoading,
    refetch: refetchComments,
    isFetching: isCommentsFetching
  } = usePullHorseAggregatedComments(
    {
      query: {
        horseId: currentHorse?.id
      }
    },
    {
      enabled: Boolean(horseId),
      onSuccess: data => {
        const hasUnreadComments = data.horseAggregatedComments.some(
          comment => !comment.isRead
        );
        const newTabs = tabs.map(tab => {
          const newTab = {
            ...tab,
            path: tab.path.replace(":horseId", match.params.horseId)
          };

          return tab.name === "Comments"
            ? { ...newTab, isUpdated: hasUnreadComments }
            : newTab;
        });

        setTabs(newTabs);
      }
    }
  );
  const comments = horseAggregatedCommentsData?.horseAggregatedComments;

  const isLoading = [isHorseLoading, isMatchesLoading, isCommentsLoading].some(
    item => item
  );

  const handleTabChange = (tab: ITab) => {
    setActiveTab(tab);
  };

  const handleMapOpen = () => {
    dispatch(setMapPopupState(true));
  };

  const handleMapClose = () => {
    dispatch(setMapPopupState(false));
  };

  const handleMapFullscreenClick = () => {
    dispatch(setMapPopupState(true));
  };

  const handleChangeFacility = (facility: racehorse360.IFacility) => {
    setSelectedFacility(facility);
  };

  const handleOpenMatchedRacesPopover = useCallback(() => {
    setIsOpenMatchedRacesPopover(true);
  }, [setIsOpenMatchedRacesPopover]);

  const handleCloseMatchedRacesPopover = useCallback(() => {
    setIsOpenMatchedRacesPopover(false);
  }, [setIsOpenMatchedRacesPopover]);

  const handleBarnChange = (barn: racehorse360.IBarn) => {
    setCurrentHorse({ ...currentHorse, barn });
  };

  const handleBarnChangeOnMap = (barn: racehorse360.IBarn, horseId: string) => {
    if (currentHorse.id === horseId) {
      handleBarnChange(barn);
    }
  };

  useEffect(() => {
    if (
      currentUser.isTrainer &&
      currentHorse &&
      currentFacilityId &&
      currentFacilityId !== horseFacilityId
    ) {
      history.push(routes.horseList.path);
    }
  }, [currentFacilityId, horseFacilityId]);

  useEffect(() => {
    setCurrentHorse(horse);
  }, [horse]);

  useEffect(() => {
    const list = tabs.map(item => ({
      ...item,
      path: item.path.replace(":horseId", match.params.horseId)
    }));
    setTabs(list);
    return () => {
      dispatch(setMapPopupState(false));
    };
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  if (!currentHorse) return null;

  return (
    <AppPage className={clsx({ [classes.rootBorder]: isMapFullscreen })}>
      <ErrorBoundary>
        {!isOpenPreferredConditions && (
          <AppPageHeader
            className={clsx(classes.appPageHeader, {
              [classes.noBorder]: isMapFullscreen
            })}
          >
            {isMapFullscreen ? (
              <div className={classes.mapHeader}>
                <Typography component={"b"} className={classes.mapHeaderTitle}>
                  Location
                </Typography>
                <div className={classes.mapFacilitySelectWrapper}>
                  <ErrorBoundary>
                    <FacilitySelect
                      isActive={true}
                      selectedFacility={
                        selectedFacility || currentHorse.currentFacility
                      }
                      onChange={handleChangeFacility}
                    />
                  </ErrorBoundary>
                </div>
                <IconButton
                  size={matchesDownXs ? "small" : "medium"}
                  onClick={handleMapClose}
                >
                  <Close />
                </IconButton>
              </div>
            ) : (
              <>
                <Header horse={currentHorse} />
                {matchesDownSm && (
                  <TabsComponent
                    tabs={tabs}
                    selected={activeTab}
                    onChange={handleTabChange}
                    className={classes.appPageHeaderTabs}
                  />
                )}
              </>
            )}
          </AppPageHeader>
        )}
      </ErrorBoundary>
      <AppPageContent
        className={clsx(
          classes.appPageContentContainer,
          classes[activeTab.value],
          {
            [classes.progress]: isLoading
          }
        )}
      >
        {isMapFullscreen ? (
          <ErrorBoundary>
            <Map
              currentHorse={currentHorse}
              onOpen={handleMapOpen}
              onClose={handleMapClose}
              selectedFacility={
                selectedFacility || currentHorse.currentFacility
              }
              onBarnChange={handleBarnChangeOnMap}
            />
          </ErrorBoundary>
        ) : (
          <div className={classes["grid-container"]}>
            <div className={classes["grid-details-and-days"]}>
              <div
                className={clsx(
                  classes["grid-info-panels"],
                  classes[tabs[0].value]
                )}
              >
                <TitledContainer title={"Details and Activity"}>
                  <ErrorBoundary>
                    <InfoPanelsGrid horse={currentHorse} />
                  </ErrorBoundary>
                </TitledContainer>
              </div>
              <div
                className={clsx(
                  classes["grid-days-off"],
                  classes[tabs[1].value]
                )}
              >
                <TitledContainer
                  title={matchesDownSm ? "Race / Work Days Off" : "Days Off"}
                >
                  <ErrorBoundary>
                    <DaysOff horse={currentHorse} showViewHorseButton={false} />
                  </ErrorBoundary>
                </TitledContainer>
              </div>
            </div>
            <div
              className={clsx(classes["grid-activity"], classes[tabs[1].value])}
            >
              <ErrorBoundary>
                <Activity horse={currentHorse} rootRef={rootRef} />
              </ErrorBoundary>
            </div>
            <div className={classes["grid-info"]}>
              <div
                className={clsx(
                  classes["grid-information"],
                  classes[tabs[0].value]
                )}
              >
                <TitledContainer title={"Information"}>
                  <ErrorBoundary>
                    <Information
                      horse={currentHorse}
                      onBarnChange={handleBarnChange}
                      selectedFacilityId={selectedFacility?.id}
                    />
                  </ErrorBoundary>
                </TitledContainer>
              </div>
              <div
                className={clsx(
                  classes["grid-location"],
                  classes[tabs[0].value]
                )}
              >
                <TitledContainer title={"Location"}>
                  <ErrorBoundary>
                    <Map
                      currentHorse={currentHorse}
                      onFullscreenClick={handleMapFullscreenClick}
                      isPreview
                      selectedFacility={
                        selectedFacility || currentHorse.currentFacility
                      }
                      onBarnChange={handleBarnChangeOnMap}
                    />
                  </ErrorBoundary>
                </TitledContainer>
              </div>
            </div>
            <div className={classes["grid-right-rail"]}>
              <div
                className={clsx(
                  classes["grid-preferred-condition"],
                  classes[tabs[0].value]
                )}
              >
                <PreferredCondition
                  horse={
                    currentHorse || {
                      id: match.params.horseId
                    }
                  }
                  matchedRaces={matchedRaces}
                  onOpenMatchedRacesPopover={handleOpenMatchedRacesPopover}
                  refetchMatchedRaces={refetchHorseMatchesData}
                />
              </div>
              <div
                className={clsx(
                  classes["grid-upcoming-events"],
                  classes[tabs[1].value]
                )}
              >
                <ErrorBoundary>
                  <UpcomingEvents horse={currentHorse} />
                </ErrorBoundary>
              </div>
              <div
                className={clsx(
                  classes["grid-comments"],
                  classes[tabs[2].value]
                )}
              >
                <ErrorBoundary>
                  <Comments
                    horse={currentHorse}
                    commentsList={comments}
                    refetchComments={refetchComments}
                    isCommentsFetching={isCommentsFetching}
                  />
                </ErrorBoundary>
              </div>
            </div>
          </div>
        )}
      </AppPageContent>
      <MatchedRacesPopover
        isOpen={isOpenMatchedRacesPopover}
        races={matchedRaces}
        onClose={handleCloseMatchedRacesPopover}
      />
    </AppPage>
  );
};

export default TrainerHorseDetailsPage;
