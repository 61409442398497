import React from "react";
import clsx from "clsx";

import Checkbox, { CheckboxProps } from "@material-ui/core/Checkbox";
import CheckIcon from "@material-ui/icons/Check";

import Loader from "components/Loader";

import useStyles from "./styles";

interface IProps extends CheckboxProps {
  isLoading: boolean;
  dataTestId?: string;
}

const ActionCellCheckbox = (props: IProps) => {
  const { dataTestId, isLoading, disabled, className, ...restProps } = props;

  const classes = useStyles();

  const checkBoxIcon = isLoading ? (
    <Loader size={16} disableGutters={true} />
  ) : (
    <span className={classes.checkedIconWrapper}>
      <CheckIcon className={classes.checkedIcon} />
    </span>
  );

  return (
    <Checkbox
      className={clsx(classes.checkbox, className)}
      disableRipple
      disabled={disabled}
      color="default"
      icon={<span className={classes.checkboxIcon} />}
      checkedIcon={checkBoxIcon}
      data-test-id={dataTestId}
      {...restProps}
    />
  );
};

export default React.memo(ActionCellCheckbox);
