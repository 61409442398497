import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

interface Props {
  className?: string;
}

const MapEdit = (props: Props) => {
  const { className } = props;

  return (
    <SvgIcon
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        d="M14.7239 4.50141L13.8322 3.60974L9.20719 8.23474V9.13474H10.1072L14.7239 4.50141ZM13.9905 1.61807C14.106 1.50117 14.2436 1.40835 14.3952 1.345C14.5468 1.28164 14.7095 1.24902 14.8739 1.24902C15.0382 1.24902 15.2009 1.28164 15.3525 1.345C15.5042 1.40835 15.6417 1.50117 15.7572 1.61807L16.7155 2.57641C17.2072 3.06807 17.2072 3.85974 16.7155 4.34307L10.6489 10.4097H7.91553V7.69307L13.9905 1.61807ZM11.0155 2.23474L9.49886 3.75141C6.74886 3.79307 4.58219 5.91807 4.58219 8.91807C4.58219 10.8681 6.20719 13.4514 9.58219 16.5347C12.9572 13.4514 14.5822 10.8764 14.5822 8.91807V8.83474L16.0822 7.33474C16.1905 7.83474 16.2489 8.36807 16.2489 8.91807C16.2489 11.6847 14.0239 14.9597 9.58219 18.7514C5.14053 14.9597 2.91553 11.6847 2.91553 8.91807C2.91553 4.76807 6.08219 2.08474 9.58219 2.08474C10.0655 2.08474 10.5489 2.13474 11.0155 2.23474Z"
        fill="black"
      />
    </SvgIcon>
  );
};

export default React.memo(MapEdit);
