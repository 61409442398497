import { makeStyles } from "@material-ui/core/styles";

export default makeStyles({
  actionsContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  actionButton: {
    padding: 4,
    margin: -2
  },
  confirm: {
    color: "#2ab24b"
  },
  cancel: {
    color: "#ee1923"
  },
  actionIcon: {
    width: 20,
    height: 20
  }
});
