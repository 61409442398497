import { useMemo, useRef } from "react";
import { InfiniteData } from "@tanstack/react-query";

import { racehorse360 } from "@tsg/1st-grpc-web";

import { EPreRacesTableTab } from "pages/PreRaceScreeningPage/types";
import { examsRowDataMapper, updateRowsAccordingToNewPages } from "./helper";
import { IExamsTableRowData } from "./types";

export const useUpdateInfiniteTableData = <
  T extends racehorse360.IWorkoutRequest | racehorse360.IUpcomingRaceEntry
>(
  data: InfiniteData<T[]>,
  existingRows: IExamsTableRowData[],
  selectedTab?: EPreRacesTableTab
): IExamsTableRowData[] => {
  const pagesAmount = useRef(0);

  const newPagesAmount = data?.pages.length || 0;

  return useMemo(() => {
    if (newPagesAmount > pagesAmount.current) {
      pagesAmount.current = newPagesAmount;

      return updateRowsAccordingToNewPages(
        data?.pages.flat(),
        existingRows,
        selectedTab
      );
    }

    pagesAmount.current = 0;

    return data?.pages
      ?.flat()
      ?.map(item => examsRowDataMapper(item, selectedTab));
  }, [data]);
};
