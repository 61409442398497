import { MuiTheme } from "theme";
import { makeStyles } from "@material-ui/core/styles";
import Breakpoints from "common/breakpoints";

export default makeStyles((theme: MuiTheme) => {
  const { primary } = theme.palette;

  return {
    pageHeader: {
      height: 95,
      [theme.breakpoints.down(Breakpoints.SM_600)]: {
        "&.raceDayTab": {
          height: 136
        }
      }
    },
    firstLevelFilters: {
      display: "flex",
      padding: "8px 16px 0 16px",
      [theme.breakpoints.down(Breakpoints.XS_420)]: {
        width: "100%",
        padding: "8px 8px 0 8px"
      }
    },
    secondLevelFilters: {
      display: "flex",
      alignItems: "center",
      padding: "0 16px",
      [theme.breakpoints.down(Breakpoints.XS_420)]: {
        padding: "0 8px",
        flexDirection: "column",
        alignItems: "flex-start"
      }
    },
    thirdLevelFilters: {
      display: "none",
      [theme.breakpoints.down(Breakpoints.SM_600)]: {
        display: "block"
      }
    },
    facility: {
      display: "flex",
      height: 44,
      width: "100%"
    },
    assignedToMe: {
      display: "flex",
      height: 44,
      minWidth: 152
    },
    searchFilter: {
      padding: 8,
      width: "100%"
    },
    searchFilterInput: {
      [theme.breakpoints.down(Breakpoints.XS_420)]: {
        maxWidth: "100%"
      }
    },
    saveSelectionsContainer: {
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "flex-end",
      height: 44,
      [theme.breakpoints.down(Breakpoints.XS_420)]: {
        marginRight: 8
      },
      [theme.breakpoints.up(Breakpoints.SM_600)]: {
        padding: 8
      }
    },
    saveSelections: {
      fontSize: "12px",
      color: primary.main,
      backgroundColor: "rgba(3, 243, 178, 1.0)",
      borderRadius: "2px",
      minWidth: 140,
      padding: "6px 16px",
      "&:hover": {
        backgroundColor: "rgba(3, 243, 178, 1.0)"
      },
      "&.Mui-disabled": {
        backgroundColor: "#d8d8d8",
        color: primary.dark
      }
    },
    rightSideGroup: {
      display: "flex"
    },
    search: {
      [theme.breakpoints.down(Breakpoints.SM_600)]: {
        maxWidth: "100%"
      }
    },
    pickerFilterDesktop: {
      [theme.breakpoints.down(Breakpoints.SM_600)]: {
        display: "none"
      }
    }
  };
});
