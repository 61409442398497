import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import IconButton from "@material-ui/core/IconButton";
import Switch from "@material-ui/core/Switch";

import { racehorse360 } from "@tsg/1st-grpc-web";

import AppSearch from "components/AppSearch";
import ErrorBoundary from "components/ErrorBoundary";
import FacilitySelect from "components/FacilitySelect";
import DownloadIcon from "components/Icons/Download";
import { useDebouncedNameValue } from "hooks/useSearch";
import DateRange, { DateRangeISO, DateRangeType } from "interfaces/DateRange";
import {
  setAssignedOnly as setStoreAssignedOnly,
  setSearchQuery as setStoreSearchQuery,
  setSelectedDateRange as setStoreSelectedDateRange,
  setSelectedFacility as setStoreSelectedFacility
} from "store/actions/vetWorkoutsPage";
import {
  withClickBlocker,
  WithClickBlockerProps
} from "components/BlockableClickContext";
import DateRangeSelect from "components/DateRangeSelect";
import { IExamsTableRowData } from "components/VetExamsTable/types";
import AssignedToMeToggle from "components/VetExamsAssignment/AssignedToMeToggle";
import AssignToVetButton from "components/VetExamsAssignment/AssignToVetButton";
import WorkoutRequestReportDialog from "./WorkoutRequestReportDialog";
import { checkCurrentWorkoutsTableTab } from "../helper";
import { useVetWorkoutsStore } from "../useVetWorkoutsStore";

import useStyles from "./styles";

interface IProps {
  workoutRequestsQuery: racehorse360.IWorkoutRequestFilter;
  openAssignToVetDialog: () => void;
  openConfirmSelectionsDialog: (value: boolean) => void;
  clearSearch: boolean;
  onClearSearch: (value: boolean) => void;
  tableRows: IExamsTableRowData[];
}

const Header: React.FC<IProps & WithClickBlockerProps> = props => {
  const {
    workoutRequestsQuery,
    openAssignToVetDialog,
    openConfirmSelectionsDialog,
    clearSearch,
    onClearSearch,
    handleBlockableClick,
    tableRows
  } = props;

  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    assignedOnly,
    selectedFacility,
    selectedTab,
    searchValue,
    examCheckedIds,
    passCheckedIds,
    assignCheckedIds,
    savingCheckedIds,
    convertedToDateSelectedDateRange
  } = useVetWorkoutsStore();

  const [query, setQuery] = useState<string>(searchValue);
  const searchQuery = useDebouncedNameValue(query, { timeout: 700 });
  const [
    shouldDisplayWorkoutRequestReportDialog,
    setShouldDisplayWorkoutRequestReportDialog
  ] = useState<boolean>(false);

  const { isRequestsTab, isExamsTab, isRejectedTab } =
    checkCurrentWorkoutsTableTab(selectedTab.value);

  const hasCheckedExamsOnPage = tableRows?.some(row =>
    assignCheckedIds.includes(row.eventId)
  );
  const isAssignButtonDisabled = [
    !hasCheckedExamsOnPage,
    savingCheckedIds.length
  ].some(Boolean);

  useEffect(() => {
    dispatch(setStoreSearchQuery(searchQuery));
  }, [searchQuery]);

  const handleAppSearch = (value: string) => {
    handleBlockableClick(() => setQuery(value));
  };

  const handleFacilityChange = useCallback(
    (facility: racehorse360.IFacility) => {
      dispatch(setStoreSelectedFacility(facility));
    },
    [dispatch]
  );

  const handleDateRangeChange = useCallback(
    (dateRange: DateRange) => {
      const storeDateRange: DateRangeISO = {
        Type: dateRange.Type,
        CustomDateRange: []
      };

      if (dateRange.Type === DateRangeType.Custom) {
        storeDateRange.CustomDateRange = dateRange.CustomDateRange.map(d =>
          d.toISOString()
        );
      }

      dispatch(setStoreSelectedDateRange(storeDateRange));
    },
    [dispatch]
  );

  const handleSaveSelectionsClick = () => {
    handleBlockableClick(() => openConfirmSelectionsDialog(true));
  };

  const handleAssignedToMeChange = useCallback(() => {
    dispatch(setStoreAssignedOnly(!assignedOnly));
  }, [dispatch, assignedOnly]);

  const handleDownloadWorkoutRequestReport = () => {
    setShouldDisplayWorkoutRequestReportDialog(true);
  };

  const handleWorkoutRequestReportDialogClose = () => {
    setShouldDisplayWorkoutRequestReportDialog(false);
  };

  const renderSaveSelectionButton = () => {
    const checkedExamPassIds = new Set([...examCheckedIds, ...passCheckedIds]);
    const hasCheckedWorkoutsOnPage = tableRows?.some(row =>
      checkedExamPassIds.has(row.eventId)
    );
    const isSaveSelectionsDisabled = [
      !hasCheckedWorkoutsOnPage,
      savingCheckedIds.length
    ].some(Boolean);

    if (isRequestsTab) {
      return (
        <div className={classes.saveSelectionsContainer}>
          <Button
            className={classes.saveSelections}
            disabled={isSaveSelectionsDisabled}
            onClick={handleSaveSelectionsClick}
            data-test-id={"saveSelections"}
          >
            Save Selections
          </Button>
        </div>
      );
    }
  };

  const renderDownloadButton = () => {
    return (
      <div className={classes.saveSelectionsContainer}>
        <IconButton
          className={classes.downloadButton}
          size="small"
          onClick={handleDownloadWorkoutRequestReport}
        >
          <DownloadIcon className={classes.downloadIcon} />
        </IconButton>
      </div>
    );
  };

  return (
    <>
      <div className={classes.primaryFilter}>
        <div className={classes.facility}>
          <ErrorBoundary>
            <FacilitySelect
              isActive={true}
              selectedFacility={selectedFacility}
              shouldDisplayTrainingFacilities={true}
              onChange={handleFacilityChange}
            />
          </ErrorBoundary>
        </div>
        {!isRequestsTab && !isRejectedTab && (
          <div className={classes.assignedToMe}>
            <AssignedToMeToggle
              isChecked={assignedOnly}
              onChange={handleAssignedToMeChange}
            />
          </div>
        )}
      </div>

      <div className={classes.secondaryFilter}>
        <div className={classes.searchFilter}>
          <ErrorBoundary>
            <AppSearch
              className={classes.searchFilterInput}
              isForceSearch
              placeholder={"Search Horses and Trainers"}
              onSearch={handleAppSearch}
              value={searchQuery}
              vetWorkoutRequestsFilter={workoutRequestsQuery}
              clearSearch={clearSearch}
              onClearSearch={onClearSearch}
            />
          </ErrorBoundary>
        </div>

        <div className={classes.pickerFilter}>
          <div>
            <ErrorBoundary>
              <DateRangeSelect
                selectedDateRange={convertedToDateSelectedDateRange}
                onChange={handleDateRangeChange}
              />
            </ErrorBoundary>
          </div>
          <div className={classes.rightSideGroup}>
            {renderSaveSelectionButton()}
            {isExamsTab && (
              <div className={classes.saveSelectionsContainer}>
                <AssignToVetButton
                  disabled={isAssignButtonDisabled}
                  onClick={openAssignToVetDialog}
                />
              </div>
            )}
            {renderDownloadButton()}
          </div>
        </div>

        {shouldDisplayWorkoutRequestReportDialog && (
          <WorkoutRequestReportDialog
            onClose={handleWorkoutRequestReportDialogClose}
            open
          />
        )}
      </div>
    </>
  );
};

export default withClickBlocker<IProps>(Header);
