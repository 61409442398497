import React, { useCallback, useMemo, useState } from "react";
import { shallowEqual, useDispatch } from "react-redux";
import { useQueryClient } from "@tanstack/react-query";
import clsx from "clsx";

import { racehorse360 } from "@tsg/1st-grpc-web";

import { useAppSelector } from "hooks/redux/useAppSelector";
import VetExamsTable from "components/VetExamsTable/VetExamsTable";
import DataAbsenceView from "components/VetExamsTable/DataAbsenceView/DataAbsenceView";
import { IExamsTableRowData } from "components/VetExamsTable/types";
import { useRiskFactorsColumnWidth } from "components/VetExamsTable/useRiskFactorsColumnWidth";
import VetExamOverlay from "components/VetExamOverlay/VetExamOverlay";
import { useLoggedInUser } from "components/LoggedInUserProvider";
import { setIsExamActive } from "store/actions/vetExam";
import { setSearchValue } from "store/actions/preRaceScreeningPage";
import { EMPTY_STRING } from "common/constants";
import PreRacesTableHeader from "./PreRacesTableHeader";
import PreRaceRow from "./PreRaceRow";
import { checkCurrentRaceEntriesTableTab, createConfigTable } from "../helper";
import { EPreRacesTableTab, EPreRaceTableColumnSorting } from "../types";
import { INFINITE_LIST_UPCOMING_RACE_ENTRIES_QUERY_KEY } from "../options";

import useStyles from "./styles";

interface IProps {
  tableBodyRef: React.RefObject<HTMLDivElement>;
  isFetchingNextPage: boolean;
}

const PreRacesTable = (props: IProps) => {
  const { tableBodyRef, isFetchingNextPage } = props;

  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { currentUser } = useLoggedInUser();

  const { selectedTab, searchValue, tableRows, isExamActive, orderBy } =
    useAppSelector(
      state => ({
        selectedTab: state.preRaceScreeningPage.selectedTab,
        searchValue: state.preRaceScreeningPage.searchValue,
        tableRows: state.preRaceScreeningPage.tableRows,
        isExamActive: state.vetExam.isExamActive,
        orderBy: state.preRaceScreeningPage.orderBy
      }),
      shallowEqual
    );

  const [activeExamId, setActiveExamId] = useState<string>();

  const { isEntriesTab, isEntryExamsTab, isResultsTab, isRaceDayTab } =
    checkCurrentRaceEntriesTableTab(selectedTab.value);

  const shouldRecalculateRiskFactorsWidth = [
    isEntriesTab,
    isEntryExamsTab,
    isRaceDayTab
  ].some(Boolean);
  const riskFactorsWidth = useRiskFactorsColumnWidth(
    tableRows,
    shouldRecalculateRiskFactorsWidth
  );
  const classes = useStyles({ riskFactorsWidth });

  const columns = useMemo(() => createConfigTable(selectedTab), [selectedTab]);
  const extraColumnsClassName = clsx({
    [classes.rowContentEntries]: isEntriesTab,
    [classes.rowContentEntryExams]: isEntryExamsTab,
    [classes.rowContentPassed]: isResultsTab,
    [classes.rowContentRaceDay]: isRaceDayTab
  });

  const refetchData = useCallback(async () => {
    queryClient.invalidateQueries({
      queryKey: [INFINITE_LIST_UPCOMING_RACE_ENTRIES_QUERY_KEY]
    });
  }, [currentUser.rh360Id]);

  const handleCloseExamForm = useCallback(() => {
    dispatch(setIsExamActive(false));
    setActiveExamId(null);
  }, [dispatch]);

  const handleOpenExamForm = useCallback(
    (examId: string, examStatus: racehorse360.HorseExamStatus) => {
      const isExamAvailable = [
        racehorse360.HorseExamStatus.HORSE_EXAM_STATUS_REQUESTED,
        racehorse360.HorseExamStatus.HORSE_EXAM_STATUS_IN_PROGRESS
      ].includes(examStatus);

      if (isExamAvailable) {
        setActiveExamId(examId);
        dispatch(setIsExamActive(true));
      }
    },
    [dispatch]
  );

  const shouldRenderTrainerAccordions =
    (isEntryExamsTab || isRaceDayTab) &&
    orderBy === EPreRaceTableColumnSorting.TRAINER_LAST_NAME;

  const handleClearSearch = () => {
    dispatch(setSearchValue(EMPTY_STRING));
  };

  const noResultContent = useMemo(() => {
    let tabName: string;

    switch (selectedTab.value) {
      case EPreRacesTableTab.ENTRIES: {
        tabName = `Pre-Race ${selectedTab.name}`;
        break;
      }
      case EPreRacesTableTab.ENTRY_EXAMS: {
        tabName = selectedTab.name;
        break;
      }
      default: {
        tabName = `${selectedTab.name} Exams`;
      }
    }

    return (
      <DataAbsenceView
        isSearchResults={Boolean(searchValue?.length)}
        searchValue={searchValue}
        onClearSearch={handleClearSearch}
        message={`No ${tabName} to Display for Date Range`}
      />
    );
  }, [selectedTab.value, searchValue]);

  const tableHeaderContent = useMemo(
    () => (
      <PreRacesTableHeader
        columnsClassName={extraColumnsClassName}
        refElement={tableBodyRef.current}
        columns={columns}
      />
    ),
    [columns, extraColumnsClassName, tableBodyRef.current]
  );

  const renderRow = (rowData: IExamsTableRowData, index: number) => (
    <PreRaceRow
      index={index}
      rowData={rowData}
      columns={columns}
      columnsClassName={extraColumnsClassName}
      onOpenExamForm={handleOpenExamForm}
    />
  );

  if (isExamActive && activeExamId) {
    return (
      <VetExamOverlay
        examId={activeExamId}
        refetchData={refetchData}
        onClose={handleCloseExamForm}
      />
    );
  }

  return (
    <VetExamsTable
      rows={tableRows}
      isMappedByTrainer={shouldRenderTrainerAccordions}
      isFetchingNextPage={isFetchingNextPage}
      tableBodyRef={tableBodyRef}
      noResultsContent={noResultContent}
      tableHeaderContent={tableHeaderContent}
      renderRow={renderRow}
      refetchData={refetchData}
      shouldUseLockableRow={isEntryExamsTab || isRaceDayTab}
      hasTrainerAccordionViewFixedCells={isEntryExamsTab || isRaceDayTab}
    />
  );
};

export default React.memo(PreRacesTable);
