import { makeStyles } from "@material-ui/core/styles";

export default makeStyles({
  assignCheckboxChecked: {
    backgroundColor: "rgba(3, 243, 178, 1.0)",
    "&:hover": {
      backgroundColor: "rgba(3, 243, 178, 1.0)"
    }
  }
});
