import { makeStyles } from "@material-ui/core/styles";
import { MuiTheme } from "theme";

export default makeStyles((theme: MuiTheme) => {
  const { primary } = theme.palette;

  return {
    rootButton: {
      fontSize: "12px",
      color: primary.main,
      backgroundColor: "rgba(3, 243, 178, 1.0)",
      borderRadius: "2px",
      minWidth: 140,
      padding: "6px 16px",
      "&:hover": {
        backgroundColor: "rgba(3, 243, 178, 1.0)"
      },
      "&.Mui-disabled": {
        backgroundColor: "#d8d8d8",
        color: primary.dark
      }
    }
  };
});
