import { makeStyles, Theme } from "@material-ui/core/styles";

interface StylesProps {
  riskFactorsColumnWidth: number;
}

const getRequestsTabTemplateColumns = ({
  riskFactorsColumnWidth
}: StylesProps): string => {
  const riskFactorsWidth = riskFactorsColumnWidth
    ? `${riskFactorsColumnWidth}px`
    : "120px";
  // Horse+Trainer, History, Work Date, Requested, Risk Level, Barn, Age+Sex, HSF, DLW, DLR, LastExam, Flag Request, Vet Lists, Risk Factors
  return `180px 80px 100px 100px 100px 100px 100px 80px 80px 80px 100px 150px 100px ${riskFactorsWidth} auto`;
};

const getExamTabTemplateColumns = ({
  riskFactorsColumnWidth
}: StylesProps): string => {
  const riskFactorsWidth = riskFactorsColumnWidth
    ? `${riskFactorsColumnWidth}px`
    : "120px";
  // Horse+Trainer, History, Work Date, Requested, Risk Level, Flag Request, Barn, Exam Status, Age+Sex, HSF, DLW, DLR, LastExam, Assigned Vet, Un-Assign, Exam, Vet Lists, Risk Factors
  return `180px 80px 100px 100px 100px 150px 100px 120px 100px 80px 80px 80px 100px 180px 80px 140px 120px 120px ${riskFactorsWidth}`;
};

export default makeStyles<Theme, StylesProps | null>({
  rowContentRequests: {
    gridTemplateColumns: getRequestsTabTemplateColumns
  },
  rowContentExams: {
    gridTemplateColumns: getExamTabTemplateColumns
  },
  rowContentPassed: {
    // Horse+Trainer, Work Date, Requested, Risk, Barn, ExamType, AssignedVet, Age+Sex, HSF, DLW, DLR, LastExam
    gridTemplateColumns:
      "180px 100px 100px 100px 100px 140px 180px 100px 80px 80px 80px 100px auto"
  },
  rowContentFailed: {
    // Horse+Trainer, Work Date, Requested, Risk, Barn, AssignedVet, Age+Sex, HSF, DLW, DLR, LastExam
    gridTemplateColumns:
      "180px 100px 100px 100px 100px 180px 100px 80px 80px 80px 100px auto"
  },
  rowContentRejected: {
    // Horse+Trainer, Work Date, Requested, Risk, Barn, Age+Sex, HSF, DLW, DLR, LastExam, Imaging/Diagnostics
    gridTemplateColumns:
      "180px 100px 100px 100px 100px 100px 80px 80px 80px 100px 176px auto"
  }
});
