import React, { HTMLAttributes, useEffect, useState } from "react";
import { UseMutateAsyncFunction } from "@tanstack/react-query";
import clsx from "clsx";

import { racehorse360 } from "@tsg/1st-grpc-web";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Theme } from "@material-ui/core/styles";

import Loader from "components/Loader";
import { EMPTY_STRING, NOT_ACCESSIBLE } from "common/constants";
import { FirstApiError } from "hooks/api/errors";
import { useRacehorse360Api } from "hooks/api";
import ConfirmAndCancelButtons from "./CofirmAndCancelButtons";
import EditButton from "./EditButton";
import { useLoggedInUser } from "../LoggedInUserProvider";

import useStyles from "./styles";

interface IProps extends HTMLAttributes<HTMLDivElement> {
  horse: racehorse360.IHorse;
  options: Pick<racehorse360.IBarn, "id" | "name">[];
  selectedFacilityId: string;
  startActions?: boolean;
  endActions?: boolean;
  missingValueSign?: string;
  iconVariant?: "default" | "map";
  onBarnChange?: (barn: racehorse360.IBarn) => void;
}

const MOBILE_LOADER_SIZE = 16;
const DESKTOP_LOADER_SIZE = 20;

const EMPTY_OPTION: Pick<racehorse360.IBarn, "id" | "name"> = {
  id: "emptyOptionId",
  name: ""
};

const BarnNumberPicker = (props: IProps) => {
  const {
    horse,
    selectedFacilityId,
    missingValueSign = NOT_ACCESSIBLE,
    startActions = false,
    endActions = false,
    options = [],
    iconVariant = "default",
    className,
    onBarnChange
  } = props;

  const classes = useStyles();
  const { currentUser } = useLoggedInUser();
  const matchesDownXS420 = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xs")
  );

  const loaderSize = matchesDownXS420
    ? MOBILE_LOADER_SIZE
    : DESKTOP_LOADER_SIZE;

  const [isEditMode, setIsEditMode] = useState(false);
  const [barnId, setBarnId] = useState(horse.barn?.id || EMPTY_OPTION.id);
  const [selectValue, setSelectValue] = useState(EMPTY_STRING);

  const { useAssignHorseToBarn, useUnassignHorseFromBarn } =
    useRacehorse360Api();

  const { mutateAsync: assignHorseToBarn, isPending: isHorseAssigning } =
    useAssignHorseToBarn();
  const { mutateAsync: unAssignHorseFromBarn, isPending: isHorseUnAssigning } =
    useUnassignHorseFromBarn();

  const isHorseAtSelectedFacility =
    selectedFacilityId === horse.currentFacility?.id;
  let cellContent: string;

  if (isHorseAtSelectedFacility) {
    cellContent =
      options.find(opt => opt.id === barnId)?.name || missingValueSign;
  } else {
    cellContent = horse.currentFacility?.code || missingValueSign;
  }

  const shouldShowEditIcon = [
    !currentUser.isRegulatoryVet,
    isHorseAtSelectedFacility,
    options.length
  ].every(Boolean);
  const shouldShowActionsAtStart = [shouldShowEditIcon, startActions].every(
    Boolean
  );
  const shouldShowActionsAtEnd = [shouldShowEditIcon, endActions].every(
    Boolean
  );
  const isLoading = [isHorseAssigning, isHorseUnAssigning].some(Boolean);

  const handleOpenEditMode = () => {
    setSelectValue(barnId);
    setIsEditMode(true);
  };

  const handleConfirmValue = () => {
    if (selectValue === barnId) {
      setIsEditMode(false);

      return;
    }

    const query = {
      horseId: horse?.id,
      barnId: null,
      getOptions: {
        select: [
          "barn.id",
          "barn.name",
          "barn.facility.name",
          "barn.facility.code"
        ]
      }
    };

    let changeBarnRequest: UseMutateAsyncFunction<
      Partial<Omit<racehorse360.IHorse, "toJSON">>,
      FirstApiError,
      | racehorse360.IUnassignHorseFromBarnRequest
      | racehorse360.IAssignHorseToBarnRequest,
      unknown
    >;

    if (selectValue === EMPTY_OPTION.id) {
      changeBarnRequest = unAssignHorseFromBarn;
    } else {
      changeBarnRequest = assignHorseToBarn;
      query.barnId = selectValue;
    }

    changeBarnRequest(query).then(horse => {
      setBarnId(horse.barn?.id || EMPTY_OPTION.id);
      setIsEditMode(false);
      onBarnChange && onBarnChange(horse.barn);
    });
  };

  const handleCloseEditMode = () => {
    setIsEditMode(false);
  };

  const handleChange = event => {
    setSelectValue(event.target.value);
  };

  useEffect(() => {
    setBarnId(horse.barn?.id || EMPTY_OPTION.id);
  }, [horse]);

  const renderContent = () => {
    if (!isEditMode) {
      return (
        <>
          <div>
            {shouldShowActionsAtStart && (
              <EditButton variant={iconVariant} onClick={handleOpenEditMode} />
            )}
          </div>
          <span>{cellContent}</span>
          <div>
            {shouldShowActionsAtEnd && (
              <EditButton variant={iconVariant} onClick={handleOpenEditMode} />
            )}
          </div>
        </>
      );
    }

    return (
      <>
        {shouldShowActionsAtStart && (
          <ConfirmAndCancelButtons
            onConfirm={handleConfirmValue}
            onCancel={handleCloseEditMode}
          />
        )}
        {isLoading && <Loader className={classes.loader} size={loaderSize} />}
        {!isLoading && (
          <Select
            className={classes.selectWrapper}
            variant={"outlined"}
            classes={{
              root: classes.root,
              select: classes.select,
              iconOutlined: classes.iconOutlined
            }}
            MenuProps={{
              classes: {
                paper: classes.paper,
                list: classes.selectList
              }
            }}
            value={selectValue}
            onChange={handleChange}
          >
            {[EMPTY_OPTION, ...options].map(item => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        )}
        {shouldShowActionsAtEnd && (
          <ConfirmAndCancelButtons
            onConfirm={handleConfirmValue}
            onCancel={handleCloseEditMode}
          />
        )}
      </>
    );
  };

  return (
    <div
      className={clsx(className, classes.container, {
        editMode: isEditMode,
        fadeOut: !horse.currentFacility
      })}
    >
      {renderContent()}
    </div>
  );
};

export default React.memo(BarnNumberPicker);
