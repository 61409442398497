import { makeStyles } from "@material-ui/core/styles";
import { MuiTheme } from "theme";
import Breakpoints from "common/breakpoints";

export default makeStyles((theme: MuiTheme) => {
  const { secondary } = theme.palette;

  return {
    pickerFilter: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      height: 44,
      [theme.breakpoints.down(Breakpoints.SM_600)]: {
        justifyContent: "space-between",
        padding: "0 16px"
      },
      [theme.breakpoints.down(Breakpoints.XS_420)]: {
        padding: "0 10px"
      }
    },
    rightSide: {
      display: "flex",
      alignItems: "center"
    },
    assignButton: {
      margin: "0 8px"
    },
    downloadButton: {
      display: "inline-flex",
      padding: 8
    },
    downloadIcon: {
      color: secondary.dark,
      fontSize: "26px"
    }
  };
});
