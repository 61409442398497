import React, { ChangeEvent, useEffect, useState } from "react";
import { shallowEqual, useDispatch } from "react-redux";
import { UseMutateAsyncFunction, useIsMutating } from "@tanstack/react-query";
import clsx from "clsx";

import { racehorse360 } from "@tsg/1st-grpc-web";

import { useSnackbar } from "components/SnackbarContext/SnackbarContext";
import {
  IExamsTableRowData,
  ITableColumnsContent
} from "components/VetExamsTable/types";
import ExamStatusCell from "components/VetExamsTable/TableRow/RowContent/ExamStatusCell/ExamStatusCell";
import Row from "components/VetExamsTable/TableRow/Row";
import RowContent from "components/VetExamsTable/TableRow/RowContent/RowContent";
import ExamNote from "components/VetExamsTable/ExamNote";
import TrainerCell from "components/VetExamsTable/TableRow/RowContent/TrainerCell/TrainerCell";
import DateCell from "components/VetExamsTable/TableRow/RowContent/DateCell";
import ExamCell from "components/VetExamsTable/TableRow/RowContent/ExamCell/ExamCell";
import TableCellWrapper from "components/VetExamsTable/TableCellWrapper";
import FlagPriority from "components/VetExamsTable/TableRow/RowContent/FlagCell/FlagPriority";
import { getUpcomingRaceEntryFlagPriority } from "components/VetExamsTable/TableRow/RowContent/FlagCell/helper";
import { useExamLockerContext } from "components/ExamLockerContext/ExamLockerContext";
import { EFlagPriority } from "components/VetExamsTable/helper";
import ActionCellsWrapper from "components/VetExamsTable/TableRow/RowContent/ActionCellsWrapper";
import AssignActionCell from "components/VetExamsTable/TableRow/RowContent/ActionCellsWrapper/AssignActionCell";
import UnAssignExamCell from "components/VetExamsTable/TableRow/RowContent/UnAssignExamCell";
import {
  addAssignCheckedId,
  filterTableRow,
  removeAssignCheckedId
} from "store/actions/preRaceScreeningPage";
import { useAppSelector } from "hooks/redux/useAppSelector";
import { getDateInTimeZone } from "utils/date-utils";
import { useRacehorse360Api } from "hooks/api";
import { FirstApiError } from "hooks/api/errors";
import EntriesTabActionCells from "./EntriesTabActionCells";
import {
  checkCurrentRaceEntriesTableTab,
  getPreRaceExamTableStatus
} from "../../helper";
import {
  BULK_ASSIGN_UPCOMING_RACE_ENTRY_EXAMS_KEY,
  BULK_ASSIGN_UPCOMING_RACE_ENTRY_RACE_DAY_EXAMS_KEY
} from "../../PreRaceScreeningPageHeader/PickerFilter/PickerFilter";
import { EPreRaceTableColumn } from "../../types";

import useStyles from "./styles";

interface IProps {
  index: number;
  columnsClassName: string;
  rowData: IExamsTableRowData;
  columns: string[];
  onOpenExamForm: (
    examId: string,
    status: racehorse360.HorseExamStatus
  ) => void;
}

const PreRaceRow = (props: IProps) => {
  const { index, rowData, columnsClassName, columns, onOpenExamForm } = props;

  const classes = useStyles();
  const dispatch = useDispatch();
  const { pullLocker } = useExamLockerContext();
  const isBulkAssignEntryExamsMutating = useIsMutating({
    mutationKey: [BULK_ASSIGN_UPCOMING_RACE_ENTRY_EXAMS_KEY]
  });
  const isBulkAssignEntryRaceDayExamsMutating = useIsMutating({
    mutationKey: [BULK_ASSIGN_UPCOMING_RACE_ENTRY_RACE_DAY_EXAMS_KEY]
  });

  const [currentRowData, setCurrentRowData] =
    useState<IExamsTableRowData>(rowData);
  const [isExamChecked, setIsExamChecked] = useState(false);
  const [isPassChecked, setIsPassChecked] = useState(false);

  const { selectedTab, facilityBarns, assignCheckedIds, selectedFacility } =
    useAppSelector(state => state.preRaceScreeningPage, shallowEqual);
  const { showErrorSnack } = useSnackbar();

  const {
    useSetUpcomingRaceEntryFlag,
    useResetUpcomingRaceEntryFlag,
    useSetUpcomingRaceEntryExamNote,
    useResetUpcomingRaceEntryExamNote,
    useRevertQuickPassUpcomingRaceEntryExam,
    useUnassignUpcomingRaceEntryExam,
    useUnassignUpcomingRaceEntryRaceDayExam
  } = useRacehorse360Api();

  const { mutateAsync: setRaceEntryFlag } = useSetUpcomingRaceEntryFlag();
  const { mutateAsync: resetRaceEntryFlag } = useResetUpcomingRaceEntryFlag();
  const {
    mutateAsync: revertQuickPassRaceEntryExam,
    isPending: isQuickPassRaceEntryExamReverting
  } = useRevertQuickPassUpcomingRaceEntryExam();
  const { mutateAsync: setEntryExamNote, isPending: isEntryExamNoteSetting } =
    useSetUpcomingRaceEntryExamNote();
  const {
    mutateAsync: resetEntryExamNote,
    isPending: isEntryExamNoteResetting
  } = useResetUpcomingRaceEntryExamNote();
  const { mutateAsync: unAssignEntryExam, isPending: isEntryExamUnAssigning } =
    useUnassignUpcomingRaceEntryExam();
  const {
    mutateAsync: unAssignRaceDayExam,
    isPending: isRaceDayExamUnAssigning
  } = useUnassignUpcomingRaceEntryRaceDayExam();

  const {
    eventId: preRaceId,
    eventDate: preRaceDate,
    eventNumber: preRaceNumber,
    eventPosition: preRacePosition,
    eventFlagPriority: preRaceFlagPriority,
    eventFacility,
    examId,
    examType,
    examStatus,
    examResult,
    examAssignedUser,
    horse
  } = currentRowData;

  const isAssignChecked = assignCheckedIds.includes(preRaceId);

  const { isEntriesTab, isEntryExamsTab, isResultsTab, isRaceDayTab } =
    checkCurrentRaceEntriesTableTab(selectedTab.value);

  const shouldShowFlagPriorityIndicator = [
    preRaceFlagPriority,
    !isResultsTab
  ].every(Boolean);
  const shouldShowFlagOnTrack = [
    isEntriesTab,
    horse.hasActualFlagOnTrack
  ].every(Boolean);
  const shouldShowLastExamNote = [
    horse.lastCompletedExamWithNote,
    isEntriesTab
  ].every(Boolean);
  const shouldShowRevertButton = [
    isResultsTab,
    rowData.examType === racehorse360.HorseExamType.HORSE_EXAM_TYPE_QUICK_PASS,
    getDateInTimeZone(new Date(), eventFacility?.timezone) <
      new Date(rowData.eventDate)
  ].every(Boolean);
  const isEntryExamLoading = [
    isEntryExamNoteSetting,
    isEntryExamNoteResetting
  ].some(Boolean);
  const hasTabAssignActionCell = [isEntryExamsTab, isRaceDayTab].some(Boolean);
  const isExamAssigning = [
    isAssignChecked,
    isBulkAssignEntryExamsMutating || isBulkAssignEntryRaceDayExamsMutating
  ].every(Boolean);

  const handleUnAssignDialogConfirm = () => {
    let actualRequest: UseMutateAsyncFunction<
      Partial<Omit<racehorse360.UpcomingRaceEntry, "toJSON">>,
      FirstApiError,
      | racehorse360.IUnassignUpcomingRaceEntryExamRequest
      | racehorse360.IUnassignUpcomingRaceEntryRaceDayExamRequest,
      unknown
    >;

    if (isEntryExamsTab) {
      actualRequest = unAssignEntryExam;
    } else if (isRaceDayTab) {
      actualRequest = unAssignRaceDayExam;
    }

    actualRequest?.({
      id: preRaceId
    })
      .then(() =>
        setCurrentRowData({
          ...currentRowData,
          examAssignedUser: null
        })
      )
      .catch(error => {
        showErrorSnack(error.message);
      });
  };

  const handleExamButtonClick = async () => {
    const response = await pullLocker();

    if (response.data.isLocked) return;

    onOpenExamForm(examId, examStatus);
  };

  const handleUndoQuickPassConfirm = async () => {
    const revertedRaceEntry = await revertQuickPassRaceEntryExam({
      id: preRaceId
    });

    revertedRaceEntry && dispatch(filterTableRow(revertedRaceEntry.id));
  };

  const handleSetExamNote = async (note: string) => {
    const updatedRaceEntry = await setEntryExamNote({
      id: preRaceId,
      body: note,
      getOptions: {
        select: [
          "entryExamNote.body",
          "entryExamNote.notedBy",
          "entryExamNote.notedOn"
        ]
      }
    });

    setCurrentRowData({
      ...currentRowData,
      examNote: updatedRaceEntry.entryExamNote
    });
  };

  const handleResetExamNote = async () => {
    await resetEntryExamNote({ id: preRaceId });

    setCurrentRowData({
      ...currentRowData,
      examNote: null
    });
  };

  const handleChangePriority = async (event: ChangeEvent<HTMLInputElement>) => {
    const flagPriority = getUpcomingRaceEntryFlagPriority(
      event.currentTarget.value as EFlagPriority
    );
    let updatedRaceEntry;

    if (
      flagPriority ===
      racehorse360.UpcomingRaceEntryFlagPriority
        .UPCOMING_RACE_ENTRY_FLAG_PRIORITY_INVALID
    ) {
      updatedRaceEntry = await resetRaceEntryFlag({ id: preRaceId });
    } else {
      updatedRaceEntry = await setRaceEntryFlag({
        id: preRaceId,
        flagPriority,
        getOptions: {
          select: ["flagPriority"]
        }
      });
    }

    setCurrentRowData({
      ...currentRowData,
      eventFlagPriority: updatedRaceEntry.flagPriority
    });
  };

  const handleAssignChange = (isChecked: boolean) => {
    if (isChecked) {
      dispatch(addAssignCheckedId(preRaceId));
    } else {
      dispatch(removeAssignCheckedId(preRaceId));
    }
  };

  const examNoteContent = !isResultsTab && (
    <ExamNote
      index={index}
      rowData={currentRowData}
      isLoading={isEntryExamLoading}
      readOnly={isEntryExamsTab || isRaceDayTab}
      onSaveComment={handleSetExamNote}
      onDeleteComment={handleResetExamNote}
      noteLabel={"Entry Exam Note"}
    />
  );

  const actionCells = !isResultsTab && (
    <ActionCellsWrapper
      className={clsx({
        [classes.rowOdd]: index % 2,
        [classes.rowExamChecked]: isExamChecked,
        [classes.rowPassChecked]: isPassChecked,
        [classes.rowAssignChecked]: isAssignChecked
      })}
    >
      {isEntriesTab && (
        <EntriesTabActionCells
          entryId={preRaceId}
          isExamChecked={isExamChecked}
          isPassChecked={isPassChecked}
          onSetIsExamChecked={setIsExamChecked}
          onSetIsPassChecked={setIsPassChecked}
        />
      )}
      {hasTabAssignActionCell && (
        <AssignActionCell
          isAssignChecked={isAssignChecked}
          onAssignChange={handleAssignChange}
          assignedUserId={examAssignedUser?.id}
          examStatus={examStatus}
          isLoading={isExamAssigning}
        />
      )}
    </ActionCellsWrapper>
  );

  const columnsContent: ITableColumnsContent = {
    [EPreRaceTableColumn.TRAINER]: (
      <TrainerCell
        key={`${preRaceId}-trainer`}
        bold={isEntriesTab}
        trainerInfo={horse.trainer}
      />
    ),
    [EPreRaceTableColumn.RACE_DATE]: (
      <DateCell
        key={`${preRaceId}-race-date`}
        date={preRaceDate}
        bold={isEntriesTab}
      />
    ),
    [EPreRaceTableColumn.RACE_NUMBER]: (
      <TableCellWrapper
        key={`${preRaceId}-race-number`}
        bold={isEntriesTab}
        centered
      >
        {preRaceNumber}
      </TableCellWrapper>
    ),
    [EPreRaceTableColumn.RACE_POSITION]: (
      <TableCellWrapper
        key={`${preRaceId}-race-position`}
        bold={isEntriesTab}
        centered
      >
        {preRacePosition}
      </TableCellWrapper>
    ),
    [EPreRaceTableColumn.UN_ASSIGN]: (
      <UnAssignExamCell
        key={`${preRaceId}-un-assign`}
        rowData={currentRowData}
        onConfirm={handleUnAssignDialogConfirm}
        isUnAssignSaving={isEntryExamUnAssigning || isRaceDayExamUnAssigning}
      />
    ),
    [EPreRaceTableColumn.EXAM]: (
      <ExamCell
        key={`${preRaceId}-exam`}
        assignedUserId={examAssignedUser?.id}
        onExamButtonClick={handleExamButtonClick}
      />
    ),
    [EPreRaceTableColumn.ENTRY_REVIEW]: (
      <ExamStatusCell
        key={`${preRaceId}-exam-type`}
        status={getPreRaceExamTableStatus(examType, examResult)}
        horseName={horse.name}
        eventName={"Race"}
        eventTabName={"Entries"}
        eventDate={preRaceDate}
        shouldShowRevertButton={shouldShowRevertButton}
        onConfirm={handleUndoQuickPassConfirm}
        isLoading={isQuickPassRaceEntryExamReverting}
      />
    ),
    [EPreRaceTableColumn.WATCH_LIST]: (
      <TableCellWrapper key={`${preRaceId}-watch-list`}>
        <FlagPriority
          priority={preRaceFlagPriority}
          onChangePriority={handleChangePriority}
          defaultButtonTitle={"Watch"}
          selectedButtonTitle={"Watching"}
        />
      </TableCellWrapper>
    )
  };

  useEffect(() => {
    setCurrentRowData(rowData);
  }, [rowData]);

  return (
    <>
      <Row
        index={index}
        rowContainerClassName={clsx({
          [classes.rowExamChecked]: isExamChecked,
          [classes.rowPassChecked]: isPassChecked,
          [classes.rowAssignChecked]: isAssignChecked
        })}
        rowContentClassName={columnsClassName}
        rowData={currentRowData}
        examNoteContent={examNoteContent}
        actionCellsContent={actionCells}
        shouldShowFlagPriorityIndicator={shouldShowFlagPriorityIndicator}
        shouldShowFlagOnTrack={shouldShowFlagOnTrack}
        shouldShowLastExamNote={shouldShowLastExamNote}
        shouldShowExamsTablePPs={isEntriesTab}
      >
        <RowContent
          rowData={currentRowData}
          columns={columns}
          bold={isEntriesTab}
          customColumnsContent={columnsContent}
          extenderFitsTwoColumns={isEntriesTab}
          extenderFitsOneColumns={hasTabAssignActionCell}
          facilityBarns={facilityBarns}
          selectedFacilityId={selectedFacility.id}
        />
      </Row>
    </>
  );
};

export default React.memo(PreRaceRow);
