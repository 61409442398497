import { makeStyles } from "@material-ui/core/styles";
import { MuiTheme } from "theme";

export default makeStyles((theme: MuiTheme) => ({
  listRoot: {
    display: "flex",
    flex: "auto"
  },
  listContainer: {
    display: "flex",
    flex: "auto",
    flexDirection: "column",
    overflow: "hidden"
  },
  listHeader: {
    boxShadow: "0px 1px 7px 0 rgba(0,0,0,0.1)",
    display: "flex"
  },
  listRow: {
    display: "flex",
    alignItems: "center",
    height: 47
  },
  evenRow: {
    backgroundColor: "#fbfbfb"
  },
  headerCell: {
    fontSize: 14,
    fontFamily: "SuisseIntlMedium, SuisseIntlRegular"
  },
  bodyCell: {
    fontSize: 12
  },
  horseNameCell: {
    paddingLeft: 16,
    flexGrow: 1,
    width: "auto",
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.up("md")]: {
      width: 180,
      maxWidth: 180
    }
  },
  barnCell: {
    width: 100,
    maxWidth: 100,
    padding: 8,
    fontSize: 14,
    textAlign: "center",
    display: "flex",
    justifyContent: "center"
  },
  valueCell: {
    width: 46,
    maxWidth: 46,
    padding: 8,
    fontSize: 14,
    textAlign: "center"
  },
  scopeCell: {
    minWidth: 40,
    maxWidth: 40,
    paddingLeft: 0,
    paddingRight: 16,
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      minWidth: 72,
      maxWidth: 72
    }
  },
  scopeSignIcon: {
    width: 16,
    height: 16,
    "& path": {
      fill: theme.palette.primary.light
    }
  },
  scopeActive: {
    "& path": {
      fill: theme.palette.secondary.dark
    }
  },
  collapseButton: {
    textTransform: "none",
    fontFamily: "SuisseIntlMedium, SuisseIntlRegular",
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
  }
}));
