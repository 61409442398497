import React, { useState } from "react";
import { shallowEqual, useDispatch } from "react-redux";
import { UseMutateAsyncFunction } from "@tanstack/react-query";
import clsx from "clsx";

import { racehorse360 } from "@tsg/1st-grpc-web";

import IconButton from "@material-ui/core/IconButton";

import {
  clearAssignCheckedIds,
  setTableRows
} from "store/actions/preRaceScreeningPage";
import { useAppSelector } from "hooks/redux/useAppSelector";
import { useRacehorse360Api } from "hooks/api";
import { FirstApiError } from "hooks/api/errors";
import ErrorBoundary from "components/ErrorBoundary";
import DateRangeSelect from "components/DateRangeSelect";
import DownloadIcon from "components/Icons/Download";
import { checkCurrentRaceEntriesTableTab } from "../../helper";
import AssignToVetButton from "components/VetExamsAssignment/AssignToVetButton/AssignToVetButton";
import ConfirmAssignmentDialog from "components/VetExamsAssignment/ConfirmAssignmentDialog";
import {
  examsRowDataMapper,
  filterVisibleCheckedWorkoutIds,
  updateTableRowsAccordingToNewRows
} from "components/VetExamsTable/helper";
import { useSnackbar } from "components/SnackbarContext/SnackbarContext";
import { commonRequestedFields } from "../../options";

import useStyles from "./styles";

interface IProps {
  className?: string;
}

export const BULK_ASSIGN_UPCOMING_RACE_ENTRY_EXAMS_KEY =
  "BULK_ASSIGN_UPCOMING_RACE_ENTRY_EXAMS_KEY";
export const BULK_ASSIGN_UPCOMING_RACE_ENTRY_RACE_DAY_EXAMS_KEY =
  "BULK_ASSIGN_UPCOMING_RACE_ENTRY_RACE_DAY_EXAMS_KEY";

const PickerFilter = (props: IProps) => {
  const { className } = props;

  const classes = useStyles();
  const dispatch = useDispatch();
  const { showSuccessSnack, showErrorSnack } = useSnackbar();

  const { selectedTab, assignCheckedIds, tableRows, selectedFacility } =
    useAppSelector(state => state.preRaceScreeningPage, shallowEqual);

  const [isAssignToVetDialogOpen, setIsAssignToVetDialogOpen] = useState(false);

  const {
    useBulkAssignUpcomingRaceEntryExams,
    useBulkRequireAndAssignUpcomingRaceEntryRaceDayExams
  } = useRacehorse360Api();

  const { mutateAsync: assignUpcomingRaceEntryExams } =
    useBulkAssignUpcomingRaceEntryExams({
      mutationKey: [BULK_ASSIGN_UPCOMING_RACE_ENTRY_EXAMS_KEY]
    });

  const { mutateAsync: assignUpcomingRaceEntryRaceDayExams } =
    useBulkRequireAndAssignUpcomingRaceEntryRaceDayExams({
      mutationKey: [BULK_ASSIGN_UPCOMING_RACE_ENTRY_RACE_DAY_EXAMS_KEY]
    });

  const { isRaceDayTab, isEntryExamsTab } = checkCurrentRaceEntriesTableTab(
    selectedTab.value
  );

  const shouldShowAssignButton = [isEntryExamsTab, isRaceDayTab].some(Boolean);
  const hasCheckedAssignCheckboxesOnPage = tableRows?.some(row =>
    assignCheckedIds.includes(row.eventId)
  );
  const visibleAssignCheckedCheckBoxes = filterVisibleCheckedWorkoutIds(
    tableRows,
    assignCheckedIds
  );

  const handleOpenAssignmentDialog = () => {
    setIsAssignToVetDialogOpen(true);
  };

  const handleCloseAssignmentDialog = () => {
    setIsAssignToVetDialogOpen(false);
  };

  const handleConfirmAssignmentDialog = (assignedVetId: string) => {
    setIsAssignToVetDialogOpen(false);

    let actualRequest: UseMutateAsyncFunction<
      Partial<Omit<racehorse360.ListUpcomingRaceEntriesResponse, "toJSON">>,
      FirstApiError,
      | racehorse360.IBulkAssignUpcomingRaceEntryExamsRequest
      | racehorse360.IBulkRequireAndAssignUpcomingRaceEntryRaceDayExamsRequest,
      unknown
    >;

    if (isEntryExamsTab) {
      actualRequest = assignUpcomingRaceEntryExams;
    } else if (isRaceDayTab) {
      actualRequest = assignUpcomingRaceEntryRaceDayExams;
    }

    actualRequest &&
      actualRequest({
        ids: assignCheckedIds,
        assignedVetId,
        getOptions: {
          select: [
            ...commonRequestedFields,
            ...selectedTab.additionalRequestedFields
          ]
        }
      })
        .then(data => {
          const newTableRows =
            data.upcomingRaceEntries?.map(item =>
              examsRowDataMapper(item, selectedTab.value)
            ) || [];
          const updatedRows = updateTableRowsAccordingToNewRows(
            tableRows,
            newTableRows
          );

          dispatch(setTableRows(updatedRows));
          dispatch(clearAssignCheckedIds());

          showSuccessSnack("Vet Assigned");
        })
        .catch(error => {
          showErrorSnack(error.message);
        });
  };

  return (
    <div className={clsx(className, classes.pickerFilter)}>
      <div>
        {!isRaceDayTab && (
          <ErrorBoundary>
            <DateRangeSelect />
          </ErrorBoundary>
        )}
      </div>

      <div className={classes.rightSide}>
        {shouldShowAssignButton && (
          <AssignToVetButton
            className={classes.assignButton}
            disabled={!hasCheckedAssignCheckboxesOnPage}
            onClick={handleOpenAssignmentDialog}
          />
        )}

        <IconButton className={classes.downloadButton} size="small">
          <DownloadIcon className={classes.downloadIcon} />
        </IconButton>

        <ConfirmAssignmentDialog
          open={isAssignToVetDialogOpen}
          onClose={handleCloseAssignmentDialog}
          onConfirm={handleConfirmAssignmentDialog}
          selectedItemsAmount={visibleAssignCheckedCheckBoxes.length}
          selectedFacilityId={selectedFacility?.id}
        />
      </div>
    </div>
  );
};

export default React.memo(PickerFilter);
