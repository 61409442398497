import { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { IRoute, matchRoute } from "utils/routes";

export const useDefaultTab = (
  possibleRoutes: IRoute[],
  defaultRouteIndex = 0,
  selectedTabPath?: string
): void => {
  const history = useHistory();

  useEffect(() => {
    const isExistingRoute = matchRoute(
      history.location.pathname,
      possibleRoutes
    );

    if (!isExistingRoute) {
      history.replace(
        selectedTabPath || possibleRoutes[defaultRouteIndex].path
      );
    }
  }, [history.location.pathname, defaultRouteIndex]);
};
