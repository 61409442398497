import React from "react";

export interface Coordinates {
  x: number;
  y: number;
}

export interface Size {
  width: number;
  height: number;
}

export const getScreenPositionByBarn = (
  barnSelector: string,
  mapRootRef: React.RefObject<SVGGElement>,
  svgContainerRef: React.RefObject<HTMLDivElement>,
  containerSize: Size
): Coordinates => {
  const activeBarnEl: SVGGElement =
    mapRootRef.current?.querySelector(barnSelector);
  const svgContainerRect = svgContainerRef.current?.getBoundingClientRect();

  if (activeBarnEl) {
    const activeBarnRect = activeBarnEl?.getBoundingClientRect();
    const barnRelativeX = activeBarnRect.left - svgContainerRect.left;
    const barnRelativeY = activeBarnRect.top - svgContainerRect.top;
    return {
      x: Math.ceil(
        -barnRelativeX + containerSize.width / 2 - activeBarnRect.width / 2
      ),
      y: Math.ceil(
        -barnRelativeY + containerSize.height / 2 - activeBarnRect.height / 2
      )
    };
  }

  return null;
};
