import React, { HTMLAttributes } from "react";

import Button from "@material-ui/core/Button";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";

import TableCellWrapper from "components/VetExamsTable/TableCellWrapper";
import { useLoggedInUser } from "components/LoggedInUserProvider";
import { useExamLockerContext } from "components/ExamLockerContext/ExamLockerContext";

import useStyles from "./styles";

interface IProps extends HTMLAttributes<HTMLDivElement> {
  onExamButtonClick: () => void;
  assignedUserId: string;
}

const ExamCell = (props: IProps) => {
  const { onExamButtonClick, assignedUserId, ...restProps } = props;

  const classes = useStyles();
  const { isLocked } = useExamLockerContext();
  const {
    currentUser: { rh360Id, isSeniorVet, isTrackVet, isChiefVet }
  } = useLoggedInUser();
  const isChiefOrSeniorVet = [isSeniorVet, isChiefVet].some(Boolean);
  const isAssignedToCurrentTrackVet = [
    isTrackVet,
    rh360Id === assignedUserId
  ].every(Boolean);

  const isExamButtonDisabled =
    !(isAssignedToCurrentTrackVet || isChiefOrSeniorVet) || isLocked;

  return (
    <TableCellWrapper {...restProps} centered>
      {Boolean(assignedUserId) && (
        <Button
          className={classes.exam}
          size={"small"}
          disabled={isExamButtonDisabled}
          onClick={onExamButtonClick}
          startIcon={<AddCircleOutlineIcon className={classes.examIcon} />}
        >
          EXAM
        </Button>
      )}
    </TableCellWrapper>
  );
};

export default React.memo(ExamCell);
