import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useQueryClient } from "@tanstack/react-query";

import { racehorse360 } from "@tsg/1st-grpc-web";

import routes from "common/routes";
import AppPage from "components/AppPage";
import Loader from "components/Loader";
import AppPageContent from "components/AppPageContent";
import AppPageHeader from "components/AppPageHeader";
import ConfirmAssignmentDialog from "components/VetExamsAssignment/ConfirmAssignmentDialog";
import ErrorBoundary from "components/ErrorBoundary";
import { useLoggedInUser } from "components/LoggedInUserProvider";
import { useSnackbar } from "components/SnackbarContext/SnackbarContext";
import TabsComponent from "components/Tabs";
import { getDateRangeQuery } from "components/DateRangeSelect/helpers";
import {
  examsRowDataMapper,
  filterVisibleCheckedWorkoutIds,
  updateTableRowsAccordingToNewRows
} from "components/VetExamsTable/helper";
import { IExamsTableRowData } from "components/VetExamsTable/types";
import { useFacilityBarns } from "components/BarnNumberPicker/useFacilityBarns";
import { useUpdateInfiniteTableData } from "components/VetExamsTable/useUpdateTableData";
import { DateRangeISO, DateRangeType } from "interfaces/DateRange";
import { tabs, VetWorkoutsTab } from "interfaces/VetWorkoutsTab";
import { useInfiniteScroll } from "hooks/useInfiniteScroll";
import { useRacehorse360Api } from "hooks/api";
import {
  resetCheckedIds,
  setAssignCheckedIds,
  setExamCheckedIds,
  setFacilityBarns,
  setOrderBy,
  setPassCheckedIds,
  setSavingCheckedIds,
  setSearchQuery,
  setSelectedDateRange as setStoreSelectedDateRange,
  setSelectedTab as setStoreSelectedTab
} from "store/actions/vetWorkoutsPage";
import { useDefaultTab } from "hooks/useDefaultTab";
import {
  columnSettings,
  commonWorkoutRequestSelectedFields,
  EColumnName
} from "./WorkoutsTable/options";
import {
  checkCurrentWorkoutsTableTab,
  getOrderByOptions,
  getOrderRules
} from "./helper";
import Header from "./Header";
import WorkoutsTable from "./WorkoutsTable";
import ConfirmSelectionsDialog from "./ConfirmSelectionsDialog";
import { useVetWorkoutsStore } from "./useVetWorkoutsStore";

import useStyles from "./styles";

const possibleVetRoutes = [
  routes.workoutRequests,
  routes.workoutExams,
  routes.workoutPassed,
  routes.workoutFailed,
  routes.workoutRejected
];

const VetWorkoutsPage = () => {
  const classes = useStyles();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { currentUser } = useLoggedInUser();
  const { showSuccessSnack, showErrorSnack } = useSnackbar();

  const tableBodyRef = useRef<HTMLDivElement>(null);

  const {
    assignedOnly,
    selectedFacility,
    selectedTab,
    order,
    orderBy,
    searchValue,
    assignCheckedIds,
    savingCheckedIds,
    examCheckedIds,
    passCheckedIds,
    convertedToDateSelectedDateRange
  } = useVetWorkoutsStore();

  useDefaultTab(possibleVetRoutes, null, selectedTab.path);
  const { facilityBarns, isLoading: isListBarnsLoading } = useFacilityBarns(
    selectedFacility?.id
  );

  const [clearSearch, setClearSearch] = useState<boolean>(false);

  const { isRequestsTab, isRejectedTab } = checkCurrentWorkoutsTableTab(
    selectedTab.value
  );
  const isTabWithRequests = isRequestsTab || isRejectedTab;
  const isAssignAvailable = assignedOnly && !isTabWithRequests;

  const [isAssignToVetDialogOpen, setIsAssignToVetDialogOpen] =
    useState<boolean>(false);
  const [isOpenConfirmSelectionsPopup, setIsOpenConfirmSelectionsPopup] =
    useState<boolean>(false);
  const [tableRows, setTableRows] = useState<IExamsTableRowData[]>(null);

  const {
    useBulkQuickPassWorkoutRequest,
    useBulkRequireWorkoutRequestExam,
    useBulkAssignWorkoutRequestExam,
    useInfiniteListWorkoutRequests
  } = useRacehorse360Api();

  const { mutateAsync: assignExam } = useBulkAssignWorkoutRequestExam();
  const { mutateAsync: quickPassRequests } = useBulkQuickPassWorkoutRequest();
  const { mutateAsync: requireExams } = useBulkRequireWorkoutRequestExam();

  useEffect(() => {
    return () => {
      dispatch(resetCheckedIds());
    };
  }, [dispatch, selectedFacility?.id]);

  const activeColumn = columnSettings.find(item =>
    item.columnNames.includes(orderBy)
  );

  const workoutRequestsQuery: racehorse360.IWorkoutRequestFilter = {
    facilityIds: [selectedFacility?.id],
    horseOrTrainerName: searchValue?.length
      ? { contains: searchValue }
      : undefined,
    statuses: selectedTab.requestStatuses,
    date: getDateRangeQuery(convertedToDateSelectedDateRange),
    horseExamSubFilter: isTabWithRequests
      ? null
      : {
          statuses: selectedTab.examStatuses,
          assignedVetIds: isAssignAvailable ? [currentUser.rh360Id] : [],
          horseExamTypes: [
            racehorse360.HorseExamType.HORSE_EXAM_TYPE_QUICK_PASS,
            racehorse360.HorseExamType.HORSE_EXAM_TYPE_FULL_EXAM
          ],
          results: selectedTab?.results,
          withWorkout: { value: true }
        }
  };

  const requestsOrderBy = getOrderByOptions(activeColumn?.orderBy, orderBy);

  const {
    isLoading: isListWorkoutRequestsLoading,
    isFetching,
    data: workoutRequestsData,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage
  } = useInfiniteListWorkoutRequests(
    `${currentUser.rh360Id}-${searchValue}-infinite-workout-requests`,
    {
      query: workoutRequestsQuery,
      pagingOptions: {
        maxResults: selectedTab.itemsPerPage,
        includeSummary: true
      },
      getOptions: {
        select: [
          ...commonWorkoutRequestSelectedFields,
          ...selectedTab.additionalSelectedFields
        ],
        orderBy: getOrderRules(selectedTab.value, requestsOrderBy, order)
      }
    },
    {
      enabled: Boolean(selectedFacility?.id)
    }
  );

  const updatedTableRows = useUpdateInfiniteTableData(
    workoutRequestsData,
    tableRows
  );

  const visibleAssignCheckedWorkoutIds = filterVisibleCheckedWorkoutIds(
    tableRows,
    assignCheckedIds
  );
  const visiblePassCheckedWorkoutIds = filterVisibleCheckedWorkoutIds(
    tableRows,
    passCheckedIds
  );
  const visibleExamCheckedWorkoutIds = filterVisibleCheckedWorkoutIds(
    tableRows,
    examCheckedIds
  );

  const getTabsWithTotalResults = () => {
    if (isRequestsTab) {
      const newName = `Requests (${workoutRequestsData?.totalResults || 0})`;

      return tabs.map(tab =>
        tab.name === "Requests" ? { ...tab, name: newName } : tab
      );
    } else {
      return tabs;
    }
  };

  useInfiniteScroll(
    tableBodyRef,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    true,
    true
  );

  const resetRequestsCache = () => {
    return queryClient.invalidateQueries({
      queryKey: [
        `${currentUser.rh360Id}-${searchValue}-infinite-workout-requests`
      ]
    });
  };

  const saveRequestsSelection = () => {
    const promises = [];

    if (visiblePassCheckedWorkoutIds.length) {
      promises.push(quickPassRequests({ ids: visiblePassCheckedWorkoutIds }));
    }

    if (visibleExamCheckedWorkoutIds.length) {
      promises.push(requireExams({ ids: visibleExamCheckedWorkoutIds }));
    }

    const checkedIds = [
      ...visiblePassCheckedWorkoutIds,
      ...visibleExamCheckedWorkoutIds
    ];

    dispatch(setSavingCheckedIds([...savingCheckedIds, ...checkedIds]));

    const clearSavingCheckedIds = () => {
      dispatch(
        setSavingCheckedIds(
          savingCheckedIds.filter(savingCheckedId => {
            return checkedIds.includes(savingCheckedId);
          })
        )
      );
    };

    Promise.all(promises)
      .then(() => {
        dispatch(setExamCheckedIds([]));
        dispatch(setPassCheckedIds([]));
        clearSavingCheckedIds();
        return resetRequestsCache();
      })
      .catch(error => {
        console.error("ERROR", error);
        clearSavingCheckedIds();
      });
  };

  const saveExamsSelection = (vetId: string, assignCheckedIds: string[]) => {
    dispatch(setSavingCheckedIds([...savingCheckedIds, ...assignCheckedIds]));

    const clearSavingCheckedIds = () => {
      dispatch(
        setSavingCheckedIds(
          savingCheckedIds.filter(savingCheckedId => {
            return assignCheckedIds.includes(savingCheckedId);
          })
        )
      );
    };

    assignExam({
      ids: assignCheckedIds,
      assignedVetId: vetId,
      getOptions: {
        select: [
          ...commonWorkoutRequestSelectedFields,
          ...selectedTab.additionalSelectedFields
        ]
      }
    })
      .then(data => {
        handleUpdateTableRows(
          data.workoutRequests?.map(item => examsRowDataMapper(item))
        );

        showSuccessSnack("Vet Assigned");
        dispatch(setAssignCheckedIds([]));
        clearSavingCheckedIds();
      })
      .catch(error => {
        showErrorSnack(error.message);
        console.error(error);
        clearSavingCheckedIds();
      });
  };

  const openAssignToVetDialog = () => {
    setIsAssignToVetDialogOpen(true);
  };

  const handleTabChange = useCallback(
    (tab: VetWorkoutsTab) => {
      const { isExamsTab } = checkCurrentWorkoutsTableTab(tab.value);

      if (!convertedToDateSelectedDateRange) {
        const date: DateRangeISO = {
          Type: DateRangeType.Next3Days
        };

        dispatch(setStoreSelectedDateRange(date));
      }

      isExamsTab && dispatch(setOrderBy(EColumnName.TRAINER_LASTNAME));
      dispatch(setStoreSelectedTab(tab));
      window.scrollTo(0, 0);
    },
    [dispatch]
  );

  const handleUpdateTableRows = useCallback(
    (newTableRows: IExamsTableRowData[]) => {
      const updatedTableRows = updateTableRowsAccordingToNewRows(
        tableRows,
        newTableRows
      );

      setTableRows(updatedTableRows);
    },
    [tableRows]
  );

  const handleAssignmentDialogConfirm = (vetId: string) => {
    setIsAssignToVetDialogOpen(false);
    saveExamsSelection(vetId, visibleAssignCheckedWorkoutIds);
  };

  const handleAssignmentDialogClose = () => {
    setIsAssignToVetDialogOpen(false);
  };

  const handleClearSearch = useCallback(
    (value: boolean) => {
      dispatch(setSearchQuery(""));
      setClearSearch(value);
    },
    [dispatch]
  );

  const handleOpenConfirmSelectionsDialog = () => {
    setIsOpenConfirmSelectionsPopup(true);
  };

  const handleCloseConfirmSelectionsDialog = () => {
    setIsOpenConfirmSelectionsPopup(false);
  };

  const shouldHideTabs = [searchValue, !tableRows?.length].every(Boolean);

  const shouldShowMainLoader = [
    isListBarnsLoading,
    isListWorkoutRequestsLoading,
    !tableRows,
    isFetching && !isFetchingNextPage
  ].some(Boolean);

  useEffect(() => {
    dispatch(setFacilityBarns(facilityBarns));
  }, [facilityBarns]);

  useEffect(() => {
    setTableRows(updatedTableRows);
  }, [updatedTableRows]);

  const pageContent = shouldShowMainLoader ? (
    <Loader />
  ) : (
    <WorkoutsTable
      rows={tableRows}
      onUpdateTableRows={handleUpdateTableRows}
      isFetchingNextPage={isFetchingNextPage}
      tableBodyRef={tableBodyRef}
      searchValue={searchValue}
      onClearSearch={handleClearSearch}
    />
  );

  return (
    <AppPage className={classes.appPage}>
      <AppPageHeader className={classes.appPageHeader}>
        <Header
          workoutRequestsQuery={workoutRequestsQuery}
          openAssignToVetDialog={openAssignToVetDialog}
          openConfirmSelectionsDialog={handleOpenConfirmSelectionsDialog}
          clearSearch={clearSearch}
          onClearSearch={handleClearSearch}
          tableRows={tableRows}
        />
        {!shouldHideTabs && (
          <TabsComponent
            className={classes.tabs}
            tabs={getTabsWithTotalResults()}
            selected={selectedTab}
            onChange={handleTabChange}
          />
        )}
      </AppPageHeader>

      <AppPageContent>{pageContent}</AppPageContent>

      <ConfirmAssignmentDialog
        open={isAssignToVetDialogOpen}
        onClose={handleAssignmentDialogClose}
        onConfirm={handleAssignmentDialogConfirm}
        selectedItemsAmount={visibleAssignCheckedWorkoutIds.length}
        selectedFacilityId={selectedFacility?.id}
      />

      {isOpenConfirmSelectionsPopup && (
        <ErrorBoundary>
          <ConfirmSelectionsDialog
            open={true}
            onClose={handleCloseConfirmSelectionsDialog}
            onSaveRequestsSelection={saveRequestsSelection}
            workoutRequestQuery={workoutRequestsQuery}
            passCheckedIds={visiblePassCheckedWorkoutIds}
            examCheckedIds={visibleExamCheckedWorkoutIds}
          />
        </ErrorBoundary>
      )}
    </AppPage>
  );
};

export default React.memo(VetWorkoutsPage);
