import React from "react";

import { racehorse360 } from "@tsg/1st-grpc-web";

import TableCellWrapper from "components/VetExamsTable/TableCellWrapper/TableCellWrapper";
import { getPersonFullName } from "utils/person";
import { NONE } from "common/constants";

import useStyles from "./styles";

interface IProps {
  assignedVet: racehorse360.IUser;
}

const AssignedVetCell = (props: IProps) => {
  const { assignedVet } = props;

  const classes = useStyles();

  return (
    <TableCellWrapper fadedOut={!assignedVet}>
      <div className={classes.ellipsis}>
        {assignedVet ? getPersonFullName(assignedVet) : NONE}
      </div>
    </TableCellWrapper>
  );
};

export default React.memo(AssignedVetCell);
