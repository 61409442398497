import { makeStyles, Theme } from "@material-ui/core/styles";

interface StylesProps {
  riskFactorsWidth: number;
}

const columnsWidth = {
  horseAndTrainer: "180px",
  raceDate: "100px",
  raceNumber: "100px",
  racePosition: "100px",
  watchList: "110px",
  riskLevel: "100px",
  barn: "100px",
  csa: "100px",
  hsf: "80px",
  dlw: "80px",
  dlr: "80px",
  vetLists: "100px",
  risksFactors: "120px",
  exam: "120px",
  entryReview: "140px",
  unAssign: "80px",
  assignedVet: "180px",
  extender: "auto"
};

const getActualRiskFactorsColumnWidth = (riskFactorsWidth: number) => {
  return riskFactorsWidth ? `${riskFactorsWidth}px` : columnsWidth.risksFactors;
};

const getEntriesTabTemplateColumns = ({
  riskFactorsWidth
}: StylesProps): string => `
  ${columnsWidth.horseAndTrainer}
  ${columnsWidth.raceDate}
  ${columnsWidth.raceNumber}
  ${columnsWidth.racePosition}
  ${columnsWidth.watchList}
  ${columnsWidth.riskLevel}
  ${columnsWidth.barn}
  ${columnsWidth.csa}
  ${columnsWidth.hsf}
  ${columnsWidth.dlw}
  ${columnsWidth.dlr}
  ${columnsWidth.vetLists}
  ${getActualRiskFactorsColumnWidth(riskFactorsWidth)}
  ${columnsWidth.extender}
`;

const getEntryExamsTabTemplateColumns = ({
  riskFactorsWidth
}: StylesProps): string => `
  ${columnsWidth.horseAndTrainer}
  ${columnsWidth.raceDate}
  ${columnsWidth.raceNumber}
  ${columnsWidth.racePosition}
  ${columnsWidth.assignedVet}
  ${columnsWidth.unAssign}
  ${columnsWidth.exam}
  ${columnsWidth.riskLevel}
  ${columnsWidth.watchList}
  ${columnsWidth.barn}
  ${columnsWidth.csa}
  ${columnsWidth.hsf}
  ${columnsWidth.dlw}
  ${columnsWidth.dlr}
  ${columnsWidth.vetLists}
  ${getActualRiskFactorsColumnWidth(riskFactorsWidth)}
  ${columnsWidth.extender}
`;

const getRaceDayTabTemplateColumns = ({
  riskFactorsWidth
}: StylesProps): string => `
  ${columnsWidth.horseAndTrainer}
  ${columnsWidth.raceDate}
  ${columnsWidth.raceNumber}
  ${columnsWidth.racePosition}
  ${columnsWidth.entryReview}
  ${columnsWidth.assignedVet}
  ${columnsWidth.unAssign}
  ${columnsWidth.exam}
  ${columnsWidth.riskLevel}
  ${columnsWidth.watchList}
  ${columnsWidth.barn}
  ${columnsWidth.csa}
  ${columnsWidth.hsf}
  ${columnsWidth.dlw}
  ${columnsWidth.dlr}
  ${columnsWidth.vetLists}
  ${getActualRiskFactorsColumnWidth(riskFactorsWidth)}
  ${columnsWidth.extender}
`;

export default makeStyles<Theme, StylesProps | null>({
  rowContentEntries: {
    gridTemplateColumns: getEntriesTabTemplateColumns
  },
  rowContentEntryExams: {
    gridTemplateColumns: getEntryExamsTabTemplateColumns
  },
  rowContentRaceDay: {
    gridTemplateColumns: getRaceDayTabTemplateColumns
  },
  rowContentPassed: {
    gridTemplateColumns: `
      ${columnsWidth.horseAndTrainer}
      ${columnsWidth.raceDate}
      ${columnsWidth.raceNumber}
      ${columnsWidth.racePosition}
      ${columnsWidth.entryReview}
      ${columnsWidth.riskLevel}
      ${columnsWidth.barn}
      ${columnsWidth.csa}
      ${columnsWidth.hsf}
      ${columnsWidth.dlw}
      ${columnsWidth.dlr}
      ${columnsWidth.extender}
    `
  }
});
