import { racehorse360 } from "@tsg/1st-grpc-web";
import { DateRangeISO } from "interfaces/DateRange";
import { VetWorkoutsTab } from "interfaces/VetWorkoutsTab";
import { SortOrderType } from "interfaces/SortOrder";
import { EColumnName } from "pages/WorkoutsPage/Vet/WorkoutsTable/options";

const base = "VET_WORKOUTS_PAGE";

export const SET_SEARCH_VALUE = `${base}_SET_SEARCH_VALUE`;
export const SET_ASSIGNED_ONLY = `${base}_SET_ASSIGNED_ONLY`;
export const SET_SELECTED_DATE_RANGE = `${base}_SET_SELECTED_DATE_RANGE`;
export const SET_SELECTED_FACILITY = `${base}_SET_SELECTED_FACILITY`;
export const SET_SELECTED_TAB = `${base}_SET_SELECTED_TAB`;
export const SET_PAGE_NUMBER = `${base}_SET_PAGE_NUMBER`;
export const SET_ORDER = `${base}_SET_ORDER`;
export const SET_ORDER_BY = `${base}_SET_ORDER_BY`;
export const SET_EXAM_CHECKED_IDS_BY = `${base}_SET_EXAM_CHECKED_IDS_BY`;
export const SET_PASS_CHECKED_IDS_BY = `${base}_SET_PASS_CHECKED_IDS_BY`;
export const SET_ASSIGN_CHECKED_IDS_BY = `${base}_SET_ASSIGN_CHECKED_IDS_BY`;
export const REMOVE_ASSIGN_CHECKED_ID_BY = `${base}_REMOVE_ASSIGN_CHECKED_ID_BY`;
export const RESET_CHECKED_IDS = `${base}_RESET_CHECKED_IDS`;
export const SET_SAVING_CHECKED_IDS_BY = `${base}_SET_SAVING_CHECKED_IDS_BY`;
export const SET_SAVING_UNDO_QUICK_PASS_IDS_BY = `${base}_SET_SAVING_UNDO_QUICK_PASS_IDS_BY`;
export const SET_SAVING_UNASSIGN_IDS_BY = `${base}_SET_SAVING_UNASSIGN_IDS_BY`;
export const SET_FACILITY_BARNS = `${base}_SET_FACILITY_BARNS`;

export interface VetWorkoutsPageType {
  type: string;
  searchValue?: string;
  selectedFacility: racehorse360.IFacility;
  assignedOnly: boolean;
  selectedDateRange: DateRangeISO;
  selectedTab: VetWorkoutsTab;
  pageNumber: number;
  order: SortOrderType;
  orderBy: EColumnName;
  examCheckedIds: string[];
  passCheckedIds: string[];
  assignCheckedIds: string[];
  assignCheckedId: string;
  savingCheckedIds: string[];
  savingUndoQuickPassIds: string[];
  savingUnAssignIds: string[];
  facilityBarns: racehorse360.IHorse[];
}

export const setSearchQuery = (
  searchValue: string
): Partial<VetWorkoutsPageType> => {
  return {
    type: SET_SEARCH_VALUE,
    searchValue
  };
};

export const setAssignedOnly = (
  assignedOnly: boolean
): Partial<VetWorkoutsPageType> => {
  return {
    type: SET_ASSIGNED_ONLY,
    assignedOnly
  };
};

export const setSelectedDateRange = (
  selectedDateRange: DateRangeISO
): Partial<VetWorkoutsPageType> => {
  return {
    type: SET_SELECTED_DATE_RANGE,
    selectedDateRange
  };
};

export const setSelectedFacility = (
  selectedFacility: racehorse360.IFacility
): Partial<VetWorkoutsPageType> => {
  return {
    type: SET_SELECTED_FACILITY,
    selectedFacility
  };
};

export const setSelectedTab = (
  selectedTab: VetWorkoutsTab
): Partial<VetWorkoutsPageType> => {
  return {
    type: SET_SELECTED_TAB,
    selectedTab
  };
};

export const setPageNumber = (
  pageNumber: number
): Partial<VetWorkoutsPageType> => {
  return {
    type: SET_PAGE_NUMBER,
    pageNumber
  };
};

export const setOrder = (
  order: SortOrderType
): Partial<VetWorkoutsPageType> => {
  return {
    type: SET_ORDER,
    order
  };
};

export const setOrderBy = (
  orderBy: EColumnName
): Partial<VetWorkoutsPageType> => {
  return {
    type: SET_ORDER_BY,
    orderBy
  };
};

export const setExamCheckedIds = (
  examCheckedIds: string[]
): Partial<VetWorkoutsPageType> => {
  return {
    type: SET_EXAM_CHECKED_IDS_BY,
    examCheckedIds
  };
};

export const setPassCheckedIds = (
  passCheckedIds: string[]
): Partial<VetWorkoutsPageType> => {
  return {
    type: SET_PASS_CHECKED_IDS_BY,
    passCheckedIds
  };
};

export const setAssignCheckedIds = (
  assignCheckedIds: string[]
): Partial<VetWorkoutsPageType> => {
  return {
    type: SET_ASSIGN_CHECKED_IDS_BY,
    assignCheckedIds
  };
};

export const removeAssignCheckedId = (
  assignCheckedId: string
): Partial<VetWorkoutsPageType> => {
  return {
    type: REMOVE_ASSIGN_CHECKED_ID_BY,
    assignCheckedId
  };
};

export const resetCheckedIds = (): Partial<VetWorkoutsPageType> => {
  return {
    type: RESET_CHECKED_IDS
  };
};

export const setSavingCheckedIds = (
  savingCheckedIds: string[]
): Partial<VetWorkoutsPageType> => {
  return {
    type: SET_SAVING_CHECKED_IDS_BY,
    savingCheckedIds
  };
};

export const setSavingUndoQuickPassIds = (
  savingUndoQuickPassIds: string[]
): Partial<VetWorkoutsPageType> => {
  return {
    type: SET_SAVING_UNDO_QUICK_PASS_IDS_BY,
    savingUndoQuickPassIds
  };
};

export const setSavingUnAssignIds = (
  savingUnAssignIds: string[]
): Partial<VetWorkoutsPageType> => {
  return {
    type: SET_SAVING_UNASSIGN_IDS_BY,
    savingUnAssignIds
  };
};

export const setFacilityBarns = (
  facilityBarns: Pick<racehorse360.IBarn, "id" | "name">[]
): Pick<VetWorkoutsPageType, "type" | "facilityBarns"> => ({
  type: SET_FACILITY_BARNS,
  facilityBarns
});
