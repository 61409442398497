import VetWorkoutsPageState from "interfaces/VetWorkoutsPageState";
import { tabs } from "interfaces/VetWorkoutsTab";
import { SortOrder } from "interfaces/SortOrder";
import {
  REMOVE_ASSIGN_CHECKED_ID_BY,
  RESET_CHECKED_IDS,
  SET_ASSIGN_CHECKED_IDS_BY,
  SET_ASSIGNED_ONLY,
  SET_EXAM_CHECKED_IDS_BY,
  SET_FACILITY_BARNS,
  SET_ORDER,
  SET_ORDER_BY,
  SET_PAGE_NUMBER,
  SET_PASS_CHECKED_IDS_BY,
  SET_SAVING_CHECKED_IDS_BY,
  SET_SAVING_UNASSIGN_IDS_BY,
  SET_SAVING_UNDO_QUICK_PASS_IDS_BY,
  SET_SEARCH_VALUE,
  SET_SELECTED_DATE_RANGE,
  SET_SELECTED_FACILITY,
  SET_SELECTED_TAB,
  VetWorkoutsPageType
} from "store/actions/vetWorkoutsPage";

const initialState: VetWorkoutsPageState = {
  searchValue: "",
  assignedOnly: false,
  selectedDateRange: null,
  selectedFacility: null,
  selectedTab: tabs[0],
  pageNumber: 1,
  order: SortOrder.ASC,
  orderBy: "date",
  examCheckedIds: [],
  passCheckedIds: [],
  assignCheckedIds: [],
  savingCheckedIds: [],
  savingUndoQuickPassIds: [],
  savingUnAssignIds: [],
  facilityBarns: []
};

export default (
  state = initialState,
  action: VetWorkoutsPageType
): VetWorkoutsPageState => {
  switch (action.type) {
    case SET_SEARCH_VALUE:
      return {
        ...state,
        searchValue: action.searchValue
      };
    case SET_ASSIGNED_ONLY:
      return {
        ...state,
        assignedOnly: action.assignedOnly
      };
    case SET_SELECTED_DATE_RANGE:
      return {
        ...state,
        selectedDateRange: action.selectedDateRange
      };
    case SET_SELECTED_FACILITY:
      return {
        ...state,
        selectedFacility: action.selectedFacility
      };
    case SET_SELECTED_TAB:
      return {
        ...state,
        selectedTab: action.selectedTab
      };
    case SET_PAGE_NUMBER:
      return {
        ...state,
        pageNumber: action.pageNumber
      };
    case SET_ORDER:
      return {
        ...state,
        order: action.order
      };
    case SET_ORDER_BY:
      return {
        ...state,
        orderBy: action.orderBy
      };
    case SET_EXAM_CHECKED_IDS_BY:
      return {
        ...state,
        examCheckedIds: action.examCheckedIds
      };
    case SET_PASS_CHECKED_IDS_BY:
      return {
        ...state,
        passCheckedIds: action.passCheckedIds
      };
    case SET_ASSIGN_CHECKED_IDS_BY:
      return {
        ...state,
        assignCheckedIds: action.assignCheckedIds
      };
    case REMOVE_ASSIGN_CHECKED_ID_BY:
      return {
        ...state,
        assignCheckedIds: state.assignCheckedIds.filter(
          id => id !== action.assignCheckedId
        )
      };
    case RESET_CHECKED_IDS:
      return {
        ...state,
        examCheckedIds: [],
        passCheckedIds: [],
        assignCheckedIds: []
      };
    case SET_SAVING_CHECKED_IDS_BY:
      return {
        ...state,
        savingCheckedIds: action.savingCheckedIds
      };
    case SET_SAVING_UNDO_QUICK_PASS_IDS_BY:
      return {
        ...state,
        savingUndoQuickPassIds: action.savingUndoQuickPassIds
      };
    case SET_SAVING_UNASSIGN_IDS_BY:
      return {
        ...state,
        savingUnAssignIds: action.savingUnAssignIds
      };
    case SET_FACILITY_BARNS: {
      return {
        ...state,
        facilityBarns: action.facilityBarns
      };
    }
    default:
      return state;
  }
};
