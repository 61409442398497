import { makeStyles } from "@material-ui/core/styles";
import { MuiTheme } from "theme";

export default makeStyles((theme: MuiTheme) => ({
  listWrapper: {
    overflow: "hidden"
  },
  list: {
    padding: 0
  },
  listItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "18px 16px",
    "&:nth-child(4n - 1)": {
      background: "#fbfbfb"
    }
  },
  barnNumberPickerRow: {
    height: 52
  },
  listItemLabel: {
    marginRight: 12,
    lineHeight: 1
  },
  listItemValue: {
    lineHeight: 1,
    textAlign: "right"
  },
  defaultAlignment: {
    textAlign: "initial"
  },
  listItemText: {
    color: theme.palette.text.primary,
    fontSize: 14
  },
  none: {
    color: theme.palette.text.hint
  },
  barnNumberPicker: {
    padding: "0 0 0 12px",
    fontSize: 16,
    "& span": {
      paddingTop: 2
    }
  }
}));
