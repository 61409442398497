import { useMemo } from "react";
import { racehorse360 } from "@tsg/1st-grpc-web";
import { useRacehorse360Api } from "hooks/api";

interface IUseFacilityBarnsReturnType {
  facilityBarns: Pick<racehorse360.IBarn, "id" | "name">[];
  isLoading: boolean;
}

export const useFacilityBarns = (
  facilityId: string
): IUseFacilityBarnsReturnType => {
  const { useListBarns } = useRacehorse360Api();

  const { data, isLoading } = useListBarns(
    {
      query: { facilityId: facilityId },
      getOptions: { select: ["name"] }
    },
    {
      enabled: Boolean(facilityId)
    }
  );

  const facilityBarns = data?.barns
    .map(barn => ({ id: barn.id, name: barn.name }))
    .sort((barn1, barn2) => {
      const parseBarnNumber = (number: string): [number, string] => {
        const match = number.match(/^(\d+)([A-Za-z]*)$/);

        return match ? [Number.parseInt(match[1]), match[2]] : [0, ""];
      };

      const [numA, letterA] = parseBarnNumber(barn1.name);
      const [numB, letterB] = parseBarnNumber(barn2.name);

      return numA - numB || letterA.localeCompare(letterB);
    });

  return useMemo(
    () => ({
      facilityBarns,
      isLoading
    }),
    [data, isLoading]
  );
};
