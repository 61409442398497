import {
  EPreRacesTableTab,
  EPreRaceTableColumn,
  EPreRaceTableColumnSorting,
  IPreRaceTableTab
} from "./types";
import { racehorse360 } from "@tsg/1st-grpc-web";
import { EExamTableStatus } from "components/VetExamsTable/types";
import { SortOrder, SortOrderExtended } from "interfaces/SortOrder";
import { checkSortingType, reverseSortOrderValue } from "utils/sort-utils";

export interface IPreRacesTabsStatuses {
  isEntriesTab: boolean;
  isEntryExamsTab: boolean;
  isResultsTab: boolean;
  isRaceDayTab: boolean;
}

export const checkCurrentRaceEntriesTableTab = (
  tab: EPreRacesTableTab
): IPreRacesTabsStatuses => {
  return {
    isEntriesTab: tab === EPreRacesTableTab.ENTRIES,
    isEntryExamsTab: tab === EPreRacesTableTab.ENTRY_EXAMS,
    isResultsTab: tab === EPreRacesTableTab.RESULTS,
    isRaceDayTab: tab === EPreRacesTableTab.RACE_DAY
  };
};

export const createConfigTable = (
  selectedTab: IPreRaceTableTab
): EPreRaceTableColumn[] => {
  switch (selectedTab.value) {
    case EPreRacesTableTab.ENTRY_EXAMS: {
      return [
        EPreRaceTableColumn.TRAINER,
        EPreRaceTableColumn.RACE_DATE,
        EPreRaceTableColumn.RACE_NUMBER,
        EPreRaceTableColumn.RACE_POSITION,
        EPreRaceTableColumn.ASSIGNED_VET,
        EPreRaceTableColumn.UN_ASSIGN,
        EPreRaceTableColumn.EXAM,
        EPreRaceTableColumn.RISK_LEVEL,
        EPreRaceTableColumn.WATCH_LIST,
        EPreRaceTableColumn.BARN,
        EPreRaceTableColumn.CSA,
        EPreRaceTableColumn.HSF,
        EPreRaceTableColumn.DLW,
        EPreRaceTableColumn.DLR,
        EPreRaceTableColumn.ACTIVE_VET_LISTS,
        EPreRaceTableColumn.RISK_FACTORS,
        EPreRaceTableColumn.EXTENDER
      ];
    }
    case EPreRacesTableTab.RESULTS: {
      return [
        EPreRaceTableColumn.TRAINER,
        EPreRaceTableColumn.RACE_DATE,
        EPreRaceTableColumn.RACE_NUMBER,
        EPreRaceTableColumn.RACE_POSITION,
        EPreRaceTableColumn.ENTRY_REVIEW,
        EPreRaceTableColumn.RISK_LEVEL,
        EPreRaceTableColumn.BARN,
        EPreRaceTableColumn.CSA,
        EPreRaceTableColumn.HSF,
        EPreRaceTableColumn.DLW,
        EPreRaceTableColumn.DLR
      ];
    }
    case EPreRacesTableTab.RACE_DAY: {
      return [
        EPreRaceTableColumn.TRAINER,
        EPreRaceTableColumn.RACE_DATE,
        EPreRaceTableColumn.RACE_NUMBER,
        EPreRaceTableColumn.RACE_POSITION,
        EPreRaceTableColumn.ENTRY_REVIEW,
        EPreRaceTableColumn.ASSIGNED_VET,
        EPreRaceTableColumn.UN_ASSIGN,
        EPreRaceTableColumn.EXAM,
        EPreRaceTableColumn.RISK_LEVEL,
        EPreRaceTableColumn.WATCH_LIST,
        EPreRaceTableColumn.BARN,
        EPreRaceTableColumn.CSA,
        EPreRaceTableColumn.HSF,
        EPreRaceTableColumn.DLW,
        EPreRaceTableColumn.DLR,
        EPreRaceTableColumn.ACTIVE_VET_LISTS,
        EPreRaceTableColumn.RISK_FACTORS,
        EPreRaceTableColumn.EXTENDER
      ];
    }
    default: {
      return [
        EPreRaceTableColumn.TRAINER,
        EPreRaceTableColumn.RACE_DATE,
        EPreRaceTableColumn.RACE_NUMBER,
        EPreRaceTableColumn.RACE_POSITION,
        EPreRaceTableColumn.WATCH_LIST,
        EPreRaceTableColumn.RISK_LEVEL,
        EPreRaceTableColumn.BARN,
        EPreRaceTableColumn.CSA,
        EPreRaceTableColumn.HSF,
        EPreRaceTableColumn.DLW,
        EPreRaceTableColumn.DLR,
        EPreRaceTableColumn.ACTIVE_VET_LISTS,
        EPreRaceTableColumn.RISK_FACTORS,
        EPreRaceTableColumn.EXTENDER
      ];
    }
  }
};

export const getPreRaceExamTableStatus = (
  examType: racehorse360.HorseExamType,
  examResult: racehorse360.HorseExamResult
): EExamTableStatus => {
  if (examResult === racehorse360.HorseExamResult.HORSE_EXAM_RESULT_FAILED) {
    return EExamTableStatus.EXAM_FAILED;
  }

  if (examResult === racehorse360.HorseExamResult.HORSE_EXAM_RESULT_PASSED) {
    if (examType === racehorse360.HorseExamType.HORSE_EXAM_TYPE_QUICK_PASS) {
      return EExamTableStatus.QUICK_PASSED;
    }

    return EExamTableStatus.EXAM_PASSED;
  }
};

export const getDateRangeFilter = (
  isRaceDayTab: boolean,
  timezone: string
): racehorse360.IDateMatch => {
  const defaultDateRange: racehorse360.IDateMatch = {
    range: {
      relative: {
        startDate: {
          value: 1
        },
        timezone
      }
    }
  };

  if (isRaceDayTab) {
    defaultDateRange.range.relative.startDate.value = 0;
    defaultDateRange.range.relative.endDate = { value: 1 };

    return defaultDateRange;
  }

  return defaultDateRange;
};

export const getSortingRules = (
  selectedTab: IPreRaceTableTab,
  requestedOrderBy: EPreRaceTableColumnSorting,
  sortOrder: SortOrder
): string[] => {
  const isSortingInverse = [
    EPreRaceTableColumnSorting.LAST_RACE_DATE,
    EPreRaceTableColumnSorting.LAST_WORKOUT_DATE
  ].some(sortType => sortType === requestedOrderBy);

  let actualSortOrder: SortOrder = sortOrder;

  if (isSortingInverse) {
    actualSortOrder = reverseSortOrderValue(sortOrder);
  }

  const { isEntryExamsTab, isRaceDayTab } = checkCurrentRaceEntriesTableTab(
    selectedTab.value
  );
  const isDefaultAccordionView =
    (isEntryExamsTab || isRaceDayTab) &&
    requestedOrderBy === EPreRaceTableColumnSorting.TRAINER_LAST_NAME;
  const { isOneOfAsc } = checkSortingType(actualSortOrder);
  const isSortingNullSensitive = [
    EPreRaceTableColumnSorting.RISK_LEVEL,
    EPreRaceTableColumnSorting.BARN_NAME
  ].some(sortType => requestedOrderBy === sortType);

  const defaultCommonSorting = [
    `${EPreRaceTableColumnSorting.HORSE_NAME} ${SortOrder.ASC}`,
    `${EPreRaceTableColumnSorting.RACE_DATE} ${SortOrder.ASC}`
  ];
  let columnSorting = `${requestedOrderBy} ${actualSortOrder}`;
  let specificColumnSorting: string[] = [];

  if (isSortingNullSensitive) {
    columnSorting = `${requestedOrderBy} ${
      isOneOfAsc
        ? SortOrderExtended.ASC_NULLS_LAST
        : SortOrderExtended.DESC_NULLS_LAST
    }`;
  }

  if (requestedOrderBy === EPreRaceTableColumnSorting.IS_FLAGGED) {
    specificColumnSorting = [
      `${EPreRaceTableColumnSorting.FLAG_PRIORITY} ${actualSortOrder}`
    ];
  } else if (requestedOrderBy === EPreRaceTableColumnSorting.RACE_NUMBER) {
    specificColumnSorting = [
      `${EPreRaceTableColumnSorting.RACE_POSITION} ${SortOrder.ASC}`
    ];
  } else if (isDefaultAccordionView) {
    specificColumnSorting = [
      `${EPreRaceTableColumnSorting.IS_FLAGGED} ${SortOrder.DESC}`,
      `${EPreRaceTableColumnSorting.FLAG_PRIORITY} ${SortOrder.DESC}`,
      `${EPreRaceTableColumnSorting.RISK_LEVEL} ${SortOrderExtended.DESC_NULLS_LAST}`
    ];
  }

  return [columnSorting, ...specificColumnSorting, ...defaultCommonSorting];
};
