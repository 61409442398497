import { makeStyles } from "@material-ui/core/styles";
import { MuiTheme } from "theme";

export default makeStyles((theme: MuiTheme) => ({
  button: {
    padding: "4px 6px"
  },
  icon: {
    width: 20,
    height: 20,
    color: theme.palette.primary.main
  }
}));
