import { makeStyles } from "@material-ui/core/styles";

export default makeStyles({
  barnNumberPicker: {
    justifyContent: "space-between",
    width: 50,
    "&.editMode": {
      justifyContent: "flex-end"
    },
    "&.noEditButton": {
      justifyContent: "center",
      width: "100%"
    }
  }
});
