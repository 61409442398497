import React from "react";
import clsx from "clsx";
import { useQueryClient } from "@tanstack/react-query";

import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";

import { racehorse360 } from "@tsg/1st-grpc-web";

import { useLoggedInUser } from "components/LoggedInUserProvider";
import BarnNumberPicker from "components/BarnNumberPicker/BarnNumberPicker";
import ScopeSign from "components/Icons/ScopeSign";
import { DASH } from "common/constants";
import { LIST_HORSES_QUERY_KEY } from "components/Map/Map";

import useStyles from "./styles";
import useParentStyles from "../styles";

interface IProps {
  index: number;
  style: React.CSSProperties;
  horse: racehorse360.IHorse;
  selectedHorseId: string;
  mapBarns: Record<string, unknown>;
  onHorseClick: (horse: racehorse360.IHorse) => void;
  facilityBarns: Pick<racehorse360.IBarn, "id" | "name">[];
  selectedFacilityId: string;
  onBarnChange: (barn: racehorse360.IBarn, horseId: string) => void;
}

const HorseTableRow = (props: IProps) => {
  const {
    index,
    style,
    horse,
    selectedHorseId,
    mapBarns,
    onHorseClick,
    facilityBarns,
    selectedFacilityId,
    onBarnChange
  } = props;

  const classes = useStyles();
  const parentClasses = useParentStyles();

  const queryClient = useQueryClient();
  const { currentUser } = useLoggedInUser();

  const isNoEditButton = [
    currentUser.isRegulatoryVet,
    !facilityBarns.length
  ].some(Boolean);

  const handleHorseClick = () => {
    onHorseClick(horse);
  };

  const handleBarnChange = (barn: racehorse360.IHorse) => {
    onBarnChange(barn, horse.id);
    queryClient.invalidateQueries({
      queryKey: [LIST_HORSES_QUERY_KEY]
    });
  };

  return (
    <Box
      className={clsx(parentClasses.listRow, {
        [parentClasses.evenRow]: index % 2 === 1
      })}
      style={style}
    >
      <Box
        className={clsx(parentClasses.bodyCell, parentClasses.horseNameCell)}
      >
        {horse.name}
      </Box>
      <Box className={clsx(parentClasses.bodyCell, parentClasses.barnCell)}>
        <BarnNumberPicker
          className={clsx(classes.barnNumberPicker, {
            noEditButton: isNoEditButton
          })}
          horse={horse}
          startActions={true}
          options={facilityBarns}
          iconVariant={"map"}
          missingValueSign={DASH}
          selectedFacilityId={selectedFacilityId}
          onBarnChange={handleBarnChange}
        />
      </Box>
      <Box className={clsx(parentClasses.bodyCell, parentClasses.valueCell)}>
        {horse.stall?.name || DASH}
      </Box>
      <Box className={clsx(parentClasses.bodyCell, parentClasses.scopeCell)}>
        {horse.barn && mapBarns[horse.barn.name.toLowerCase()] && (
          <IconButton size={"small"} onClick={handleHorseClick}>
            <ScopeSign
              className={clsx(parentClasses.scopeSignIcon, {
                [parentClasses.scopeActive]: selectedHorseId === horse.id
              })}
            />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};

export default React.memo(HorseTableRow);
