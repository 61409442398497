import { MuiTheme } from "theme";
import { makeStyles } from "@material-ui/core/styles";
import Breakpoints from "common/breakpoints";

export default makeStyles((theme: MuiTheme) => ({
  subHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 4,
    [theme.breakpoints.down(Breakpoints.SM_600)]: {
      "&.entryExamsTab": {
        flexDirection: "column-reverse",
        alignItems: "flex-start"
      }
    },
    [theme.breakpoints.down(Breakpoints.XS_420)]: {
      flexDirection: "column-reverse"
    }
  }
}));
