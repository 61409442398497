import React, { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import useTheme from "@material-ui/core/styles/useTheme";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Close from "@material-ui/icons/Close";
import CropFree from "@material-ui/icons/CropFree";
import Refresh from "@material-ui/icons/Refresh";
import ZoomIn from "@material-ui/icons/ZoomIn";
import ZoomOut from "@material-ui/icons/ZoomOut";
import LocationOffIcon from "@material-ui/icons/LocationOff";

import { racehorse360 } from "@tsg/1st-grpc-web";
import { useWindowSize } from "hooks/screen";

import Loader from "components/Loader";
import { useLoggedInUser } from "components/LoggedInUserProvider";
import SantaAnitaMap from "components/MapComponents/SantaAnitaMap";
import GoldenGateFieldMap from "components/MapComponents/GoldenGateFieldMap";
import GulfstreamParkMap from "components/MapComponents/GulfstreamParkMap";
import LaurelParkMap from "components/MapComponents/LaurelParkMap";
import { useRacehorse360Api } from "hooks/api";
import { getScreenPositionByBarn } from "utils/map";

import HorseTable from "./HorseTable";
import { MuiTheme } from "theme";
import { SortOrder } from "interfaces/SortOrder";
import useStyles from "./styles";

interface Props {
  currentHorse: racehorse360.IHorse;
  onFullscreenClick?: () => void;
  onOpen?: () => void;
  onClose?: () => void;
  isPreview?: boolean;
  selectedFacility?: racehorse360.IFacility;
  onBarnChange: (barn: racehorse360.IBarn, horseId: string) => void;
}

const DEFAULT_SCALE = 3;
export const LIST_HORSES_QUERY_KEY = "listHorsesQueryKey";

const MAPS = {
  GP: GulfstreamParkMap,
  GG: GoldenGateFieldMap,
  SA: SantaAnitaMap,
  LRL: LaurelParkMap
};

const Map = (props: Props) => {
  const {
    currentHorse,
    isPreview,
    onFullscreenClick,
    selectedFacility,
    onBarnChange
  } = props;

  const classes = useStyles();

  const rootRef = useRef<HTMLDivElement>();
  const { width, height } = useWindowSize();
  const clipPathRef = useRef<SVGGElement>();
  const mapRootRef = useRef<SVGGElement>();
  const svgContainerRef = useRef<HTMLDivElement>();

  const theme = useTheme<MuiTheme>();
  const matchesDownSm = useMediaQuery(theme.breakpoints.down("sm"), {
    noSsr: true
  });

  const { currentUser } = useLoggedInUser();

  const [containerSize, setContainerSize] = useState({ width: 0, height: 0 });
  const [selectedHorse, setSelectedHorse] =
    useState<racehorse360.IHorse>(currentHorse);
  const [activeBarn, setActiveBarn] = useState<string>(
    currentHorse?.barn?.name
  );
  const [existingBarns, setExistingBarns] = useState<Record<string, boolean>>(
    {}
  );
  const [scale, setScale] = useState<number>(1);
  const [positionX, setPositionX] = useState<number>(0);
  const [positionY, setPositionY] = useState<number>(0);
  const [isBarnDialogOpen, setIsBarnDialogOpen] = useState<boolean>(false);

  const { useListHorses } = useRacehorse360Api();

  const isValidMap = Object.keys(MAPS).includes(selectedFacility?.code);

  const { isLoading, data } = useListHorses(
    LIST_HORSES_QUERY_KEY,
    {
      query: {
        trainerIds: currentUser.trainerIds,
        facilityIds: [selectedFacility?.id]
      },
      pagingOptions: {
        maxResults: 9999
      },
      getOptions: {
        select: ["name", "barn.name", "stall.name", "currentFacility.id"],
        orderBy: [`name ${SortOrder.ASC}`]
      }
    },
    {
      enabled: Boolean(selectedFacility)
    }
  );

  const horses = data?.horses || [];

  const handleGroupClick = e => {
    if (!isPreview) {
      const { name } = e.target.parentElement.dataset;
      const hasHorses = horses.some(horse => horse.barn?.name === name);
      if (hasHorses) {
        setSelectedHorse(null);
        setActiveBarn(name);
        setIsBarnDialogOpen(true);
      }
    }
  };

  const handlePanningStop = options => {
    setPositionX(options.positionX);
    setPositionY(options.positionY);
  };

  const handleHorseClick = (horse: racehorse360.IHorse) => {
    setActiveBarn(horse.barn?.name);
    setSelectedHorse(horse);
    setIsBarnDialogOpen(false);
    const position = getScreenPositionByBarn(
      `[data-name='${horse?.barn?.name}']`,
      mapRootRef,
      svgContainerRef,
      containerSize
    );
    if (position) {
      setPositionX(position.x);
      setPositionY(position.y);
    }
  };

  const handleBarnDialogClose = () => {
    setIsBarnDialogOpen(false);
  };

  const renderFacilityName = (facilityName: string) => {
    return facilityName
      .toLowerCase()
      .split(" ")
      .reduce((acc, cur) => {
        acc += cur.charAt(0).toUpperCase() + cur.substring(1) + " ";
        return acc;
      }, "")
      .trim();
  };

  const renderMap = (isPreview: boolean) => {
    switch (selectedFacility?.code) {
      case "SA":
        return (
          <SantaAnitaMap
            horses={horses}
            mapRootRef={mapRootRef}
            svgContainerRef={svgContainerRef}
            clipPathRef={clipPathRef}
            containerSize={containerSize}
            handleGroupClick={handleGroupClick}
            isPreview={isPreview}
          />
        );
      case "GG":
        return (
          <GoldenGateFieldMap
            horses={horses}
            mapRootRef={mapRootRef}
            svgContainerRef={svgContainerRef}
            clipPathRef={clipPathRef}
            containerSize={containerSize}
            handleGroupClick={handleGroupClick}
            isPreview={isPreview}
          />
        );
      case "GP":
        return (
          <GulfstreamParkMap
            horses={horses}
            mapRootRef={mapRootRef}
            svgContainerRef={svgContainerRef}
            clipPathRef={clipPathRef}
            containerSize={containerSize}
            handleGroupClick={handleGroupClick}
            isPreview={isPreview}
          />
        );
      case "LRL":
        return (
          <LaurelParkMap
            horses={horses}
            mapRootRef={mapRootRef}
            svgContainerRef={svgContainerRef}
            clipPathRef={clipPathRef}
            containerSize={containerSize}
            handleGroupClick={handleGroupClick}
            isPreview={isPreview}
          />
        );
      default:
        return <></>;
    }
  };

  const renderPreview = () => {
    const horseBarn = currentHorse?.barn;
    const horseStall = currentHorse?.stall;
    const facilityName: string = horseBarn
      ? horseBarn?.facility.name
      : currentHorse?.currentFacility?.name;
    const mobileWithoutMap = matchesDownSm && !isValidMap;
    const mobileWithMap = matchesDownSm && isValidMap;
    const flexDirectionBarnStall = mobileWithMap ? "column" : "row";

    return (
      <Box className={clsx(classes.mapRoot, classes.default)}>
        <Box
          className={clsx(
            classes.header,
            { [classes.mobileWithoutMap]: mobileWithoutMap },
            { [classes.mobileWithMap]: mobileWithMap }
          )}
        >
          {facilityName ? (
            <>
              <Box
                className={clsx(classes.facilityName, {
                  [classes.facilityWithMap]: mobileWithMap
                })}
              >
                {renderFacilityName(facilityName)}
              </Box>
              {horseBarn && (
                <Box className={classes.barnStallWrapper}>
                  <Box
                    className={classes.barn}
                    flexDirection={flexDirectionBarnStall}
                  >
                    <Box className={classes.barnText}>Barn</Box>
                    <Box className={classes.number}>{horseBarn?.name}</Box>
                  </Box>
                  {horseStall && (
                    <Box
                      className={classes.stall}
                      flexDirection={flexDirectionBarnStall}
                    >
                      <Box className={classes.stallText}>Stall</Box>
                      <Box className={classes.number}>{horseStall?.name}</Box>
                    </Box>
                  )}
                </Box>
              )}
            </>
          ) : (
            <Box className={classes.unavailableLocation}>
              <Typography className={classes.locationText}>
                Location unavailable
              </Typography>
              <LocationOffIcon />
            </Box>
          )}
        </Box>

        {isValidMap && (
          <Box className={classes.content}>
            <div
              ref={rootRef}
              className={clsx(classes.wrapper, classes.relative)}
            >
              {isLoading && <Loader overlay />}
              <>
                <TransformWrapper
                  defaultScale={1}
                  scale={scale}
                  positionX={positionX}
                  positionY={positionY}
                  pan={{
                    velocity: false
                  }}
                  scalePadding={{
                    disabled: true
                  }}
                  options={{
                    disabled: true,
                    limitToBounds: true,
                    limitToWrapper: true,
                    centerContent: false
                  }}
                >
                  <TransformComponent>{renderMap(true)}</TransformComponent>
                </TransformWrapper>
                <IconButton
                  className={clsx(classes.tool, classes.fullscreenTool)}
                  onClick={onFullscreenClick}
                >
                  <CropFree />
                </IconButton>
              </>
            </div>
          </Box>
        )}
      </Box>
    );
  };

  useEffect(() => {
    if (mapRootRef.current) {
      const svgContainerRect = svgContainerRef.current.getBoundingClientRect();
      const clipPathRect = clipPathRef.current.getBoundingClientRect();
      const position = getScreenPositionByBarn(
        `[data-name='${currentHorse?.barn?.name}']`,
        mapRootRef,
        svgContainerRef,
        containerSize
      );

      if (position) {
        setScale(5);
        setPositionX(position.x);
        setPositionY(position.y);
      } else if (
        containerSize.width &&
        containerSize.height &&
        clipPathRect.width &&
        clipPathRect.height
      ) {
        const widthRatio = containerSize.width / (clipPathRect.width / scale);
        const heightRatio =
          containerSize.height / (clipPathRect.height / scale);
        const newScale = widthRatio > heightRatio ? widthRatio : heightRatio;
        const mapRelativeX =
          ((clipPathRect.left - svgContainerRect.left) / scale) * newScale;
        const mapRelativeY =
          ((clipPathRect.top - svgContainerRect.top) / scale) * newScale;
        setScale(newScale);
        setPositionX(-Math.abs(Math.floor(mapRelativeX)));
        setPositionY(-Math.abs(Math.floor(mapRelativeY)));
      }
    }
  }, [mapRootRef.current, containerSize.width, containerSize.height]);

  useEffect(() => {
    if (rootRef.current) {
      const rect = rootRef.current?.getBoundingClientRect();
      setContainerSize({
        width: Math.ceil(rect.width),
        height: Math.ceil(rect.height)
      });
    }
  }, [width, height]);

  useEffect(() => {
    if (mapRootRef.current) {
      const barns = {};
      Array.from(mapRootRef.current.querySelectorAll("[data-name]")).forEach(
        (g: SVGGElement) => {
          const name = g.dataset?.name;
          if (name) barns[name.toLowerCase()] = true;
        }
      );
      setExistingBarns(barns);
    }
  }, [mapRootRef.current]);

  useEffect(() => {
    isPreview && handleHorseClick(currentHorse);
  }, [currentHorse]);

  return isPreview ? (
    renderPreview()
  ) : (
    <Box className={clsx(classes.mapRoot, classes.fullscreen)}>
      <Box className={classes.content}>
        <Box className={classes.fullscreenHorseList}>
          <HorseTable
            horses={horses}
            isLoading={isLoading}
            maxHeight={containerSize.height}
            selectedHorse={selectedHorse}
            selectedFacilityId={selectedFacility?.id}
            onHorseClick={handleHorseClick}
            onBarnChange={onBarnChange}
            mapBarns={existingBarns}
          />
        </Box>
        {isValidMap ? (
          <div
            ref={rootRef}
            className={clsx(classes.wrapper, classes.relative)}
          >
            <div className={classes.innerWrapper}>
              {isLoading && <Loader overlay />}
              <TransformWrapper
                onPanningStop={handlePanningStop}
                defaultScale={DEFAULT_SCALE}
                positionX={positionX}
                positionY={positionY}
                pan={{
                  velocity: false
                }}
                scalePadding={{
                  disabled: true
                }}
                options={{
                  limitToBounds: true,
                  limitToWrapper: true,
                  centerContent: false
                }}
              >
                {({ zoomIn, zoomOut, resetTransform }) => (
                  <React.Fragment>
                    <Box className={classes.tools}>
                      <IconButton
                        className={clsx(classes.tool, classes.refreshTool)}
                        onClick={resetTransform}
                      >
                        <Refresh />
                      </IconButton>
                      <IconButton
                        className={clsx(classes.tool, classes.zoomInTool)}
                        onClick={zoomIn}
                      >
                        <ZoomIn />
                      </IconButton>
                      <IconButton
                        className={clsx(classes.tool, classes.zoomOutTool)}
                        onClick={zoomOut}
                      >
                        <ZoomOut />
                      </IconButton>
                    </Box>
                    <TransformComponent>{renderMap(false)}</TransformComponent>
                  </React.Fragment>
                )}
              </TransformWrapper>
            </div>
            <Dialog
              onClose={handleBarnDialogClose}
              aria-labelledby="barn-dialog-title"
              open={isBarnDialogOpen}
              disablePortal
              classes={{
                root: classes.barnDialogRoot
              }}
              BackdropProps={{
                classes: { root: classes.barnDialogBackdrop }
              }}
              PaperProps={{
                classes: { root: classes.barnDialogPaper }
              }}
              style={{ position: "absolute" }}
            >
              <DialogTitle
                id="barn-dialog-title"
                className={classes.barnDialogTitle}
              >
                Barn {activeBarn}
                <IconButton
                  color={"primary"}
                  size={"small"}
                  onClick={handleBarnDialogClose}
                >
                  <Close />
                </IconButton>
              </DialogTitle>
              <DialogContent dividers className={classes.barnDialogContent}>
                <Table stickyHeader size={"medium"}>
                  <TableHead>
                    <TableRow className={classes.barnDialogRow}>
                      <TableCell className={classes.bold}>Horse</TableCell>
                      <TableCell align={"center"} className={classes.bold}>
                        Stall
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {horses
                      .filter(horse => horse.barn?.name === activeBarn)
                      .map(horse => (
                        <TableRow
                          key={horse.id}
                          className={classes.barnDialogRow}
                        >
                          <TableCell>{horse.name}</TableCell>
                          <TableCell align={"center"}>
                            {horse.stall?.name || "-"}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </DialogContent>
            </Dialog>
          </div>
        ) : (
          <Loader />
        )}
      </Box>
    </Box>
  );
};

export default Map;
