import React from "react";
import clsx from "clsx";

import { racehorse360 } from "@tsg/1st-grpc-web";

import ActionCellCheckbox from "../ActionCellCheckbox";
import { useLoggedInUser } from "components/LoggedInUserProvider";
import {
  withClickBlocker,
  WithClickBlockerProps
} from "components/BlockableClickContext";

import useStyles from "./styles";

interface IProps {
  examStatus: racehorse360.HorseExamStatus;
  isAssignChecked: boolean;
  assignedUserId: string;
  isLoading: boolean;
  onAssignChange: (value: boolean) => void;
}

const AssignActionCell: React.FC<IProps & WithClickBlockerProps> = props => {
  const {
    examStatus,
    isAssignChecked,
    assignedUserId,
    isLoading,
    onAssignChange,
    handleBlockableClick
  } = props;

  const classes = useStyles();
  const {
    currentUser: { isTrackVet, isChiefVet, isSeniorVet, rh360Id }
  } = useLoggedInUser();

  const isChiefOrSeniorVet = [isChiefVet, isSeniorVet].some(Boolean);
  const isAssignedToCurrentUser = rh360Id === assignedUserId;
  const isExamStatusRequested =
    examStatus === racehorse360.HorseExamStatus.HORSE_EXAM_STATUS_REQUESTED;

  const isCheckBoxDisabled =
    (!isTrackVet || !isExamStatusRequested || isAssignedToCurrentUser) &&
    !isChiefOrSeniorVet;

  const handleAssignCheckChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    handleBlockableClick(() => {
      const checked = event.target.checked;

      onAssignChange(checked);
    });
  };

  return (
    <ActionCellCheckbox
      className={clsx({ [classes.assignCheckboxChecked]: isAssignChecked })}
      checked={isAssignChecked}
      isLoading={isLoading}
      disabled={isCheckBoxDisabled}
      onChange={handleAssignCheckChange}
      dataTestId={"assignCheckbox"}
    />
  );
};

export default withClickBlocker<IProps>(React.memo(AssignActionCell));
