import React, { useCallback } from "react";
import clsx from "clsx";
import { shallowEqual, useDispatch } from "react-redux";

import { racehorse360 } from "@tsg/1st-grpc-web";

import { useAppSelector } from "hooks/redux/useAppSelector";
import {
  setShowAssignedToMeOnly,
  setSearchValue,
  setSelectedPreRaceFacility
} from "store/actions/preRaceScreeningPage";
import ErrorBoundary from "components/ErrorBoundary";
import AppSearch from "components/AppSearch/AppSearch";
import FacilitySelect from "components/FacilitySelect";
import {
  withClickBlocker,
  WithClickBlockerProps
} from "components/BlockableClickContext";
import AssignedToMeToggle from "components/VetExamsAssignment/AssignedToMeToggle";
import PickerFilter from "./PickerFilter";
import { checkCurrentRaceEntriesTableTab } from "../helper";

import useStyles from "./styles";

interface IProps {
  upcomingRaceEntriesQuery: racehorse360.IUpcomingRaceEntryFilter;
}

const PreRaceScreeningPageHeader: React.FC<
  IProps & WithClickBlockerProps
> = props => {
  const { upcomingRaceEntriesQuery, handleBlockableClick } = props;

  const classes = useStyles();
  const dispatch = useDispatch();

  const { searchValue, selectedFacility, selectedTab, showAssignedToMeOnly } =
    useAppSelector(
      state => ({
        searchValue: state.preRaceScreeningPage.searchValue,
        selectedFacility: state.preRaceScreeningPage.selectedFacility,
        selectedTab: state.preRaceScreeningPage.selectedTab,
        showAssignedToMeOnly: state.preRaceScreeningPage.showAssignedToMeOnly
      }),
      shallowEqual
    );

  const { isEntryExamsTab, isRaceDayTab } = checkCurrentRaceEntriesTableTab(
    selectedTab.value
  );

  const handleFacilityChange = useCallback(
    (facility: racehorse360.IFacility) => {
      dispatch(setSelectedPreRaceFacility(facility));
    },
    [dispatch]
  );

  const handleAssignedToMeChange = useCallback(() => {
    dispatch(setShowAssignedToMeOnly(!showAssignedToMeOnly));
  }, [dispatch, showAssignedToMeOnly]);

  const handleSearch = (value: string) => {
    handleBlockableClick(() => dispatch(setSearchValue(value)));
  };

  return (
    <div
      className={clsx(classes.pageHeader, {
        raceDayTab: !isEntryExamsTab
      })}
    >
      <div className={classes.firstLevelFilters}>
        <div className={classes.facility}>
          <ErrorBoundary>
            <FacilitySelect
              selectedFacility={selectedFacility}
              onChange={handleFacilityChange}
            />
          </ErrorBoundary>
        </div>
        {(isEntryExamsTab || isRaceDayTab) && (
          <div className={classes.assignedToMe}>
            <AssignedToMeToggle
              isChecked={showAssignedToMeOnly}
              onChange={handleAssignedToMeChange}
            />
          </div>
        )}
      </div>

      <div className={classes.secondLevelFilters}>
        <div className={classes.searchFilter}>
          <ErrorBoundary>
            <AppSearch
              className={classes.search}
              isForceSearch
              placeholder={"Search Horses or Trainers"}
              upcomingRaceEntriesRequestFilter={upcomingRaceEntriesQuery}
              value={searchValue}
              onSearch={handleSearch}
              clearSearch={Boolean(!searchValue)}
            />
          </ErrorBoundary>
        </div>

        <PickerFilter className={classes.pickerFilterDesktop} />
      </div>

      <div className={classes.thirdLevelFilters}>
        <PickerFilter />
      </div>
    </div>
  );
};

export default withClickBlocker<IProps>(React.memo(PreRaceScreeningPageHeader));
