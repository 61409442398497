import React, { HTMLAttributes } from "react";
import clsx from "clsx";

import IconButton from "@material-ui/core/IconButton";

import DefaultBarnEditIcon from "components/Icons/Edit";
import MapBarnEditIcon from "components/Icons/MapEdit";

import useStyles from "./styles";

interface IProps extends HTMLAttributes<HTMLButtonElement> {
  variant?: "default" | "map";
}

const EditButton = (props: IProps) => {
  const { onClick, className, variant = "default" } = props;

  const classes = useStyles();

  const icon =
    variant === "map" ? (
      <MapBarnEditIcon className={classes.icon} />
    ) : (
      <DefaultBarnEditIcon className={classes.icon} />
    );

  return (
    <IconButton className={clsx(className, classes.button)} onClick={onClick}>
      {icon}
    </IconButton>
  );
};

export default React.memo(EditButton);
