import { makeStyles } from "@material-ui/core/styles";
import { MuiTheme } from "theme";

export default makeStyles((theme: MuiTheme) => {
  const { primary } = theme.palette;

  return {
    trainerName: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: "100%"
    },
    trainerComment: {
      width: "14px",
      height: "16px",
      marginLeft: "6px",
      "&:hover": {
        cursor: "pointer"
      }
    },
    wrReqCommentsList: {
      maxHeight: 440,
      overflow: "auto"
    },
    wrReqCommentsContainer: {
      backgroundColor: "white",
      color: "black",
      borderRadius: 2,
      width: 320
    },
    wrReqCommentsListItem: {
      padding: "12px 16px",
      borderBottom: "1px solid #e6e6e6",
      "&:last-child": {
        borderBottom: "none"
      }
    },
    wrReqCommentsContainerHeader: {
      marginBottom: 8,
      fontSize: 13
    },
    wrReqCommentsContainerBody: {
      fontSize: 12
    },
    commentCreator: {
      fontWeight: 600,
      fontSize: 13
    },
    createDateTime: {
      color: primary.dark,
      fontSize: 13
    },
    rowOdd: {
      backgroundColor: "#F6F6F6"
    },
    menuButtonExamChecked: {
      backgroundColor: "rgba(240, 227, 191, 1.0)",
      "&:hover": {
        backgroundColor: "rgba(240, 227, 191, 1.0)"
      }
    },
    menuButtonPassChecked: {
      backgroundColor: "rgba(3, 243, 178, 1.0)",
      "&:hover": {
        backgroundColor: "rgba(3, 243, 178, 1.0)"
      }
    },
    menuButtonAssignChecked: {
      backgroundColor: "rgba(3, 243, 178, 1.0)",
      "&:hover": {
        backgroundColor: "rgba(3, 243, 178, 1.0)"
      }
    },
    rowExamChecked: {
      backgroundColor: "rgba(252, 249, 242, 1.0)",
      "& label.MuiFormLabel-root.MuiFormLabel-root": {
        backgroundColor: "rgba(252, 249, 242, 1.0)"
      }
    },
    rowPassChecked: {
      backgroundColor: "rgba(205, 253, 240, 1.0)",
      "& label.MuiFormLabel-root.MuiFormLabel-root": {
        backgroundColor: "rgba(205, 253, 240, 1.0)"
      }
    },
    rowAssignChecked: {
      backgroundColor: "rgba(205, 253, 240, 1.0)",
      "& label.MuiFormLabel-root.MuiFormLabel-root": {
        backgroundColor: "rgba(205, 253, 240, 1.0)"
      }
    },
    rowRejected: {
      backgroundColor: "#ffdbdc"
    },
    unassign: {
      color: "#f73d54"
    },
    activeVetLists: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    risksHistoryButton: {
      textDecoration: "underline",
      cursor: "pointer",
      color: theme.palette.warning.dark,
      width: 30,
      height: 30,
      minWidth: 30,
      padding: 0,
      borderRadius: "50%",
      "&:hover": {
        textDecoration: "underline",
        cursor: "pointer"
      }
    },
    rowHeader: {
      display: "flex",
      alignItems: "center",
      height: 32
    }
  };
});
