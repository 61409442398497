import React from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";

import { EEventType, IExamsTableRowData } from "components/VetExamsTable/types";
import { transformViewDate } from "utils/date-utils";

import useStyles from "./styles";

interface Props {
  content?: string;
  title?: string;
  rowData: IExamsTableRowData;
  onClose: () => void;
  onConfirm: () => void;
}

const UnAssignConfirmPopUp = (props: Props) => {
  const { onClose, onConfirm, title, content, rowData } = props;
  const { eventId, eventType, eventDate, horse } = rowData;

  const classes = useStyles();

  return (
    <Dialog onClose={onClose} className={classes.dialog} open={true}>
      <MuiDialogTitle className={classes.header}>
        <span className={classes.title}>{title}</span>
      </MuiDialogTitle>

      <MuiDialogContent className={classes.content}>
        <div className={classes.description}>{content}</div>

        {eventId && (
          <>
            <Typography className={classes.itemTitle}>Horse Name</Typography>
            <Typography className={classes.itemValue}>{horse?.name}</Typography>
            <Typography className={classes.itemTitle}>
              {`${eventType} Date`}
            </Typography>
            <Typography className={classes.itemValue}>
              {transformViewDate(eventDate, null, "M/d/yyyy")}
            </Typography>
          </>
        )}
      </MuiDialogContent>
      <MuiDialogActions className={classes.actions}>
        <Button onClick={onClose} className={classes.buttonClose}>
          CANCEL
        </Button>
        <Button onClick={onConfirm} className={classes.buttonConfirm}>
          CONFIRM
        </Button>
      </MuiDialogActions>
    </Dialog>
  );
};

export default React.memo(UnAssignConfirmPopUp);
