import React from "react";
import { shallowEqual, useDispatch } from "react-redux";

import { useAppSelector } from "hooks/redux/useAppSelector";
import ActionColumnHeaders from "components/VetExamsTable/TableHeader/ActionColumnHeaders/ActionColumnHeaders";
import TableHeader from "components/VetExamsTable/TableHeader";
import { getSortDirection } from "components/VetExamsTable/TableHeader/helper";
import { ITableHeaderColumnsContent } from "components/VetExamsTable/types";
import { SortOrder } from "interfaces/SortOrder";
import { setOrderBy, setSortOrder } from "store/actions/preRaceScreeningPage";
import { EPreRaceTableColumn, EPreRaceTableColumnSorting } from "../../types";
import { checkCurrentRaceEntriesTableTab } from "../../helper";

interface IProps {
  columnsClassName: string;
  columns: EPreRaceTableColumn[];
  refElement: HTMLElement;
}

const ENTRIES_TAB_ACTION_COLUMNS = ["Exam", "Pass"];
const ASSIGN_ACTION_COLUMNS = ["Assign"];

const PreRacesTableHeader = (props: IProps) => {
  const { columns, columnsClassName, refElement } = props;

  const dispatch = useDispatch();

  const { selectedTab, orderBy, order } = useAppSelector(
    state => ({
      selectedTab: state.preRaceScreeningPage.selectedTab,
      orderBy: state.preRaceScreeningPage.orderBy,
      order: state.preRaceScreeningPage.order
    }),
    shallowEqual
  );

  const { isEntriesTab, isEntryExamsTab, isResultsTab, isRaceDayTab } =
    checkCurrentRaceEntriesTableTab(selectedTab.value);

  const shouldShowAssignElements = [isEntryExamsTab, isRaceDayTab].some(
    Boolean
  );

  const handleSort = (
    columnName: EPreRaceTableColumnSorting,
    sortDirection: SortOrder
  ) => {
    dispatch(setSortOrder(sortDirection));
    dispatch(setOrderBy(columnName));
  };

  const tableHeaderColumnsContent: ITableHeaderColumnsContent = {
    [EPreRaceTableColumn.TRAINER]: [
      {
        title: "Horse",
        isDisplayed: true,
        sortableCell: {
          name: EPreRaceTableColumnSorting.HORSE_NAME,
          sortOrder: getSortDirection(
            EPreRaceTableColumnSorting.HORSE_NAME,
            orderBy,
            order
          ),
          sortHandler: handleSort
        }
      },
      {
        title: "Trainer",
        isDisplayed: true,
        sortableCell: {
          name: EPreRaceTableColumnSorting.TRAINER_LAST_NAME,
          sortOrder: getSortDirection(
            EPreRaceTableColumnSorting.TRAINER_LAST_NAME,
            orderBy,
            order
          ),
          sortHandler: handleSort
        }
      }
    ],
    [EPreRaceTableColumn.RACE_DATE]: {
      title: "Race Date",
      isDisplayed: true,
      centered: true,
      sortableCell: {
        name: EPreRaceTableColumnSorting.RACE_DATE,
        sortOrder: getSortDirection(
          EPreRaceTableColumnSorting.RACE_DATE,
          orderBy,
          order
        ),
        sortHandler: handleSort
      }
    },
    [EPreRaceTableColumn.RACE_NUMBER]: {
      title: "Race #",
      isDisplayed: true,
      centered: true,
      sortableCell: {
        name: EPreRaceTableColumnSorting.RACE_NUMBER,
        sortOrder: getSortDirection(
          EPreRaceTableColumnSorting.RACE_NUMBER,
          orderBy,
          order
        ),
        sortHandler: handleSort
      }
    },
    [EPreRaceTableColumn.RACE_POSITION]: {
      title: "Position #",
      isDisplayed: true,
      centered: true
    },
    [EPreRaceTableColumn.WATCH_LIST]: {
      title: "Watch List",
      isDisplayed: !isResultsTab,
      sortableCell: {
        name: EPreRaceTableColumnSorting.IS_FLAGGED,
        sortOrder: getSortDirection(
          EPreRaceTableColumnSorting.IS_FLAGGED,
          orderBy,
          order
        ),
        sortHandler: handleSort
      }
    },
    [EPreRaceTableColumn.RISK_LEVEL]: {
      title: "Risk Level",
      isDisplayed: true,
      centered: true,
      sortableCell: {
        name: EPreRaceTableColumnSorting.RISK_LEVEL,
        sortOrder: getSortDirection(
          EPreRaceTableColumnSorting.RISK_LEVEL,
          orderBy,
          order
        ),
        sortHandler: handleSort
      }
    },
    [EPreRaceTableColumn.BARN]: {
      title: "Barn #",
      isDisplayed: true,
      centered: true,
      sortableCell: {
        name: EPreRaceTableColumnSorting.BARN_NAME,
        sortOrder: getSortDirection(
          EPreRaceTableColumnSorting.BARN_NAME,
          orderBy,
          order
        ),
        sortHandler: handleSort
      }
    },
    [EPreRaceTableColumn.CSA]: {
      title: "C.S.A",
      isDisplayed: true,
      centered: true
    },
    [EPreRaceTableColumn.HSF]: {
      title: "HSF",
      isDisplayed: true,
      centered: true,
      sortableCell: {
        name: EPreRaceTableColumnSorting.HIGH_SPEED_FURLONGS,
        sortOrder: getSortDirection(
          EPreRaceTableColumnSorting.HIGH_SPEED_FURLONGS,
          orderBy,
          order
        ),
        sortHandler: handleSort
      }
    },
    [EPreRaceTableColumn.DLW]: {
      title: "DLW",
      isDisplayed: true,
      centered: true,
      sortableCell: {
        name: EPreRaceTableColumnSorting.LAST_WORKOUT_DATE,
        sortOrder: getSortDirection(
          EPreRaceTableColumnSorting.LAST_WORKOUT_DATE,
          orderBy,
          order
        ),
        sortHandler: handleSort
      }
    },
    [EPreRaceTableColumn.DLR]: {
      title: "DLR",
      isDisplayed: true,
      centered: true,
      sortableCell: {
        name: EPreRaceTableColumnSorting.LAST_RACE_DATE,
        sortOrder: getSortDirection(
          EPreRaceTableColumnSorting.LAST_RACE_DATE,
          orderBy,
          order
        ),
        sortHandler: handleSort
      }
    },
    [EPreRaceTableColumn.ACTIVE_VET_LISTS]: {
      title: "Vet Lists",
      isDisplayed: true,
      centered: true,
      sortableCell: {
        name: EPreRaceTableColumnSorting.ACTIVE_VET_LISTS_COUNT,
        sortOrder: getSortDirection(
          EPreRaceTableColumnSorting.ACTIVE_VET_LISTS_COUNT,
          orderBy,
          order
        ),
        sortHandler: handleSort
      }
    },
    [EPreRaceTableColumn.RISK_FACTORS]: {
      title: "Risks",
      isDisplayed: !isResultsTab,
      sortableCell: {
        name: EPreRaceTableColumnSorting.RISK_FACTORS_COUNT,
        sortOrder: getSortDirection(
          EPreRaceTableColumnSorting.RISK_FACTORS_COUNT,
          orderBy,
          order
        ),
        sortHandler: handleSort
      }
    },
    [EPreRaceTableColumn.ASSIGNED_VET]: {
      title: "Assigned Vet",
      isDisplayed: shouldShowAssignElements
    },
    [EPreRaceTableColumn.UN_ASSIGN]: {
      title: "Un-Assign",
      isDisplayed: shouldShowAssignElements,
      centered: true
    },
    [EPreRaceTableColumn.EXAM]: {
      title: "Exam",
      isDisplayed: shouldShowAssignElements,
      centered: true
    },
    [EPreRaceTableColumn.ENTRY_REVIEW]: {
      title: "Entry Review",
      isDisplayed: isResultsTab || isRaceDayTab
    },
    [EPreRaceTableColumn.EXTENDER]: {
      title: " ",
      isDisplayed: true,
      lastTwoColumnsCell: isEntriesTab,
      lastOneColumnCell: shouldShowAssignElements
    }
  };

  return (
    <>
      {isEntriesTab && (
        <ActionColumnHeaders
          columns={ENTRIES_TAB_ACTION_COLUMNS}
          refElement={refElement}
        />
      )}
      {shouldShowAssignElements && (
        <ActionColumnHeaders
          columns={ASSIGN_ACTION_COLUMNS}
          refElement={refElement}
        />
      )}

      <TableHeader
        tabClassName={columnsClassName}
        columns={columns}
        columnsContent={tableHeaderColumnsContent}
      />
    </>
  );
};

export default React.memo(PreRacesTableHeader);
