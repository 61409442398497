import { useEffect, useState } from "react";

export const useScrollBarWidth = (element: HTMLElement): number => {
  const [scrollBarWidth, setScrollBarWidth] = useState(0);

  useEffect(() => {
    if (!element) return;

    const setTimeoutId = setTimeout(() => {
      setScrollBarWidth(element.offsetWidth - element.clientWidth);
    }, 0);

    return () => {
      setTimeoutId && clearTimeout(setTimeoutId);
    };
  }, [element]);

  return scrollBarWidth || 0;
};
