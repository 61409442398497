import React, { useState } from "react";
import clsx from "clsx";

import { Theme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import ListItem from "@material-ui/core/ListItem";
import DoneIcon from "@material-ui/icons/Done";
import List from "@material-ui/core/List";

import { racehorse360 } from "@tsg/1st-grpc-web";

import { useLoggedInUser } from "components/LoggedInUserProvider";
import ErrorBoundary from "components/ErrorBoundary";
import Loader from "components/Loader";
import { useRacehorse360Api } from "hooks/api";

import useStyles from "./styles";

interface Props {
  open: boolean;
  onClose: () => void;
  onConfirm: (id: string) => void;
  selectedItemsAmount: number;
  selectedFacilityId: string;
}

const ConfirmAssignmentDialog = (props: Props) => {
  const { open, onClose, onConfirm, selectedItemsAmount, selectedFacilityId } =
    props;

  const classes = useStyles();
  const {
    currentUser: { isTrackVet, rh360Id }
  } = useLoggedInUser();
  const isScreenSM = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up("sm")
  );

  const [assignVetId, setAssignVetId] = useState<string>(null);

  const { useListUsers } = useRacehorse360Api();

  const shouldMakeListUsersRequest = [
    selectedFacilityId,
    selectedItemsAmount > 0,
    !isTrackVet,
    open
  ].every(Boolean);

  const { data: veterinariansData, isLoading } = useListUsers(
    `${selectedFacilityId}_veterinarians`,
    {
      query: {
        facilityIds: [selectedFacilityId],
        roleGroup: racehorse360.RoleGroup.ROLE_GROUP_VETERINARIANS
      },
      getOptions: {
        select: ["firstName", "lastName"]
      }
    },
    {
      enabled: shouldMakeListUsersRequest
    }
  );

  const veterinarians: racehorse360.IUser[] = veterinariansData?.users || [];

  const renderCommonAssigmentButtons = () => {
    let output;

    if (isScreenSM) {
      output = (
        <div className={classes.confirmationButtons}>
          <Button onClick={handleGoBack}>Go back</Button>
          <Button
            className={classes.confirmationButtonsConfirm}
            disabled={!assignVetId}
            onClick={handleConfirmAssign}
          >
            Confirm
          </Button>
        </div>
      );
    } else {
      output = (
        <div className={classes.confirmAssignmentContainer}>
          <Button
            className={classes.confirmAssignment}
            disabled={!assignVetId}
            onClick={handleConfirmAssign}
          >
            Confirm assignment
            <span className={classes.confirmAssignmentAmount}>
              ({selectedItemsAmount}) Exams
            </span>
          </Button>
        </div>
      );
    }

    return output;
  };

  const handleSelect = (vetId: string) => () => {
    setAssignVetId(vetId);
  };

  const handleGoBack = () => {
    setAssignVetId(null);

    if (onClose) {
      onClose();
    }
  };

  const handleConfirmAssign = () => {
    setAssignVetId(null);

    if (onConfirm) {
      onConfirm(assignVetId);
    }
  };

  const handleAssignToMe = () => {
    onConfirm(rh360Id);
  };

  const renderDialogTitle = () => {
    if (isTrackVet) {
      return (
        <div className={classes.assignToMeConfirmationHeaderTitle}>
          Confirm Assignment
        </div>
      );
    }

    return (
      <div className={classes.confirmationHeader}>
        {!isScreenSM && (
          <Button className={classes.closeAssignment} onClick={onClose}>
            Close
          </Button>
        )}
        <div className={classes.confirmationHeaderTitle}>
          Available Veterinarians
        </div>
        {isScreenSM && (
          <div className={classes.confirmationText}>
            Which vet would you like to assign to the&nbsp;
            <span className={classes.confirmationTextAmount}>
              ({selectedItemsAmount})
            </span>
            &nbsp;selected horses?
          </div>
        )}
      </div>
    );
  };

  const renderDialogContent = () => {
    if (isTrackVet) {
      return (
        <div className={classes.confirmationText}>
          <span className={classes.confirmationTextAmount}>
            ({selectedItemsAmount})
          </span>
          &nbsp;horses will be assigned to you for their vet examination.
        </div>
      );
    }

    return (
      <div className={classes.confirmationContainer}>
        <div className={classes.vetList}>
          <List disablePadding>
            {veterinarians.map((veterinarian, index) => {
              const veterinarianFullName = `${veterinarian.lastName}, ${veterinarian.firstName}`;
              const isChecked = veterinarian.id === assignVetId;

              return (
                <ListItem button className={classes.listItem} key={index}>
                  <Button
                    fullWidth
                    className={classes.listItemButton}
                    onClick={handleSelect(veterinarian.id)}
                  >
                    <span className={classes.listItemText}>
                      {veterinarianFullName}
                    </span>
                    <DoneIcon
                      className={clsx(classes.listItemIconCheck, {
                        [classes.listItemIconCheckSelected]: isChecked
                      })}
                    />
                  </Button>
                </ListItem>
              );
            })}
          </List>
        </div>
      </div>
    );
  };

  const renderDialogActions = () => {
    if (isTrackVet) {
      return (
        <div className={classes.assignToMeConfirmationButtons}>
          <Button className={classes.cancelButton} onClick={onClose}>
            Cancel
          </Button>
          <Button
            className={classes.confirmationButtonsConfirm}
            onClick={handleAssignToMe}
          >
            Confirm
          </Button>
        </div>
      );
    }

    return renderCommonAssigmentButtons();
  };

  const renderContent = () => {
    if (isLoading) return <Loader className={classes.assignLoader} />;

    return (
      <>
        <DialogTitle
          className={clsx(classes.dialogTitle, { assignToMeView: isTrackVet })}
          disableTypography
        >
          {renderDialogTitle()}
        </DialogTitle>

        <DialogContent
          className={clsx(classes.dialogContent, {
            assignToMeView: isTrackVet
          })}
        >
          {renderDialogContent()}
        </DialogContent>

        <DialogActions
          className={clsx(classes.dialogActions, {
            assignToMeView: isTrackVet
          })}
        >
          {renderDialogActions()}
        </DialogActions>
      </>
    );
  };

  return (
    <ErrorBoundary>
      <Dialog
        className={classes.dialog}
        classes={{
          paper: clsx(classes.dialogPaper, {
            assignToMeView: isTrackVet
          })
        }}
        open={open}
        onClose={onClose}
      >
        {renderContent()}
      </Dialog>
    </ErrorBoundary>
  );
};

export default ConfirmAssignmentDialog;
