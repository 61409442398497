import React from "react";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

import useStyles from "./styles";

export interface IProps {
  isChecked: boolean;
  onChange: () => void;
}

const AssignedToMeToggle = (props: IProps) => {
  const { isChecked, onChange } = props;

  const classes = useStyles();

  return (
    <FormControlLabel
      className={classes.root}
      control={
        <Switch size={"small"} checked={isChecked} onChange={onChange} />
      }
      label="Assigned to Me"
      labelPlacement="start"
    />
  );
};

export default React.memo(AssignedToMeToggle);
