import { makeStyles } from "@material-ui/core/styles";

export default makeStyles({
  barnNumberPicker: {
    padding: "0 12px"
  },
  bold: {
    fontWeight: "bold"
  }
});
