import React from "react";
import clsx from "clsx";

import Button, { ButtonProps } from "@material-ui/core/Button";

import { useLoggedInUser } from "components/LoggedInUserProvider";

import useStyles from "./styles";

const AssignToVetButton = (props: ButtonProps) => {
  const { className, children } = props;

  const classes = useStyles();
  const { currentUser } = useLoggedInUser();

  const defaultButtonText = currentUser.isTrackVet
    ? "Assign to me"
    : "Assign to vet";

  return (
    <Button {...props} className={clsx(className, classes.rootButton)}>
      {children || defaultButtonText}
    </Button>
  );
};

export default React.memo(AssignToVetButton);
