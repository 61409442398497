import React, { useCallback, useEffect, useRef, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";

import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { Theme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";

import { racehorse360 } from "@tsg/1st-grpc-web";

import NextScheduledWorkout from "components/NextScheduledWorkout";
import AppPage from "components/AppPage";
import AppPageHeader from "components/AppPageHeader";
import AppPageContent from "components/AppPageContent";
import DaysOff from "components/DaysOff";
import EIDData from "components/EIDData";
import ExamLockerContext from "components/ExamLockerContext";
import ErrorBoundary from "components/ErrorBoundary";
import FacilitySelect from "components/FacilitySelect";
import InfoPanelsGrid from "components/InfoPanelsGrid";
import List from "components/List";
import Loader from "components/Loader";
import { useLoggedInUser } from "components/LoggedInUserProvider";
import Map from "components/Map";
import TabTables from "components/TabTables";
import TabsComponent from "components/Tabs";
import VetExamOverlay from "components/VetExamOverlay";
import { setIsExamActive } from "store/actions/vetExam";
import TitledContainer from "components/TitledContainer";
import VetActivity from "components/VetActivity";
import { setMapPopupState } from "store/actions/vetHorseDetailsPage";
import ActivityComponentType from "interfaces/ActivityComponentType";
import { IVetHorseDetailsPageState } from "interfaces/VetHorseDetailsPageState";
import ITab from "interfaces/Tab";
import IColumn from "interfaces/Column";
import { IVetExamState } from "interfaces/VetExamState";
import routes from "common/routes";
import { useRacehorse360Api } from "hooks/api";
import { useInfiniteScroll } from "hooks/useInfiniteScroll";
import Information from "./Information";
import PastExams from "./PastExams";
import Header from "./Header";
import Notepad from "./Notepad";
import {
  currentTimeZoneString,
  isFutureDate,
  transformViewDate
} from "utils/date-utils";
import Vaccinations from "./Vaccinations";
import Comments from "./Comments";
import {
  getHorseQuery,
  getTabFromPath,
  HORSE_EXAM_OPTIONS,
  tabsList,
  TabValues,
  WORKOUT_REQUEST_OPTIONS
} from "./helper";
import { SortOrder } from "interfaces/SortOrder";

import useStyles from "./styles";

interface ILocationState {
  currentHorseExamId: string;
}

export const GET_HORSE_QUERY_PREFIX = "get-horse-";

const VetHorseDetailsPage = () => {
  const match = useRouteMatch<{ horseId: string }>(routes.horseDetails.path);
  const classes = useStyles();
  const matchesDownSm = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const matchesDownXs = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xs")
  );
  const dispatch = useDispatch();
  const history = useHistory<ILocationState>();
  const rootRef = useRef(null);
  const tableBodyRef = useRef(null);
  const { currentUser } = useLoggedInUser();
  const { isRegulatoryVet } = currentUser;

  const [tabs, setTabs] = useState<ITab[]>(tabsList);
  const [activeTab, setActiveTab] = useState<ITab>(
    getTabFromPath(history.location.pathname)
  );
  const [selectedMapFacility, setSelectedMapFacility] =
    useState<racehorse360.IFacility>(null);
  const [currentExam, setCurrentExam] = useState<racehorse360.IHorseExam>(null);
  const [commentsList, setCommentsList] = useState<
    racehorse360.IHorseAggregatedComment[]
  >([]);
  const [currentHorse, setCurrentHorse] = useState<racehorse360.IHorse>();

  const paramsHorseId = match.params.horseId;

  const { isMapFullscreen, isOpenExamForm } = useSelector(
    (state: {
      vetHorseDetailsPage: IVetHorseDetailsPageState;
      vetExam: IVetExamState;
    }) => ({
      isMapFullscreen: state.vetHorseDetailsPage.mapPopupState,
      isOpenExamForm: state.vetExam.isExamActive
    })
  );

  const isDetailsTab = activeTab?.value === TabValues.DETAILS;
  const isActivityTab = activeTab?.value === TabValues.ACTIVITY;
  const isCommentsTab = activeTab?.value === TabValues.COMMENTS;

  const horseOnListsColumns: IColumn[] = [
    {
      id: "ListType",
      title: "List Type",
      field: "listType",
      width: "35%"
    },
    {
      id: "Track",
      title: "Track",
      field: "facility.code",
      width: "15%"
    },
    {
      id: "DateOn",
      title: "Date On",
      field: "dateOn",
      width: "25%",
      renderer: val => (val ? transformViewDate(val) : "-")
    },
    {
      id: "DateOff",
      title: "Date Off",
      field: "dateOff",
      width: "25%",
      renderer: val =>
        val ? (
          <span className={clsx({ [classes.italicCell]: isFutureDate(val) })}>
            {transformViewDate(val)}
          </span>
        ) : (
          "-"
        )
    }
  ];

  const riskColumns: IColumn[] = [
    {
      id: "Risk",
      title: "Risk Factor",
      field: "riskFactor.description",
      width: "75%"
    },
    {
      id: "Identified",
      title: "Identified",
      field: "identifiedOn",
      width: "25%",
      renderer: val => (val ? transformViewDate(val) : "-")
    }
  ];

  const {
    useGetHorse,
    useListHorseExams,
    useCreateHorseExam,
    useListHorseOnLists,
    useListHorseRisks,
    useListWorkoutRequests,
    usePullHorseAggregatedComments,
    useInfinitePullHorseAggregatedComments,
    usePullRelevantHorseWarningMessage
  } = useRacehorse360Api();

  const {
    isLoading: isHorseLoading,
    isFetching: isHorseFetching,
    refetch: refetchHorse,
    data: horse
  } = useGetHorse(
    `${GET_HORSE_QUERY_PREFIX}${paramsHorseId}`,
    getHorseQuery(paramsHorseId),
    {
      onSuccess: data => {
        const hasRiskFactors = Boolean(data.riskFactorsCount);
        const newTabs = tabs.map(tab => {
          const newTab = {
            ...tab,
            path: tab.path.replace(":horseId", match.params.horseId)
          };

          return tab.value === TabValues.ACTIVITY
            ? { ...newTab, isUpdated: hasRiskFactors }
            : newTab;
        });

        setTabs(newTabs);
      }
    }
  );

  const horseFacility =
    currentHorse?.barn?.facility || currentHorse?.currentFacility;

  const { mutateAsync: createHorseExam } = useCreateHorseExam();

  const { data: workoutRequestsData, isLoading: isWorkoutRequestLoading } =
    useListWorkoutRequests(
      {
        query: {
          horseIds: currentHorse?.id ? [currentHorse?.id] : [],
          date: {
            range: {
              relative: {
                // Today and later
                startDate: { value: 0 },
                timezone: currentTimeZoneString()
              }
            }
          },
          statuses: [
            racehorse360.WorkoutRequestStatus.WORKOUT_REQUEST_STATUS_REQUESTED,
            racehorse360.WorkoutRequestStatus
              .WORKOUT_REQUEST_STATUS_EXAM_PENDING,
            racehorse360.WorkoutRequestStatus.WORKOUT_REQUEST_STATUS_APPROVED
          ]
        },
        pagingOptions: {
          maxResults: 1
        },
        getOptions: {
          select: WORKOUT_REQUEST_OPTIONS,
          orderBy: [`date ${SortOrder.ASC}`]
        }
      },
      {
        enabled: Boolean(currentHorse)
      }
    );
  const workoutRequests: racehorse360.IWorkoutRequest[] =
    workoutRequestsData?.workoutRequests || [];
  const workoutRequest = workoutRequests[0];

  const {
    isLoading: isExamsLoading,
    isFetching: isExamsFetching,
    refetch: refetchHorseExams,
    data: examsData
  } = useListHorseExams(
    {
      query: {
        horseIds: [currentHorse?.id],
        assignedVetIds: [currentUser.rh360Id],
        facilityIds: [horseFacility?.id],
        statuses: [racehorse360.HorseExamStatus.HORSE_EXAM_STATUS_IN_PROGRESS],
        horseExamTypes: [racehorse360.HorseExamType.HORSE_EXAM_TYPE_FULL_EXAM],
        withWorkout: { value: false },
        withUpcomingRaceEntry: { value: false }
      },
      getOptions: {
        select: HORSE_EXAM_OPTIONS,
        orderBy: [`updatedOn ${SortOrder.DESC}`]
      }
    },
    {
      enabled: Boolean(currentHorse && horseFacility)
    }
  );
  const existingExam = examsData?.horseExams.filter(
    exam =>
      exam.status === racehorse360.HorseExamStatus.HORSE_EXAM_STATUS_IN_PROGRESS
  )[0];

  const pastExamsQuery = {
    horseIds: [currentHorse?.id],
    statuses: [racehorse360.HorseExamStatus.HORSE_EXAM_STATUS_COMPLETED],
    horseExamTypes: [racehorse360.HorseExamType.HORSE_EXAM_TYPE_FULL_EXAM]
  };

  const {
    isLoading: isPastExamsLoading,
    isFetching: isPastExamsFetching,
    refetch: refetchPastExams,
    data: pastExamsData
  } = useListHorseExams(
    {
      query: pastExamsQuery,
      getOptions: {
        select: HORSE_EXAM_OPTIONS,
        orderBy: [`completedDate ${SortOrder.DESC}`]
      }
    },
    {
      enabled: Boolean(currentHorse)
    }
  );
  const completedExams = pastExamsData?.horseExams || [];

  const { refetch: refetchComments, isFetching: isCommentsFetching } =
    usePullHorseAggregatedComments(
      {
        query: {
          horseId: currentHorse?.id
        }
      },
      {
        enabled: Boolean(currentHorse?.id) && !matchesDownSm,
        onSuccess: data => {
          setCommentsList(data.horseAggregatedComments);
        }
      }
    );

  const {
    isFetching: isInfiniteCommentsFetching,
    fetchNextPage: fetchNextCommentsPage,
    isFetchingNextPage: isFetchingNextCommentsPage,
    hasNextPage: hasNextCommentsPage,
    refetch: refetchInfiniteComments
  } = useInfinitePullHorseAggregatedComments(
    {
      query: {
        horseId: currentHorse?.id
      },
      pagingOptions: {
        maxResults: 10
      }
    },
    {
      enabled: Boolean(currentHorse?.id) && matchesDownSm,
      onSuccess: data => {
        setCommentsList(data.pages.flat());
      }
    }
  );

  useInfiniteScroll(
    tableBodyRef,
    fetchNextCommentsPage,
    hasNextCommentsPage,
    isFetchingNextCommentsPage,
    Boolean(currentHorse?.id) && matchesDownSm,
    false
  );

  const {
    data: horseWarningMessageData,
    isFetching: isRelevantHorseWarningMessageFetching,
    refetch: refetchHorseWarningMessage
  } = usePullRelevantHorseWarningMessage(
    {
      horseId: currentHorse?.id
    },
    {
      enabled: Boolean(currentHorse?.id)
    }
  );
  const warningMessage: racehorse360.IRelevantHorseWarningMessage =
    horseWarningMessageData?.relevantHorseWarningMessage;

  const refetchData = useCallback(() => {
    return Promise.all([
      refetchHorseExams(),
      refetchPastExams(),
      refetchComments(),
      refetchHorse()
    ]);
  }, [refetchHorseExams, refetchPastExams, refetchComments, refetchHorse]);

  const handleOpenExamPage = () => {
    dispatch(setIsExamActive(true));

    if (existingExam) {
      setCurrentExam(existingExam);
    } else {
      createHorseExam({
        facilityId: horseFacility?.id,
        horseId: currentHorse?.id
      }).then(exam => setCurrentExam(exam));
    }
  };

  const handleExamClose = useCallback(() => {
    dispatch(setIsExamActive(false));
    setCurrentExam(null);
  }, [dispatch]);

  const handleTabChange = (tab: ITab) => {
    setActiveTab(tab);
  };

  const handleMapOpen = () => {
    dispatch(setMapPopupState(true));
  };

  const handleMapClose = () => {
    setSelectedMapFacility(null);
    dispatch(setMapPopupState(false));
  };

  const handleChangeFacility = (facility: racehorse360.IFacility) => {
    setSelectedMapFacility(facility);
  };

  const handleBarnChange = (barn: racehorse360.IBarn) => {
    setCurrentHorse({ ...currentHorse, barn });
  };

  const handleBarnChangeOnMap = (barn: racehorse360.IBarn, horseId: string) => {
    if (currentHorse.id === horseId) {
      handleBarnChange(barn);
    }
  };

  useEffect(() => {
    const list = tabs.map(item => ({
      ...item,
      path: item.path.replace(":horseId", paramsHorseId)
    }));

    setTabs(list);

    return () => {
      dispatch(setIsExamActive(false));
      dispatch(setMapPopupState(false));
    };
  }, []);

  const renderListActivity = (
    tabName: string,
    component: ActivityComponentType
  ) => (
    <List<
      racehorse360.IHorse,
      racehorse360.IHorseOnList,
      racehorse360.IListHorseOnListsRequest,
      racehorse360.IListHorseOnListsResponse
    >
      entity={currentHorse}
      apiListHook={useListHorseOnLists}
      query={{ horseId: { value: currentHorse?.id } }}
      getOptions={{
        select: [
          "list_type",
          "facility.code",
          "date_on",
          "date_off",
          "reason",
          "comment"
        ],
        orderBy: [`dateOff ${SortOrder.DESC}`, `dateOn ${SortOrder.DESC}`]
      }}
      listPropName="horseOnLists"
      tabName={tabName}
      columns={horseOnListsColumns}
      rootRef={rootRef}
      component={component}
      isAlertItemPredicate={(item: racehorse360.IHorseOnList) =>
        !item.dateOff || isFutureDate(item.dateOff)
      }
      commentsSource={["reason", "comment"]}
    />
  );

  const renderRisks = (tabName: string, component: ActivityComponentType) => (
    <List<
      racehorse360.IHorse,
      racehorse360.IHorseRisk,
      racehorse360.IListHorseRisksRequest,
      racehorse360.IListHorseRisksResponse
    >
      entity={currentHorse}
      apiListHook={useListHorseRisks}
      query={{ horseIds: [currentHorse?.id] }}
      getOptions={{
        select: ["risk_factor.description", "identified_on"]
      }}
      listPropName="horseRisks"
      tabName={tabName}
      columns={riskColumns}
      rootRef={rootRef}
      component={component}
      isAlertItemPredicate={() => false}
    />
  );

  const renderUpSM = () => (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography className={classes.blockTitle}>
              Identification Details
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <ErrorBoundary>
              <InfoPanelsGrid horse={currentHorse} />
            </ErrorBoundary>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={4}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography className={classes.blockTitle}>
              Activity Status
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2} wrap={"nowrap"}>
              <Grid
                item
                xs={12}
                className={classes.nextScheduledWorkoutWrapper}
              >
                <ErrorBoundary>
                  <NextScheduledWorkout
                    workoutRequest={workoutRequest}
                    isWorkoutRequestLoading={isWorkoutRequestLoading}
                  />
                </ErrorBoundary>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={2}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography className={classes.blockTitle}>Days Off</Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2} wrap={"nowrap"}>
              <Grid
                item
                xs={12}
                className={classes.nextScheduledWorkoutWrapper}
              >
                <ErrorBoundary>
                  <DaysOff
                    className={classes.daysOff}
                    horse={currentHorse}
                    showViewHorseButton={false}
                  />
                </ErrorBoundary>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={6}>
        <Grid container spacing={2}>
          <Grid item md={12}>
            <ErrorBoundary>
              <VetActivity horse={currentHorse} rootRef={rootRef} />
            </ErrorBoundary>
          </Grid>
          <Grid item md={12}>
            <Grid container spacing={2}>
              <Grid item md={12}>
                <ErrorBoundary>
                  <Information
                    horse={currentHorse}
                    isLoading={isHorseDataLoading}
                    refetchComments={refetchComments}
                    onBarnChange={handleBarnChange}
                  />
                </ErrorBoundary>
              </Grid>
              {!isRegulatoryVet && (
                <Grid item md={12}>
                  <ErrorBoundary>
                    <Notepad horseId={currentHorse.id} />
                  </ErrorBoundary>
                </Grid>
              )}
              <Grid item md={12}>
                <ErrorBoundary>
                  <Comments
                    horse={currentHorse}
                    commentsList={commentsList}
                    refetchHorseWarningMessage={refetchHorseWarningMessage}
                    refetchComments={refetchComments}
                    isCommentsFetching={isCommentsFetching}
                  />
                </ErrorBoundary>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={6}>
        <Grid container spacing={2}>
          <Grid item md={12}>
            <ErrorBoundary>
              <TabTables
                defaultOpen={1}
                tabs={[
                  {
                    name: "EID Data",
                    component: <EIDData horse={currentHorse} />
                  },
                  {
                    name: "List Activity",
                    component: renderListActivity(
                      "List Activity",
                      ActivityComponentType.PAGINATED
                    )
                  },
                  {
                    name: "Risk Factors",
                    component: renderRisks(
                      "Risks",
                      ActivityComponentType.PAGINATED
                    )
                  }
                ]}
              />
            </ErrorBoundary>
          </Grid>
          <Grid item md={12}>
            <ErrorBoundary>
              <TabTables
                defaultOpen={0}
                tabs={[
                  {
                    name: "Past Exams",
                    component: (
                      <PastExams
                        exams={completedExams}
                        isExamsLoading={isPastExamsLoading}
                      />
                    )
                  },
                  {
                    name: "Vaccinations",
                    component: <Vaccinations horse={currentHorse} />
                  }
                ]}
              />
            </ErrorBoundary>
          </Grid>
          <Grid item md={12}>
            <ErrorBoundary>
              <Map
                currentHorse={currentHorse}
                onFullscreenClick={handleMapOpen}
                isPreview
                selectedFacility={selectedMapFacility || horseFacility}
                onBarnChange={handleBarnChangeOnMap}
              />
            </ErrorBoundary>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  const renderDownSM = () => (
    <Grid container spacing={2}>
      {isDetailsTab && renderMobileDetailsTabContent()}
      {isActivityTab && renderMobileActivityTabContent()}
      {isCommentsTab && renderMobileCommentsTabContent()}
    </Grid>
  );

  const renderMobileDetailsTabContent = () => (
    <>
      <Grid item xs={12}>
        <ErrorBoundary>
          <InfoPanelsGrid horse={currentHorse} />
        </ErrorBoundary>
      </Grid>
      <Grid item xs={12}>
        <ErrorBoundary>
          <Information
            horse={currentHorse}
            isLoading={isHorseDataLoading}
            refetchComments={refetchComments}
            onBarnChange={handleBarnChange}
          />
        </ErrorBoundary>
      </Grid>
      <Grid item xs={12}>
        <ErrorBoundary>
          <Map
            currentHorse={currentHorse}
            onFullscreenClick={handleMapOpen}
            isPreview
            selectedFacility={selectedMapFacility || horseFacility}
            onBarnChange={handleBarnChangeOnMap}
          />
        </ErrorBoundary>
      </Grid>
    </>
  );

  const renderMobileActivityTabContent = () => (
    <>
      <Grid item xs={12}>
        <ErrorBoundary>
          <TitledContainer title={"Race / Work Days Off"}>
            <DaysOff
              className={classes.daysOff}
              horse={currentHorse}
              showViewHorseButton={false}
            />
          </TitledContainer>
        </ErrorBoundary>
      </Grid>
      <Grid item xs={12}>
        <ErrorBoundary>
          <NextScheduledWorkout
            workoutRequest={workoutRequest}
            isWorkoutRequestLoading={isWorkoutRequestLoading}
          />
        </ErrorBoundary>
      </Grid>
      <Grid component={Typography} item xs={12} className={classes.blockTitle}>
        Workouts and PPs
      </Grid>
      <Grid item xs={12}>
        <ErrorBoundary>
          <VetActivity horse={currentHorse} rootRef={rootRef} />
        </ErrorBoundary>
      </Grid>
      <Grid item xs={12}>
        <ErrorBoundary>
          {renderListActivity("ListActivity", ActivityComponentType.SLIDER)}
        </ErrorBoundary>
      </Grid>
      <Grid item xs={12}>
        <ErrorBoundary>
          {renderRisks("Risk Factors", ActivityComponentType.SLIDER)}
        </ErrorBoundary>
      </Grid>
      <Grid item xs={12}>
        <ErrorBoundary>
          <EIDData horse={currentHorse} />
        </ErrorBoundary>
      </Grid>
      <Grid item xs={12}>
        <ErrorBoundary>
          <Vaccinations horse={currentHorse} />
        </ErrorBoundary>
      </Grid>
      <Grid item xs={12}>
        <ErrorBoundary>
          <PastExams
            exams={completedExams}
            isExamsLoading={isPastExamsLoading}
          />
        </ErrorBoundary>
      </Grid>
    </>
  );

  const renderMobileCommentsTabContent = () => (
    <>
      {!isRegulatoryVet && (
        <Grid item xs={12}>
          <ErrorBoundary>
            <Notepad horseId={currentHorse?.id} />
          </ErrorBoundary>
        </Grid>
      )}
      <Grid item xs={12}>
        <ErrorBoundary>
          <div ref={tableBodyRef}>
            <Comments
              horse={currentHorse}
              commentsList={commentsList}
              refetchHorseWarningMessage={refetchHorseWarningMessage}
              refetchComments={refetchInfiniteComments}
              isCommentsFetching={isInfiniteCommentsFetching}
              isFetchingNextPage={isFetchingNextCommentsPage}
            />
          </div>
        </ErrorBoundary>
      </Grid>
    </>
  );

  const renderHeaderContent = () => {
    if (isMapFullscreen) {
      return (
        <div className={classes.mapHeader}>
          <Typography component={"b"} className={classes.mapHeaderTitle}>
            Location
          </Typography>
          <div className={classes.mapFacilitySelectWrapper}>
            <ErrorBoundary>
              <FacilitySelect
                isActive={true}
                selectedFacility={selectedMapFacility || horseFacility}
                onChange={handleChangeFacility}
              />
            </ErrorBoundary>
          </div>
          <IconButton
            size={matchesDownXs ? "small" : "medium"}
            onClick={handleMapClose}
          >
            <Close />
          </IconButton>
        </div>
      );
    }

    return (
      <>
        <ExamLockerContext
          recordId={existingExam?.id}
          refetchInterval={30000}
          countdown={false}
        >
          <Header
            isExistingExam={!!existingExam}
            horse={currentHorse}
            onOpenExamPage={handleOpenExamPage}
            warningMessage={warningMessage}
            isPullRelevantHorseWarningMessageFetching={
              isRelevantHorseWarningMessageFetching
            }
            refetchHorseWarningMessage={refetchHorseWarningMessage}
          />
        </ExamLockerContext>
        {matchesDownSm && (
          <TabsComponent
            tabs={tabs}
            selected={activeTab}
            onChange={handleTabChange}
            className={classes.appPageHeaderTabs}
          />
        )}
      </>
    );
  };

  const renderContainerContent = () => {
    if (isMapFullscreen) {
      return (
        <ErrorBoundary>
          <Map
            currentHorse={currentHorse}
            onOpen={handleMapOpen}
            onClose={handleMapClose}
            selectedFacility={selectedMapFacility || horseFacility}
            onBarnChange={handleBarnChangeOnMap}
          />
        </ErrorBoundary>
      );
    }

    return matchesDownSm ? renderDownSM() : renderUpSM();
  };

  const isHorseDataLoading = isHorseLoading || isHorseFetching;
  const isLoading = [
    isHorseLoading,
    isHorseFetching,
    isExamsLoading,
    isExamsFetching,
    isPastExamsLoading,
    isPastExamsFetching,
    isRelevantHorseWarningMessageFetching
  ].some(Boolean);

  useEffect(() => {
    setCurrentHorse(horse);
  }, [horse]);

  if (!currentHorse) return null;

  if (isOpenExamForm && currentExam) {
    return (
      <VetExamOverlay
        examId={currentExam.id}
        refetchData={refetchData}
        onClose={handleExamClose}
      />
    );
  }

  if (isLoading) return <Loader />;

  return (
    <AppPage className={isMapFullscreen ? classes.rootBorder : ""}>
      <ErrorBoundary>
        <AppPageHeader
          className={clsx({
            [classes.appHeaderMapFullscreen]: isMapFullscreen
          })}
        >
          {renderHeaderContent()}
        </AppPageHeader>
      </ErrorBoundary>

      <ErrorBoundary>
        <AppPageContent className={classes.pageContent}>
          <Container
            ref={rootRef}
            maxWidth={false}
            disableGutters={isMapFullscreen}
            className={clsx(
              classes.appPageContentContainer,
              classes[activeTab.value],
              {
                [classes.noPaddings]: isMapFullscreen
              }
            )}
          >
            {renderContainerContent()}
          </Container>
        </AppPageContent>
      </ErrorBoundary>
    </AppPage>
  );
};

export default VetHorseDetailsPage;
